import {FC, useEffect, useState} from 'react'
import {ID} from '../../../../../_metronic/helpers/crud-helper/models'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {useNavigate} from 'react-router-dom'
import {KTIcon} from '../../../../../_metronic/helpers'
import {
  MustHavePermission,
  SWSection,
  SWResource,
  SWAction,
} from '../../../../core/shared/core/permissionUtils'

type Props = {
  id: ID
}
const AgentActionsCell: FC<Props> = ({id}) => {
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  const navigate = useNavigate()
  const openEditModal = () => {
    navigate(`/sales/agent/edit/${id}`)
  }

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm no-wrap'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>

      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* {MustHavePermission(SWSection.Store, SWResource.Agents, SWAction.Update) ? */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={() => navigate(`/sales/agent/edit/${id}`)}>
            Edit
          </a>
        </div>
        {/* : null} */}
        {MustHavePermission(SWSection.Store, SWResource.Agents, SWAction.View) ? (
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={() => navigate(`/sales/agent/detail/${id}`)}>
              Detail
            </a>
          </div>
        ) : null}
      </div>
    </>
  )
}
export {AgentActionsCell}
