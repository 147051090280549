import { KTCard } from "../../../../../_metronic/helpers"
import ProductGroupAdvancedSearch from "./components/header/ProductGroupAdvancedSearch"
import { ProductGroupListHeader } from "./components/header/ProductGroupListHeader"
import { ListViewProvider } from "./core/ListViewProvider"
import { QueryRequestProvider } from "./core/QueryRequestProvider"
import { QueryResponseProvider } from "./core/QueryResponseProvider"
import { ProductGroupTable } from "./productgroup-table/ProductGroupTable"

const ProductGroupList = () => {
   return (
    <>
    <ProductGroupAdvancedSearch/>
      <KTCard>
        {/* <ProductGroupListHeader /> */}
        <ProductGroupTable />
      </KTCard>
    </>
  )
}

const ProductGroupListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ProductGroupList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { ProductGroupListWrapper }
