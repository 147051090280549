import {ID, Response} from '../../../../../../_metronic/helpers/crud-helper/models'

export type AvailabilityTypes = {
  id: number
  name: string
}

export type ProductGroup = {
  id?: ID
  name?: string
  description?: string
  isActive?: boolean
  productGroupImage?: productImagesItems
  statusId?: number
  imagePath?: string
  categoryId?: number
  spSemiWholeSeller?: number
  spRetail?: number
  spWholeSeller?: number
  retailMoQ?: number
  wholeSellerMoQ?: number
  semiWholeSellerMoQ?: number
  brandId?: number
  manufacturerId?: number
  secondaryCategory?: number[]
  designNumberId?: number
  designNumber?: string
  secondaryCategories?: secondaryCategories
  purchasePrice?: number
  vendorId?: string
  productImagePath?: string
  availabilityTypeId?: number
}

export type ProductGroupQueryResponse = Response<Array<ProductGroup>>
export const initialProductGroup: ProductGroup = {
  name: '',
  description: '',
  isActive: true,
  statusId: 0,
  imagePath: '',
  spSemiWholeSeller: 0,
  spRetail: 0,
  spWholeSeller: 0,
  retailMoQ: 1,
  wholeSellerMoQ: 1,
  semiWholeSellerMoQ: 1,
  brandId: undefined,
  manufacturerId: undefined,
  productGroupImage: {
    name: '',
    extension: '',
    data: '',
    isImageDelete: false,
    isFeatured: true,
  },
  secondaryCategory: [],
  designNumberId: undefined,
  productImagePath: '',
  categoryId: undefined,
  availabilityTypeId: undefined,
}

export type productImagesItems = {
  id?: number
  name?: string
  extension?: string
  data?: string
  isImageDelete?: boolean
  isFeatured?: boolean
}

export type secondaryCategories = [
  {
    productGroupId?: number
    categoryId?: number
    categoryName?: string
  },
]

export type updateProductGroupPricesRequests = {
  id?: ID
  spSemiWholeSeller?: number
  spRetail?: number
  spWholeSeller?: number
}
