import React, {FC, useEffect, useState} from 'react'
import {Field, FormikProvider, useFormik} from 'formik'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {isNotEmpty, PaginationFilter} from '../../../../../../_metronic/helpers'
import {SelectOptionProps} from '../../../../../core/models/SelectOptionProps'
import {getCountryList, getStateByCountryId} from '../../../../../core/shared/core/_request'
import {Country, State} from '../../../../../core/shared/core/_model'
import {Address, AddressUpdateAddressPayload, OrganizationAddress} from '../../../core/_models'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import CustomSelect from '../../../../../core/shared/components/CustomSelect'
import {addNewAddress, updateAddress} from '../../../core/_requests'

interface Props {
  isModalOpen: boolean
  isEditMode: boolean
  closeModal: () => void
  initialValues: OrganizationAddress
}

const paginationFilter: PaginationFilter = {
  pageNumber: 1,
  pageSize: 500,
  advancedFilter: {
    logic: 'and',
    filters: [
      {
        field: 'isActive',
        operator: 'eq',
        value: true,
      },
    ],
  },
}

const AddressModal: FC<Props> = ({isModalOpen, closeModal, initialValues, isEditMode}) => {
  const [stateList, setStateList] = useState<SelectOptionProps[]>([])
  const [countryList, setCountryList] = useState<SelectOptionProps[]>([])

  const addressSchema = Yup.object().shape({
    address: Yup.object().shape({
      addressLine1: Yup.string().required('Address Line 1 is required'),
      addressLine2: Yup.string().required('Address Line 2 is required'),
      city: Yup.string().required('City is required'),
      stateId: Yup.string().required('State is required'),
      countryId: Yup.string().required('Country is required'),
      zipCode: Yup.string().required('Zip Code is required'),
      phoneNumber: Yup.string().required('Phone Number is required'),
    }),
  })

  const queryClient = useQueryClient()
  const {mutate: updateAddressMutation} = useMutation({
    mutationKey: ['updateAddress'],
    mutationFn: (payload: AddressUpdateAddressPayload) => updateAddress(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['getAddress']})
      toast.success('Address updated successfully.')
      closeModal()
    },
    onError: (error: any) => {
      toast.error(error.message)
    },
  })

  const {mutate: addNewAddressMutation} = useMutation({
    mutationKey: ['addNewAddress'],
    mutationFn: (payload: AddressUpdateAddressPayload) => addNewAddress(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['getAddress']})
      toast.success('New address created successfully.')
      closeModal()
    },
    onError: (error: any) => {
      toast.error(error.message)
    },
  })

  const initialAddress = initialValues?.address as Address

  const editValues = {
    id: undefined,
    organizationProfileId: undefined,
    address: {
      id: undefined,
      organisationProfileAddressId: undefined,
      addressLine1: initialAddress?.addressLine1,
      addressLine2: initialAddress?.addressLine2,
      city: initialAddress?.city,
      stateId: initialAddress?.stateId,
      stateName: initialAddress?.stateName,
      countryId: initialAddress?.countryId,
      countryName: initialAddress?.countryName,
      zipCode: initialAddress?.zipCode,
      phoneNumber: initialAddress?.phoneNumber,
    },
    isPrimary: false,
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editValues,
    validationSchema: addressSchema,

    onSubmit: async (formValues, {setFieldError, setSubmitting}) => {
      const stateName = stateList.find(
        (state) => state.value === formValues?.address?.stateId
      )?.label
      const countryName = countryList.find(
        (country) => country.value === formValues?.address?.countryId
      )?.label
      const payload: AddressUpdateAddressPayload = {
        id: initialValues?.id || 0,
        organizationProfileId: initialValues?.organisationId || 0,
        address: {
          id: initialValues?.addressId || 0,
          addressLine1: formValues?.address?.addressLine1,
          addressLine2: formValues?.address?.addressLine2,
          city: formValues?.address?.city,
          stateId: formValues?.address?.stateId,
          zipCode: formValues?.address?.zipCode,
          phoneNumber: formValues?.address?.phoneNumber,
          countryId: formValues?.address?.countryId,
          stateName: stateName,
          countryName: countryName,
        },
        isPrimary: false,
      }

      setSubmitting(true)
      try {
        let result

        if (isNotEmpty(initialValues?.id) && isEditMode) {
          updateAddressMutation(payload)
        } else {
          addNewAddressMutation(payload)
        }
      } catch (ex) {
        console.error(ex)
      }
    },
  })

  const {data: countries} = useQuery(['countries'], () => getCountryList(paginationFilter), {
    onSuccess: (data) => {
      const countryArray = data.data?.map((item: Country) => ({
        value: item.id,
        label: item.name,
      }))
      setCountryList(countryArray || [])
    },
  })

  const {data: states} = useQuery(
    ['states', formik.values.address?.countryId],
    () => getStateByCountryId(formik.values.address?.countryId),
    {
      enabled: !!formik.values.address?.countryId,
    }
  )

  useEffect(() => {
    if (states) {
      const stateArray =
        Array.isArray(states) &&
        states?.map((item: State) => ({
          value: item.id || 0,
          label: item.name || '',
        }))
      setStateList(stateArray || [])
    }
  }, [states])

  return (
    <FormikProvider value={formik}>
      <div
        className={`modal fade ${isModalOpen ? 'show' : ''}`}
        id='kt_modal_new_address'
        tabIndex={-1}
        aria-modal='true'
        role='dialog'
        style={{display: isModalOpen ? 'block' : 'none'}}
      >
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          <div className='modal-content'>
            <form
              className='form fv-plugins-bootstrap5 fv-plugins-framework'
              onSubmit={formik.handleSubmit}
              noValidate
              id='kt_modal_new_address_form'
            >
              <div className='modal-header' id='kt_modal_new_address_header'>
                <h2>{isEditMode ? 'Edit' : 'Add New'} Address</h2>
                <div
                  className='btn btn-sm btn-icon btn-active-color-primary'
                  data-bs-dismiss='modal'
                  onClick={closeModal}
                >
                  <i className='ki-duotone ki-cross fs-1'>
                    <span className='path1' />
                    <span className='path2' />
                  </i>{' '}
                </div>
              </div>

              <div className='modal-body'>
                <div className='scroll-y me-n7 pe-7' id='kt_modal_new_address_scroll'>
                  <div className='d-flex flex-column mb-3 fv-row fv-plugins-icon-container'>
                    <label className='required fs-5 fw-semibold mb-2'>Address Line 1</label>

                    <Field
                      type={'text'}
                      className='form-control form-control-solid'
                      {...formik.getFieldProps('address.addressLine1')}
                    />
                    {formik.touched?.address?.addressLine1 &&
                      formik.errors?.address?.addressLine1 && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors?.address?.addressLine1}</span>
                          </div>
                        </div>
                      )}

                    <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback' />
                  </div>

                  <div className='d-flex flex-column mb-3 fv-row fv-plugins-icon-container'>
                    <label className=' fs-5 fw-semibold mb-2'>Address Line 2</label>

                    <Field
                      className='form-control form-control-solid'
                      type='text'
                      {...formik.getFieldProps('address.addressLine2')}
                      name='address.addressLine2'
                    />

                    <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback' />
                  </div>
                  <div className='row g-9 mb-3'>
                    <div className='col-md-6 fv-row fv-plugins-icon-container'>
                      <label className='required fs-5 fw-semibold mb-2'>Country</label>
                      <Field
                        className='form-select-solid'
                        options={countryList}
                        component={CustomSelect}
                        {...formik.getFieldProps('address.countryId')}
                        name='address.countryId'
                      ></Field>
                      {formik.touched?.address?.countryId && formik.errors?.address?.countryId && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors?.address?.countryId}</span>
                          </div>
                        </div>
                      )}
                      <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback' />
                    </div>
                    <div className='col-md-6 fv-row fv-plugins-icon-container'>
                      <label className='required fs-5 fw-semibold mb-2'>State</label>
                      <Field
                        className='form-select-solid'
                        options={stateList}
                        component={CustomSelect}
                        {...formik.getFieldProps('address.stateId')}
                        name='address.stateId'
                      ></Field>
                      {formik.touched?.address?.stateId && formik.errors?.address?.stateId && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors?.address?.stateId}</span>
                          </div>
                        </div>
                      )}
                      <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback' />
                    </div>
                  </div>

                  <div className='col-md-6 fv-row fv-plugins-icon-container'></div>

                  <div className='row g-9 mb-3'>
                    <div className='col-md-6 fv-row fv-plugins-icon-container'>
                      <label className='required fs-5 fw-semibold mb-2'>City</label>

                      <Field
                        type={'text'}
                        className='form-control form-control-solid'
                        {...formik.getFieldProps('address.city')}
                        name='address.city'
                      />
                      {formik.touched?.address?.city && formik.errors?.address?.city && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors?.address?.city}</span>
                          </div>
                        </div>
                      )}

                      <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback' />
                    </div>
                    <div className='col-md-6 fv-row fv-plugins-icon-container'>
                      <label className='fs-5 fw-semibold mb-2'>Zip Code</label>

                      <Field
                        type={'text'}
                        className='form-control form-control-solid'
                        {...formik.getFieldProps('address.zipCode')}
                        name='address.zipCode'
                      />
                      {formik.touched?.address?.zipCode && formik.errors?.address?.zipCode && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors?.address?.zipCode}</span>
                          </div>
                        </div>
                      )}
                      <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback' />
                    </div>
                  </div>
                </div>

                <div className='row g-9 mb-3'>
                  <div className='col-md-6 fv-row fv-plugins-icon-container'>
                    <label className='required fs-5 fw-semibold mb-2'>Phone Number</label>

                    <Field
                      type={'text'}
                      className='form-control form-control-solid'
                      {...formik.getFieldProps('address.phoneNumber')}
                      name='address.phoneNumber'
                    />
                    {formik.touched?.address?.phoneNumber &&
                      formik.errors?.address?.phoneNumber && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors?.address?.phoneNumber}</span>
                          </div>
                        </div>
                      )}

                    <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback' />
                  </div>
                </div>
              </div>

              <div className='modal-footer flex-end'>
                <button
                  type='reset'
                  id='kt_modal_new_address_cancel'
                  className='btn btn-light me-3'
                  data-bs-dismiss='modal'
                >
                  Discard
                </button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-color-modal-action='submit'
                  data-bs-dismiss='modal'
                  disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                >
                  <span className='indicator-label'>Submit</span>
                  {formik.isSubmitting && (
                    <span className='indicator-progress'>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </FormikProvider>
  )
}

export default AddressModal
