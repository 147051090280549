import React, { useEffect, useState } from 'react';
import { ProductGroup, updateProductGroupPricesRequests } from '../productGroup/productgroup-list/core/_models';
import { getProductGroupList, updatePrice } from '../productGroup/productgroup-list/core/_requests';
import { PaginationFilter } from '../../../../_metronic/helpers';
import { Category } from '../category/category-list/core/_models';
import { getCategoryList } from '../category/category-list/core/_requests';
import Select from 'react-select';
import { SelectOptionProps } from '../../../core/models/SelectOptionProps';
import axios, { AxiosResponse } from 'axios';
import { Result, FileResult } from '../../../core/models/Result';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';

const paginationFilter: PaginationFilter = {
    pageNumber: 1,
    pageSize: 500,
};

const PriceList = () => {
    const API_URL = process.env.REACT_APP_STORE_API_URL
    const GENERATE_PDF_URL = `${API_URL}/productgroup/downlaodpdf`

    const [designNumberList, setDesignNumberList] = useState<ProductGroup[]>([]);
    const [pricelist, setPriceList] = useState<updateProductGroupPricesRequests[]>([]) // make use to update theprice list api
    const [categoryList, setCategoryList] = useState<SelectOptionProps[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<number | undefined>();
    const [searched, setSearched] = useState(false);

    useEffect(() => {
        let result: any

        const updatedPaginationFilter: PaginationFilter = {
            ...paginationFilter,
            advancedFilter: {
                field: 'isActive',
                operator: 'eq',
                value: true,
            },

        };

        getCategoryList(updatedPaginationFilter).then((v) => {
            result = v.data as Category[];
            let categoryArray: any[] = [];
            result.map((item) => {
                categoryArray.push({ value: item.id, label: item.parentChildCategoryName });
            });
            setCategoryList(categoryArray);
        });
        let colorArray: any[] = []

    }, []);

    const handleSearch = () => {
        const updatedPaginationFilter: PaginationFilter = {
            ...paginationFilter,
            // advancedFilter: {
            //     field: 'categoryId',
            //     operator: 'eq',
            //     value: selectedCategory,
            // },
            advancedFilter: {
                filters: [
                    { field: 'isActive', operator: 'eq', value: true },
                    // {
                    //   field: 'colorId', operator: 'eq', value: selectedColor?.value
                    // },
                    // {
                    //   field: 'polishingTypeId', operator: 'eq', value: selectedPolishing?.value
                    // },
                    {
                        field: "categoryId",
                        operator: "eq",
                        value: selectedCategory,
                    }
                ],
                logic: "and"
            }
        };

        getProductGroupList(updatedPaginationFilter)
            .then((response) => {
                const result = response.data as ProductGroup[];

                if (result && result.length > 0) {

                    setDesignNumberList(result);
                    setSearched(true)
                }
                else {
                    setDesignNumberList([])
                    setSearched(true)
                }

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });


    };

    const handleEdit = (index, field, value) => {
        const updatedDesignNumberList = [...designNumberList];
        updatedDesignNumberList[index] = {
            ...updatedDesignNumberList[index],
            [field]: parseInt(value),
        };
        setDesignNumberList(updatedDesignNumberList);
    };


    const savePrice = () => {
        const updatedPriceList: updateProductGroupPricesRequests[] = designNumberList.map((item) => ({
            id: item.id,
            spSemiWholeSeller: item.spSemiWholeSeller,
            spRetail: item.spRetail,
            spWholeSeller: item.spWholeSeller,
        }));

        // setPriceList(updatedPriceList);
        updatePrice(updatedPriceList)// api for updating
        toast.success("Price List Updated.")
        setDesignNumberList([]);
        setSearched(false);
        setSelectedCategory(undefined);  //this is not working

    };


    const priceListGeneratePdf = async (id: number): Promise<Result | FileResult> => {
        return await axios
            .post(`${GENERATE_PDF_URL}`, {
                categoryId: id,
                rateType: 1
            }, {
                responseType: 'blob'
            },)
            .then((response: AxiosResponse<any>) => {
                let filename = response.headers['content-disposition']
                    .split(';')
                    .find((n: any) => n.includes('filename='))
                    .replace('filename=', '')
                    .trim();

                var result: FileResult = {
                    data: response.data,
                    name: filename
                }

                return result
            })
            .catch((err: Result) => {
                return err
            })
    }

    const priceListpdf = (selectedCategory) => {
        priceListGeneratePdf(selectedCategory).then(file => {
            let output = file as FileResult;

            let url = window.URL
                .createObjectURL(output.data);

            saveAs(url, output.name);
        });

    }
    const navigate = useNavigate()

    return (
        <div className="d-flex flex-column gap-5 gap-lg-5">
            <div className="card">
                <div className="card-header">
                    <div className="card-title">
                        <h3> Select Category</h3>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row mb-4">
                        <div className="col-lg-2 fv-row fv-plugins-icon-container">
                            <label className="form-label">Search Category</label>
                        </div>
                        <div className="col-lg-4 fv-row fv-plugins-icon-container">
                            <Select
                                name="categoryList"
                                className="react-select-container ms-1"
                                options={categoryList}
                                placeholder="Select a Category"
                                isMulti={false}
                                onChange={(selectedOption) => {

                                    setSelectedCategory(selectedOption?.value as number);
                                }}
                            />

                        </div>

                        <div className="col-lg-2 fv-row fv-plugins-icon-container">
                            <button className="btn btn-success" onClick={handleSearch}>
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {searched === true && designNumberList.length > 0 ?
                <><div className="card">
                    <div className="card-header">
                        <div className="card-title">
                            <h3>Price List</h3>
                        </div>
                    </div>
                    <div className="card-body">
                        <table className="table table-striped table-bordered">
                            <thead className='text-center'>
                                <tr>
                                    <th scope="col">Design Number</th>
                                    <th scope='col'>ProductGroup</th>
                                    <th scope="colgroup" colSpan={4}>Price</th>
                                </tr>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col"></th>

                                    <th scope="col">Wholesaller</th>
                                    <th scope="col">Semi-Wholesaller</th>
                                    <th scope="col">Retail</th>
                                </tr>
                            </thead>
                            <tbody>
                                {designNumberList.map((list, index) => (
                                    <tr key={index}>
                                        <td className='text-center'>{list.designNumber}</td>
                                        <td className='text-center'>{list.name}</td>


                                        <td className='text-center'> <input
                                            className='form-control'
                                            type="number"
                                            value={list.spWholeSeller}
                                            onChange={(e) => handleEdit(index, 'spWholeSeller', e.target.value)} /></td>
                                        <td className='text-center'><input
                                            className='form-control'
                                            type="number"
                                            value={list.spSemiWholeSeller}
                                            onChange={(e) => handleEdit(index, 'spSemiWholeSeller', e.target.value)} /></td>
                                        <td className='text-center'>
                                            <input
                                                className='form-control'
                                                type="number"
                                                value={list.spRetail}
                                                onChange={(e) => handleEdit(index, 'spRetail', e.target.value)} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>
                    <div className="card-footer">
                        <div className='d-flex justify-content-end '>

                            <button
                                className='btn btn-primary me-3'
                                onClick={() => { navigate('/catalog/productgroup/list') }}
                            >
                                Back
                            </button>
                            <button
                                className='btn btn-success me-3'
                                onClick={() => { priceListpdf(selectedCategory); }}
                            >
                                Print
                            </button>

                            <button
                                type='submit'
                                className='btn btn-primary'
                                onClick={savePrice}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
                </>
                : searched === false ? null : (
                    <div className='card'>
                        <div className='text-center py-5'>
                            <img src='/media/img/no-records.png' width={80} alt='No item' className='no-item' />
                            <h5 className='text-gray-600 mt-2'>No Price List Found</h5>
                        </div>
                    </div>
                )

            }
        </div>
    );
};

export default PriceList;
