import { lazy, FC, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { MasterLayout } from '../../../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../../../_metronic/helpers'

import Product from './Product'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'

const LowStockRoutes = () => {
  const Breadcrumbs: Array<PageLink> = [
    {
      title: 'Home',
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  return (
    <Routes>
      <Route>
        <Route
          path='product/*'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={Breadcrumbs}>Low Stock Product</PageTitle>
              <Product />
            </SuspensedView>
          }
        />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })

  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { LowStockRoutes }
