import { useBarcode } from "next-barcode";
import { FC } from "react";
type Props = {
  value: string;
  displayValue?: boolean
  format?: string
  width?: number
  height?: number
  textAlign?: string
  ref?: string
  margin?:number
}
const GenerateBarcode: FC<Props> = ({ value, displayValue, format, width, height, textAlign,margin }) => {
  const { inputRef } = useBarcode({
    value: value,
    options: {
      displayValue: displayValue,
      format: format,
      width: width,
      height: height,
      textAlign: textAlign,
      margin:margin

    }
  });

  return <svg ref={inputRef} />;
}

export default GenerateBarcode