// @ts-nocheck
import {Column} from 'react-table'
import { BannerCustomHeader } from './BannerCustomHeader'
import { BannerActionsCell } from './BannerActionsCell'
import { Banner } from '../core/_models'

const BrandColumns: ReadonlyArray<Column<Banner>> = [
  {
    Header: (props) => <BannerCustomHeader tableProps={props} title='Banner Name' className='min-w-125px' />,
    accessor: 'name'
  },
  {
    Header: (props) => <BannerCustomHeader tableProps={props} title='Page Name' className='min-w-125px' />,
    id:'pageId',
    accessor: 'pageName'
  },
  {
    Header: (props) => <BannerCustomHeader tableProps={props} title='Section Name' className='min-w-125px' />,
    id:'sectionId',
    accessor: 'sectionName'
  },
 
  {
    Header: (props) => (
      <BannerCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <BannerActionsCell id={props.data[props.row.index].id} />,
  },
]

export {BrandColumns}
