import {useQuery} from 'react-query'
import {KTCard, KTCardBody} from '../../../../_metronic/helpers'
import AgentAdvancedSearch from './components/header/AgentAdvancedSearch'
import {getAgentList} from './core/_requests'
import {useState} from 'react'
import {useImmer} from 'use-immer'
import {InputText} from 'primereact/inputtext'
import {AgentListToolbar} from './components/header/AgentListToolbar'
import {Loading} from '../../../core/shared/components/Loading'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {StatusCell} from '../../../../_metronic/helpers/components/StatusCell'
import {AgentActionsCell} from './agent-table/AgentActionsCell'

const AgentList = () => {
  const [filterQuery, setFilterQuery] = useState({})
  const [globalFilterValue, setGlobalFilterValue] = useState<string>('')
  const [paginationFilters, setPaginationFilters] = useImmer({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [] as string[],
    order: '' as 'asc' | 'desc',
    first: 0,
    sortField: '',
  })

  const handleFilterQueryChange = (newFilterQuery: any) => {
    setFilterQuery(newFilterQuery)
  }

  const {
    isLoading,
    refetch,
    data: response,
  } = useQuery(
    ['agentList', paginationFilters, filterQuery],
    () => {
      return getAgentList({
        ...filterQuery,
        ...paginationFilters,
        order: paginationFilters.order as 'asc' | 'desc',
      })
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  const onSort = (event: any) => {
    setPaginationFilters((draft) => {
      draft.pageNumber = event.page === undefined ? 1 : (event.page as number) + 1
      draft.pageSize = event.rows
      draft.first = event.first
      draft.sortField = event.sortField
      event.sortField && (draft.order = event.sortOrder === 1 ? 'asc' : 'desc')
      event.sortField &&
        (draft.orderBy = [`${event.sortField} ${event.sortOrder === 1 ? 'asc' : 'desc'}`])
    })
  }

  const renderHeader = () => (
    <div className='flex justify-content-end'>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          placeholder='Keyword Search'
          value={globalFilterValue}
          onChange={(e) => setGlobalFilterValue(e.target.value)}
        />
      </span>
    </div>
  )

  const header = renderHeader()

  const filteredData = response?.data?.filter((item) =>
    Object.values(item).some((value: any) => {
      return (
        typeof value === 'string' && value.toLowerCase().includes(globalFilterValue.toLowerCase())
      )
    })
  )
  const leftContent = (
    <span className='mx-3' style={{color: 'var(--text-color)', userSelect: 'none'}}>
      {`Showing ${(response?.pagination?.totalCount as number) > 0 ? (paginationFilters.first as number) + 1 : 0} to 
            ${Math.min(
              (response?.pagination?.currentPage as number) *
                (response?.pagination?.pageSize as number),
              response?.pagination?.totalCount as number
            )} 
            out of ${response?.pagination?.totalCount} Records`}
    </span>
  )
  return (
    <>
      <AgentAdvancedSearch refetch={refetch} onFilterQueryChange={handleFilterQueryChange} />
      <KTCard>
        {/* <AgentListHeader/> */}
        <div className='card-header'>
          <div className='card-title justify-content-between w-100'>
            <h3>Agent</h3>
            <AgentListToolbar />
          </div>
        </div>
        <KTCardBody>
          <DataTable
            value={filteredData}
            stripedRows
            header={header}
            showGridlines
            filterDisplay='menu'
            tableClassName='table table-bordered table-hover mb-0'
            tableStyle={{minWidth: '50rem'}}
            sortOrder={paginationFilters.order === 'asc' ? 1 : -1}
            sortField={paginationFilters?.sortField}
            loading={isLoading}
            sortMode='single'
            currentPageReportTemplate='Showing {first} to {last} of {totalRecords} records'
            paginatorClassName='dataTables_paginate paging_bootstrap_full_number mt-0'
            paginator
            paginatorLeft={leftContent}
            rows={paginationFilters.pageSize}
            totalRecords={response?.pagination?.totalCount}
            emptyMessage='No Bills found.'
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            onSort={onSort}
            onPage={onSort}
            first={paginationFilters.first}
            lazy
          >
            <Column
              field={'agentName'}
              header={'Agent Name'}
              sortable={true}
              body={(rowData) => `${rowData.firstName} ${rowData?.lastName}`}
              sortField='FirstName'
            />
            <Column
              field={'agentCode'}
              header={'AgentCode'}
              sortable={true}
              sortField='AgentCode'
            />
            <Column
              field={'companyName'}
              header={'Company Name'}
              sortable={true}
              sortField='CompanyName'
            />
            <Column
              field={'mobileNumber'}
              header={'Mobile Number'}
              sortable={true}
              sortField='MobileNumber'
            />
            <Column
              field={'emailAddress'}
              header={'Agent Email'}
              sortable={true}
              sortField='EmailAddress'
            />
            <Column
              field={'isActive'}
              header={'Status Name'}
              sortable={true}
              body={(rowData) => <StatusCell isActive={rowData?.isActive} />}
              sortField='IsActive'
            />
            <Column
              field={'actions'}
              header={'Actions'}
              body={(rowData) => <AgentActionsCell id={rowData.id} />}
            />
          </DataTable>

          {isLoading && <Loading />}
        </KTCardBody>
      </KTCard>
      {/* {itemIdForUpdate === undefined && <PaymentModal />} */}
    </>
  )
}

export default AgentList
