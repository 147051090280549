// @ts-nocheck
import {Column} from 'react-table'
import {ProductStockCustomHeader} from './ProductStockCustomHeader'
import {ProductStock} from '../core/_model'
import { formatDate } from '../../../../../core/utils/helpers'

const ProductStockColumns: ReadonlyArray<Column<ProductStock>> = [

  {
    Header: (props) => (
      <ProductStockCustomHeader tableProps={props} title='product' className='min-w-125px' />
    ),
    accessor: 'productName',
  },

  // {
  //   Header: (props) => (
  //     <ProductStockCustomHeader tableProps={props} title='unit' className='min-w-125px' />
  //   ),
  //   accessor: 'unitName',
  // },

  {
    Header: (props) => (
      <ProductStockCustomHeader tableProps={props} title='color' className='min-w-125px' />
    ),
    accessor: 'colorName',
  },

  {
    Header: (props) => (
      <ProductStockCustomHeader
        tableProps={props}
        title='polishing Type '
        className='min-w-125px'
      />
    ),
    accessor: 'polishingTypeName',
  },

  // {
  //   Header: (props) => (
  //     <ProductStockCustomHeader tableProps={props} title='size ' className='min-w-125px' />
  //   ),
  //   accessor: 'sizeName',
  // },

  {
    Header: (props) => (
      <ProductStockCustomHeader
        tableProps={props}
        title='Available Quantity'
        className='min-w-125px'
      />
    ),
    accessor: 'availableQuantity',
 
  
  },
  {
    Header: (props) => (
      <ProductStockCustomHeader
        tableProps={props}
        title='last Updated On'
        className='min-w-125px'
      />
    ),
    accessor: 'lastUpdatedOn',
    Cell: ({value}) => formatDate(value,'dd MMM yyyy'),
  },

  // {
  //   Header: (props) => (
  //     <ProductStockCustomHeader
  //       tableProps={props}
  //       title='Actions'
  //       className='text-end min-w-100px'
  //     />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}) => <ProductStockActionsCell id={props.data[props.row.index].id} />,
  // },
]

export {ProductStockColumns}
