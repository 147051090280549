import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getExpenseById } from '../core/_requests'
import { useQuery } from 'react-query'
import { format } from 'date-fns'
import { formatCurrency } from '../../../../core/utils/helpers'

const ExpenseDetails = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const { data: expenseDetails, isLoading } = useQuery({
    queryKey: ['expenseDetails'],
    queryFn: () => getExpenseById(id),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    cacheTime: 0,
  })
  return (
    <div
      className='d-flex flex-column flex-xl-row gap-5 gap-lg-5 mb-10'
      id='kt_modal_add_expense_scroll'
      data-kt-scroll='true'
    >
      <div className='card flex-row-fluid'>
        <div className='card-header'>
          <div className='card-title'>
            <h3>
              Expense Details
            </h3>
          </div>
        </div>
        <div className='card-body'>
          <div className='row'>
            <div className='col-md-6 col-lg-12 col-xl-12 col-xxl-6'>
              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold mb-2">Expense Category :<span className="fs-6 fw-semibold">  {expenseDetails?.expenseCategory}</span></label>
              </div>
            </div>
            <div className='col-md-6 col-lg-12 col-xl-12 col-xxl-6'>
              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold mb-2">Payment Mode :<span className="fs-6 fw-semibold">  {expenseDetails?.paymentModeName}</span></label>
              </div>
            </div>
          </div>
          {expenseDetails?.paymentModeId == 2 && (
            <>
              <div className='row'>
                <div className='col-md-6 col-lg-12 col-xl-12 col-xxl-6'>
                  <div className="fv-row fv-plugins-icon-container">
                    <label className="fs-5 fw-bold mb-2">Cheque Bank Name :<span className="fs-6 fw-semibold">  {expenseDetails?.chequeBankName}</span></label>
                  </div>
                </div>
                <div className='col-md-6 col-lg-12 col-xl-12 col-xxl-6'>
                  <div className="fv-row fv-plugins-icon-container">
                    <label className="fs-5 fw-bold mb-2">Cheque Number :<span className="fs-6 fw-semibold">  {expenseDetails?.chequeNumber}</span></label>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-6 col-lg-12 col-xl-12 col-xxl-6'>
                  <div className="fv-row fv-plugins-icon-container">
                    <label className="fs-5 fw-bold mb-2">Cheque Date :<span className="fs-6 fw-semibold">   {expenseDetails && format(expenseDetails?.chequeDate as Date, 'dd MMM yyyy')}</span></label>
                  </div>
                </div>
              </div>
            </>
          )}
          {expenseDetails?.paymentModeId !== 1 && (
            <div className='row'>
              <div className='col-md-6 col-lg-12 col-xl-12 col-xxl-6'>
                <div className="fv-row fv-plugins-icon-container">
                  <label className="fs-5 fw-bold mb-2">Paid Through :<span className="fs-6 fw-semibold"> {expenseDetails?.bankName}</span></label>
                </div>
              </div>
            </div>
          )}

          <div className='row'>
            <div className='col-md-6 col-lg-12 col-xl-12 col-xxl-6'>
              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold mb-2">Expense Date :<span className="fs-6 fw-semibold">  {expenseDetails && format(expenseDetails?.expenseDate as Date, 'dd MMM yyyy')}</span></label>
              </div>
            </div>
            <div className='col-md-6 col-lg-12 col-xl-12 col-xxl-6'>
              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold mb-2">Expense Amount :<span className="fs-6 fw-semibold"> {expenseDetails && formatCurrency(expenseDetails?.expenseAmount)}</span></label>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6 col-lg-12 col-xl-12 col-xxl-6'>
              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold mb-2">Vendor Name :<span className="fs-6 fw-semibold"> {expenseDetails?.vendorName}</span></label>
              </div>
            </div>
            <div className='col-md-6 col-lg-12 col-xl-12 col-xxl-6'>
              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold mb-2">Staff Name :<span className="fs-6 fw-semibold"> {expenseDetails?.staffName}</span></label>
              </div>
            </div>
          </div>


          <div className='row'>
            <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-6'>
              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold mb-2">Notes :<span className="fs-6 fw-semibold"> {expenseDetails?.notes}</span></label>
              </div>
            </div>
          </div>

        </div>
        <div className='card-footer text-end'>
          <Link to={`/purchase/expense/list`} className='btn btn-primary'>
            <i className='ki-duotone ki-left fs-2' /> Back
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ExpenseDetails
