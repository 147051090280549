import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ID } from '../../../../../../_metronic/helpers/crud-helper/models'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { MustHavePermission, SWSection, SWResource, SWAction } from '../../../../../core/shared/core/permissionUtils'
import { KTIcon } from '../../../../../../_metronic/helpers'
import { Result } from '../../../../../core/models/Result'
import { deleteProductGroup } from '../core/_requests'
import { toast } from 'react-toastify'
type Props = {
  id: ID
}
const ProductGroupActionsCell: FC<Props> = ({ id }) => {
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const navigate = useNavigate();
  const onDeleteProductGroup = async () => {
    try {
    
      let result: Result
      result = await deleteProductGroup(id) as Result
      if (result.hasOwnProperty('succeeded') && result?.succeeded) {
        toast.success('ProductGroup deleted successfully.')
        navigate('/catalog/productgroup')
      }
      else if (result?.succeeded === false){
        
          toast.error(result.messages[0])
        
      }
    } catch (error) {
      toast.error('Error while deleting the productgroup.')
    }
  }

  return (
    <>

      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>

      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {MustHavePermission(SWSection.Store, SWResource.ProductGroups, SWAction.Update) ?
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={() => navigate(`/catalog/productgroup/edit/${id}`)}>
              Edit
            </a>
          </div>
          : null}
        {/* {MustHavePermission(SWSection.Store, SWResource.ProductGroups, SWAction.Delete) ? */}

        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={onDeleteProductGroup}>
            Delete
          </a>
        </div>
        {/* : null} */}
      </div>
    </>
  )
}

export { ProductGroupActionsCell }
