import React from 'react'
import {useMutation, useQuery} from 'react-query'
import {API_URL, getInvoiceById, invoiceGeneratePdf} from '../core/_requests'
import {Link, useParams} from 'react-router-dom'
import {InvoiceDetails} from '../core/_models'
import {formatCurrency, formatDate} from '../../../../core/utils/helpers'
import {Button, Row} from 'react-bootstrap'
import {format} from 'date-fns'
import {saveAs} from 'file-saver'
import {KTCard, KTCardBody} from '../../../../../_metronic/helpers'
import {toast} from 'react-toastify'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {ColumnGroup} from 'primereact/columngroup'

const InvoiceDetailsPage = () => {
  const {id: inId} = useParams()

  const {data: invoiceData} = useQuery({
    queryKey: ['getInvoiceDetails'],
    queryFn: () => getInvoiceById(inId),
    enabled: !!inId,
    refetchOnWindowFocus: false,
  })

  const {mutate: downloadInvoice} = useMutation({
    mutationKey: ['exportVendorStatementPDF'],
    mutationFn: () => invoiceGeneratePdf(inId),
    onSuccess: (data) => {
      let output = data as any
      if (output) {
        let url = window.URL.createObjectURL(output?.data)
        saveAs(url, output?.name || 'customer_statement.pdf')
      } else {
        toast.error('Failed to fetch the ')
      }
    },
    onError: () => {
      toast.error('Failed to fetch customer statements')
    },
  })

  // const initialValues = {
  //   id: Number(invoiceData?.id),
  //   saleOrderId: Number(invoiceData?.saleOrderId),
  //   customerId: Number(invoiceData?.customerId),
  //   customerName: invoiceData?.customerName || '',
  //   invoiceNumber: invoiceData?.invoiceNumber || '',
  //   invoiceDate: invoiceData?.invoiceDate || '',
  //   billAddressLine1: invoiceData?.billAddressLine1 || '',
  //   billAddressLine2: invoiceData?.billAddressLine2 || '',
  //   billCityName: invoiceData?.billCityName || '',
  //   billStateId: invoiceData?.billStateId || 0,
  //   billPincode: invoiceData?.billPincode,
  //   billStateName: invoiceData?.billStateName || '',
  //   billCountryId: invoiceData?.billCountryId || 0,
  //   billCountryName: invoiceData?.billCountryName || '',
  //   shipAddressLine1: invoiceData?.shipAddressLine1 || '',
  //   shipAddressLine2: invoiceData?.shipAddressLine2 || '',
  //   shipCityName: invoiceData?.shipCityName || '',
  //   shipStateId: invoiceData?.shipStateId || 0,
  //   shipStateName: invoiceData?.shipStateName || '',
  //   shipCountryId: invoiceData?.shipCountryId || 0,
  //   shipCountryName: invoiceData?.shipCountryName || '',
  //   shipPincode: invoiceData?.shipPincode,
  //   termId: invoiceData?.termId || 0,
  //   termName: invoiceData?.termName || '',
  //   termNumberOfDays: invoiceData?.termNumberOfDays || 0,
  //   dueDate: invoiceData?.dueDate || '',
  //   paymentStatusId: invoiceData?.paymentStatusId || 0,
  //   paymentStatusName: invoiceData?.paymentStatusName || '',
  //   invoiceStatusId: invoiceData?.invoiceStatusId || 0,
  //   invoiceStatusName: invoiceData?.invoiceStatusName || '',
  //   orderTotal: invoiceData?.orderTotal || 0,
  //   orderSubTotal: invoiceData?.orderSubTotal || 0,
  //   roundOff: invoiceData?.roundOff || 0,
  //   totalDiscountedPrice: invoiceData?.totalDiscountedPrice || 0,
  //   totalTaxAmount: invoiceData?.totalTaxAmount || 0,
  //   totalOtherCharges: invoiceData?.totalOtherCharges || 0,
  //   gstNumber: invoiceData?.gstNumber || '',
  //   notes: invoiceData?.notes || '',
  //   placeOfSupplyId: invoiceData?.placeOfSupplyId || 0,
  //   placeOfSupplyName: invoiceData?.placeOfSupplyName || '',
  //   itemList: invoiceData?.itemList || [],
  //   paymentList: invoiceData?.paymentList || [],
  //   otherCharges: invoiceData?.otherCharges || [],
  // }

  let totalAmount: number = 0
  totalAmount =
    (invoiceData?.orderSubTotal as number) - (invoiceData?.totalDiscountedPrice as number)

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer='SubTotal:' footerStyle={{textAlign: 'right'}} />
        <Column footer={invoiceData?.itemList?.reduce((acc, item) => acc + item?.quantity, 0)} />
        <Column
          footer={formatCurrency(
            invoiceData?.itemList?.reduce((acc, item) => acc + item?.productPrice, 0)
          )}
        />
        <Column
          footer={formatCurrency(
            invoiceData?.itemList?.reduce((acc, item) => acc + item?.discountAmount, 0)
          )}
        />
        <Column
          footer={formatCurrency(
            invoiceData?.itemList?.reduce((acc, item) => acc + item?.taxAmount, 0)
          )}
        />
        <Column footer={formatCurrency(totalAmount)} />
      </Row>
      {invoiceData?.otherCharges.map((ocitem) => (
        <Row key={ocitem.rowNumber}>
          <Column colSpan={5} footer={ocitem.label} footerStyle={{textAlign: 'right'}} />
          <Column footer={formatCurrency(ocitem.amount)} />
        </Row>
      ))}
      <Row>
        <Column colSpan={5} footer='Round Off' footerStyle={{textAlign: 'right'}} />
        <Column footer={formatCurrency(invoiceData?.roundOff)} />
      </Row>
      <Row>
        <Column colSpan={5} footer='Grand Total' footerStyle={{textAlign: 'right'}} />
        <Column footer={formatCurrency(invoiceData?.orderTotal)} />
      </Row>
    </ColumnGroup>
  )
  return (
    <div id='kt_app_content' className='app-content  flex-column-fluid '>
      <div id='kt_app_content_container' className='app-container container-xxl'>
        <div className='d-flex flex-column gap-5 gap-lg-5'>
          <KTCard>
            <KTCardBody>
              <div className='d-flex flex-wrap flex-stack gap-5 gap-lg-5'>
                <ul
                  className='nav profile-nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-lg-n2 me-auto'
                  role='tablist'
                >
                  <li className='nav-item' role='presentation'>
                    <a
                      className='nav-link text-active-primary active'
                      data-bs-toggle='tab'
                      href='#kt_ecommerce_sales_order_summary'
                      aria-selected='true'
                      role='tab'
                    >
                      Order Summary
                    </a>
                  </li>
                </ul>
              </div>
            </KTCardBody>
          </KTCard>

          <div className='d-flex flex-column flex-xl-row gap-5 gap-lg-5'>
            <div className='card flex-row-fluid'>
              <div className='card-header'>
                <div className='card-title'>
                  <h3>Invoice Details (#{invoiceData?.invoiceNumber})</h3>
                </div>
              </div>

              <div className='card-body pt-0'>
                <div className='table-responsive'>
                  <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
                    <tbody className='fw-semibold text-gray-600'>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            <i className='ki-duotone ki-calendar fs-2 me-2'>
                              <span className='path1' />
                              <span className='path2' />
                            </i>{' '}
                            Date Added
                          </div>
                        </td>
                        <td className='fw-bold text-end'>
                          {formatDate(invoiceData?.invoiceDate, 'dd MMM yyyy')}
                        </td>
                      </tr>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            <i className='ki-duotone ki-wallet fs-2 me-2'>
                              <span className='path1' />
                              <span className='path2' />
                              <span className='path3' />
                              <span className='path4' />
                            </i>{' '}
                            Invoice Status
                          </div>
                        </td>
                        <td className='fw-bold text-end'>{invoiceData?.invoiceStatusName}</td>
                      </tr>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            <i className='ki-duotone ki-wallet fs-2 me-2'>
                              <span className='path1' />
                              <span className='path2' />
                              <span className='path3' />
                              <span className='path4' />
                            </i>{' '}
                            Sale Order Number
                          </div>
                        </td>
                        <td className='fw-bold text-end'>{invoiceData?.saleOrderNumber}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className='card flex-row-fluid'>
              <div className='card-header'>
                <div className='card-title'>
                  <h3>Customer Details</h3>
                </div>
              </div>

              <div className='card-body pt-0'>
                <div className='table-responsive'>
                  <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
                    <tbody className='fw-semibold text-gray-600'>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            <i className='ki-duotone ki-profile-circle fs-2 me-2'>
                              <span className='path1' />
                              <span className='path2' />
                              <span className='path3' />
                            </i>{' '}
                            Customer
                          </div>
                        </td>
                        <td className='fw-bold text-end'>
                          <div className='d-flex align-items-center justify-content-end'>
                            <label className='text-gray-600 text-hover-primary'>
                              {invoiceData?.customerName}
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            <i className='ki-duotone ki-sms fs-2 me-2'>
                              <span className='path1' />
                              <span className='path2' />
                            </i>{' '}
                            Email
                          </div>
                        </td>
                        <td className='fw-bold text-end'>
                          <a href='' className='text-gray-600 text-hover-primary'>
                            {invoiceData?.emailAddress}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            <i className='ki-duotone ki-phone fs-2 me-2'>
                              <span className='path1' />
                              <span className='path2' />
                            </i>{' '}
                            Phone
                          </div>
                        </td>
                        <td className='fw-bold text-end'>{invoiceData?.mobileNumber}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className='card flex-row-fluid'>
              <div className='card-header'>
                <div className='card-title'>
                  <h3>Documents</h3>
                </div>
              </div>

              <div className='card-body pt-0'>
                <div className='table-responsive'>
                  <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
                    <tbody className='fw-semibold text-gray-600'>
                      <tr>
                        <td className='text-muted'>
                          <div className='d-flex align-items-center'>
                            <i className='ki-duotone ki-devices fs-2 me-2'>
                              <span className='path1' />
                              <span className='path2' />
                              <span className='path3' />
                              <span className='path4' />
                              <span className='path5' />
                            </i>{' '}
                            Download Invoice
                          </div>
                        </td>
                        <td className='fw-bold'>
                          <Button
                            className='btn btn-primary btn-sm me-lg-n7'
                            onClick={() => downloadInvoice()}
                          >
                            Download
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className='tab-content'>
            <div
              className='tab-pane fade active show'
              id='kt_ecommerce_sales_order_summary'
              role='tab-panel'
            >
              <div className='d-flex flex-column gap-5 gap-lg-5'>
                <div className='d-flex flex-column flex-xl-row gap-5 gap-lg-5'>
                  <div className='card flex-row-fluid position-relative'>
                    <div className='position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5'>
                      <i className='ki-solid ki-two-credit-cart' style={{fontSize: '14em'}}></i>
                    </div>

                    <div className='card-header'>
                      <div className='card-title'>
                        <h3>Billing Address</h3>
                      </div>
                    </div>

                    {invoiceData?.billAddressLine1 ||
                    invoiceData?.billAddressLine2 ||
                    invoiceData?.billStateName ||
                    invoiceData?.billCityName ? (
                      <div className='card-body'>
                        {invoiceData?.billAddressLine1}
                        <br />
                        {invoiceData?.billAddressLine2}
                        <br />
                        {invoiceData?.billCityName} {invoiceData?.billStateName}{' '}
                        {invoiceData?.billZipCode}
                        <br />
                        {invoiceData?.billCountryName}
                        <br />
                      </div>
                    ) : (
                      <div className='card-body pt-0'>
                        <h3>No Bill Address Available</h3>
                      </div>
                    )}
                  </div>

                  <div className='card flex-row-fluid position-relative'>
                    <div className='position-absolute top-0 end-0 bottom-0 opacity-10 d-flex align-items-center me-5'>
                      <i className='ki-solid ki-delivery' style={{fontSize: '13em'}}></i>
                    </div>

                    <div className='card-header'>
                      <div className='card-title'>
                        <h3>Shipping Address</h3>
                      </div>
                    </div>
                    {invoiceData?.shipAddressLine1 ||
                    invoiceData?.shipAddressLine2 ||
                    invoiceData?.shipStateName ||
                    invoiceData?.shipCityName ? (
                      <div className='card-body'>
                        {invoiceData?.shipAddressLine1}
                        <br />
                        {invoiceData?.shipAddressLine2}
                        <br />
                        {invoiceData?.shipCityName} {invoiceData?.shipStateName}{' '}
                        {invoiceData?.shipZipCode}
                        <br />
                        {invoiceData?.shipCountryName}
                        <br />
                      </div>
                    ) : (
                      <div className='card-body'>
                        <h3>No Ship Address Available</h3>
                      </div>
                    )}
                  </div>
                </div>

                <div className='card flex-row-fluid overflow-hidden'>
                  <div className='card-header'>
                    <div className='card-title'>
                      <h3>Invoice #{invoiceData?.invoiceNumber}</h3>
                    </div>
                  </div>

                  <div className='card-body'>
                    <div className='table-responsive'>
                      {/* <table className='table border table-striped table-row-bordered gy-5 gs-7'>
                        <thead>
                          <tr className='fw-semibold fs-6 text-gray-800'>
                            <th className='min-w-175px'>Product</th>
                            <th className='min-w-70px text-end'>Qty</th>
                            <th className='min-w-100px text-end'>Unit Price</th>
                            <th className='min-w-100px text-end'> Discount </th>
                            <th className='min-w-100px text-end'> Tax Amount</th>
                            <th className='min-w-100px text-end'>Total</th>
                          </tr>
                        </thead>
                        <tbody className='fw-semibold text-gray-600'>
                          {initialValues?.itemList
                            .filter((t) => !t.isDeleted)
                            .map((soitem, index) => (
                              <tr>
                                <td>
                                  <div className='d-flex align-items-center'>
                                    {soitem.productName}
                                  </div>
                                </td>
                                <td className='text-end'>{soitem.quantity} </td>
                                <td className='text-end'>{formatCurrency(soitem.productPrice)}</td>
                                <td className='text-end'>
                                  {(soitem?.discountPercent as number) > 0 ? (
                                    <>
                                      {' '}
                                      <span>{formatCurrency(soitem.discountAmount)} </span>
                                      <span>({soitem.discountPercent}% )</span>
                                    </>
                                  ) : (
                                    <p>{formatCurrency(soitem.discountAmount)}</p>
                                  )}
                                </td>
                                <td className='text-end'>
                                  {(soitem?.taxPercent as number) > 0 ? (
                                    <>
                                      <span>{formatCurrency(soitem.taxAmount)} </span>
                                      <span>({soitem.taxPercent}% )</span>
                                    </>
                                  ) : (
                                    <p> {formatCurrency(soitem.taxAmount)}</p>
                                  )}
                                </td>
                                <td className='text-end'>
                                  {soitem.taxPercent === null
                                    ? formatCurrency(soitem.total)
                                    : formatCurrency(soitem.totalInclusiveTax)}
                                </td>
                              </tr>
                            ))}
                          <tr className='text-end text-gray-800'>
                            <td className='text-start'>SubTotal</td>
                            <td className='text-end text-gray-800'>
                              {initialValues?.itemList?.reduce((acc, item) => {
                                return acc + item.quantity
                              }, 0)}
                            </td>
                            <td className='text-end text-gray-800'>
                              {formatCurrency(initialValues.orderSubTotal)}
                            </td>
                            <td className='text-end'>
                              {initialValues.totalDiscountedPrice
                                ? formatCurrency(initialValues.totalDiscountedPrice)
                                : '₹ 0.00'}
                            </td>
                            <td className='text-end'>
                              {initialValues.totalTaxAmount
                                ? formatCurrency(initialValues.totalTaxAmount)
                                : '₹ 0.00'}
                            </td>
                            {initialValues?.totalTaxAmount !== null && (
                              <td className='text-end text-gray-800'>
                                {formatCurrency(totalAmount)}
                              </td>
                            )}
                          </tr>
                          {initialValues?.otherCharges
                            .filter((t) => !t.isDeleted)
                            .map((ocitem, index) => (
                              <tr key={ocitem.rowNumber}>
                                <td colSpan={5} className='text-end'>
                                  {ocitem.label}
                                </td>
                                <td className='text-end'>{formatCurrency(ocitem.amount)}</td>
                              </tr>
                            ))}
                          <tr>
                            <td colSpan={5} className='text-end'>
                              Round Off
                            </td>
                            <td className='text-end'>{initialValues.roundOff?.toFixed(2)}</td>
                          </tr>
                          <tr>
                            <td colSpan={5} className='fs-3 text-gray-900 text-end'>
                              Grand Total
                            </td>
                            <td className='text-gray-900 fs-3 fw-bolder text-end'>
                              {formatCurrency(initialValues.orderTotal)}
                            </td>
                          </tr>
                        </tbody>
                      </table> */}
                      <DataTable
                        stripedRows
                        tableClassName='table table-bordered table-hover mb-0'
                        value={invoiceData?.itemList}
                        tableStyle={{minWidth: '60rem'}}
                        footerColumnGroup={footerGroup}
                        emptyMessage='No Invoice found.'
                      >
                        <Column field='productName' header='Product Name'></Column>
                        <Column field='quantity' header='Quantity'></Column>
                        <Column
                          field='productPrice'
                          header='Price'
                          body={(rowData) => formatCurrency(rowData?.productPrice)}
                        ></Column>
                        <Column
                          field='discountAmount'
                          header='Discount Amount'
                          body={(rowData) => (
                            <>
                              {formatCurrency(rowData?.discountAmount)} ({rowData?.discountPercent}
                              %)
                            </>
                          )}
                        ></Column>
                        <Column
                          field='taxAmount'
                          header='Tax Amount'
                          body={(rowData) => (
                            <>
                              {formatCurrency(rowData?.taxAmount)} ({rowData?.taxPercent}%)
                            </>
                          )}
                        ></Column>
                        <Column
                          field='total'
                          header='Total Amount'
                          body={(rowData) => formatCurrency(rowData.total)}
                        ></Column>
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='tab-pane fade' id='kt_ecommerce_sales_payment_history' role='tab-panel'>
              <div className='d-flex flex-column gap-5 gap-lg-5'>
                <div className='card flex-row-fluid'>
                  <div className='card-header'>
                    <div className='card-title'>
                      <h3>Payment History</h3>
                    </div>
                  </div>

                  <div className='card-body'></div>
                  {/*end::Card body*/}
                </div>
              </div>
            </div>
          </div>

          <div className='card'>
            <div className='card-body text-end'>
              <Link to={`/sales/invoice/list`} className='btn btn-primary btn-sm ms-auto'>
                <i className='ki-duotone ki-left fs-2' />
                {'Back'}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvoiceDetailsPage
