import React, {useEffect, useState} from 'react'
import {Agent} from '../core/_models'
import {getAgentById} from '../core/_requests'
import {useParams} from 'react-router-dom'
import {format} from 'date-fns'

const AgentProfile = () => {
  const {id} = useParams()
  const [agent, setAgent] = useState<Agent>()

  useEffect(() => {
    const agentDetails = async () => {
      let response = await getAgentById(Number(id))
      setAgent(response as Agent)
    }
    agentDetails()
  }, [])

  const formatDate = (rowData: any, dateFormat: string) => {
    const date = new Date(rowData)
    const convertedDate = format(date, dateFormat)
    return convertedDate
  }

  return (
    <div className='card'>
      <div className='card-header'>
        <div className='card-title'>
          <h3>Profile</h3>
        </div>
      </div>

      <div className='card-body pb-5'>
        <div className='row'>
          <div className='col-md-6 col-lg-12 col-xl-12 col-xxl-6'>
            <div className='fv-row fv-plugins-icon-container'>
              <label className='fs-5 fw-bold mb-2'>
                First Name :
                <span className='fs-6 fw-semibold'>
                  {' '}
                  {agent?.firstName ? agent.firstName : ' -'}
                </span>
              </label>
            </div>
          </div>

          <div className='col-md-6 col-lg-12 col-xl-12 col-xxl-6'>
            <div className='fv-row'>
              <label className='fs-5 fw-bold mb-2'>
                Last Name :
                <span className='fs-6 fw-semibold'> {agent?.lastName ? agent.lastName : ' -'}</span>
              </label>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-6 col-lg-12 col-xl-12 col-xxl-6'>
            <div className='fv-row fv-plugins-icon-container'>
              <label className='fs-5 fw-bold mb-2'>
                Company Name :
                <span className='fs-6 fw-semibold'>
                  {agent?.companyName ? agent.companyName : '-'}
                </span>
              </label>
            </div>
          </div>

          <div className='col-md-6 col-lg-12 col-xl-12 col-xxl-6'>
            <div className='fv-row'>
              <label className='fs-5 fw-bold mb-2'>
                Email Address :
                <span className='fs-6 fw-semibold'>
                  {agent?.emailAddress ? agent.emailAddress : '-'}
                </span>
              </label>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-6 col-lg-12 col-xl-12 col-xxl-6'>
            <div className='fv-row fv-plugins-icon-container'>
              <label className='fs-5 fw-bold mb-2'>
                Mobile Number :<span className='fs-6 fw-semibold'>{agent?.mobileNumber}</span>
              </label>
            </div>
          </div>

          <div className='col-md-6 col-lg-12 col-xl-12 col-xxl-6'>
            <div className='fv-row'>
              <label className='fs-5 fw-bold mb-2'>
                Whatsapp Number :
                <span className='fs-6 fw-semibold'>
                  {agent?.whatsappNumber ? agent.whatsappNumber : '-'}
                </span>
              </label>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-6 col-lg-12 col-xl-12 col-xxl-6'>
            <div className='fv-row fv-plugins-icon-container'>
              <label className='fs-5 fw-bold mb-2'>
                AddressLine1 :
                <span className='fs-6 fw-semibold'>
                  {' '}
                  {agent?.addressLine1 ? agent.addressLine1 : '-'}
                </span>
              </label>
            </div>
          </div>

          <div className='col-md-6 col-lg-12 col-xl-12 col-xxl-6'>
            <div className='fv-row'>
              <label className='fs-5 fw-bold mb-2'>
                AddressLine2 :
                <span className='fs-6 fw-semibold'>
                  {' '}
                  {agent?.addressLine2 ? agent.addressLine2 : '-'}
                </span>
              </label>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-6 col-lg-12 col-xl-12 col-xxl-6'>
            <div className='fv-row fv-plugins-icon-container'>
              <label className='fs-5 fw-bold mb-2'>
                State :
                <span className='fs-6 fw-semibold'>
                  {' '}
                  {agent?.stateName ? agent.stateName : '-'}
                </span>
              </label>
            </div>
          </div>

          <div className='col-md-6 col-lg-12 col-xl-12 col-xxl-6'>
            <div className='fv-row'>
              <label className='fs-5 fw-bold mb-2'>
                City :<span className='fs-6 fw-semibold'> {agent?.city ? agent.city : '-'}</span>
              </label>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 col-lg-12 col-xl-12 col-xxl-6'>
            <div className='fv-row'>
              <label className='fs-5 fw-bold mb-2'>
                Zipcode :
                <span className='fs-6 fw-semibold'> {agent?.zipCode ? agent.zipCode : '-'}</span>
              </label>
            </div>
          </div>
          <div className='col-md-6 col-lg-12 col-xl-12 col-xxl-6'>
            <div className='fv-row'>
              <label className='fs-5 fw-bold mb-2'>
                Commission Percent :
                <span className='fs-6 fw-semibold'>
                  {' '}
                  {agent?.commissionPercent ? agent.commissionPercent : '-'}
                </span>
              </label>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-6 col-lg-12 col-xl-12 col-xxl-6'>
            <div className='fv-row fv-plugins-icon-container'>
              <label className='fs-5 fw-bold mb-2'>
                Date Of Birth :
                <span className='fs-6 fw-semibold'>
                  {' '}
                  {agent?.dateOfBirth
                    ? formatDate(agent.dateOfBirth, 'dd/MM/yyyy')
                    : 'No Date of Birth available'}
                </span>
              </label>
            </div>
          </div>

          <div className='col-md-6 col-lg-12 col-xl-12 col-xxl-6'>
            <div className='fv-row'>
              <label className='fs-5 fw-bold mb-2'>
                Date Of Anniversary :
                <span className='fs-6 fw-semibold'>
                  {' '}
                  {agent?.dateOfAnniversary
                    ? formatDate(agent.dateOfAnniversary, 'dd/MM/yyyy')
                    : 'No Date of Anniversary available'}
                </span>
              </label>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 col-lg-12 col-xl-12 col-xxl-6'>
            <div className='fv-row fv-plugins-icon-container'>
              <label className='fs-5 fw-bold mb-2'>
                Rate Applicable :
                <span className='fs-6 fw-semibold'> {agent?.rateName ? agent?.rateName : '-'}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgentProfile
