
import { KTCard } from '../../../../_metronic/helpers'
import { BannerTable } from './banner-table/BannerTable'
import BannerListHeader from './components/header/BannerListHeader'
import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'



const BannerList = () => {
  const {itemIdForUpdate} = useListView()
 
  return (
    <>
      <KTCard>
       <BannerListHeader/>
       <BannerTable /> 
      </KTCard>
    </>
  )
}

const BannerListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <BannerList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {BannerListWrapper}
