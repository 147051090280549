import {format} from 'date-fns'
import {SelectSellerOptionProps} from '../models/SelectOptionProps'

export const formatCurrency = (value: number | undefined) =>
  value?.toLocaleString('en-IN', {style: 'currency', currency: 'INR'})

export const formatDate = (rowData: any, dateFormat: string) => {
  const date = new Date(rowData)
  // Check if the date is valid
  if (isNaN(date.getTime())) {
    console.error('Invalid date:', rowData)
    return 'Invalid date'
  }
  return format(date, dateFormat)
}

export const formatPercentage = (value: number | undefined) =>
  value !== undefined ? `${value?.toFixed(2)}%` : undefined

// Define Fiscal Year Start Month
const FISCAL_YEAR_START_MONTH = 3 // April

// Utility function to get current fiscal quarter
export const getFiscalQuarter = (date: Date) => {
  const adjustedMonth = (date.getMonth() - FISCAL_YEAR_START_MONTH + 12) % 12
  return Math.floor(adjustedMonth / 3)
}

export const getPreviousFiscalQuarter = (date: Date) => {
  const currentQuarter = getFiscalQuarter(date)
  return currentQuarter === 0 ? 3 : currentQuarter - 1
}

// Utility function to get quarter date range
export const getQuarterDateRange = (date: Date, quarter: string) => {
  const fiscalQuarter =
    quarter === 'current' ? getFiscalQuarter(date) : getPreviousFiscalQuarter(date)
  const fiscalYearOffset = date.getMonth() < FISCAL_YEAR_START_MONTH ? -1 : 0

  const fromMonth = FISCAL_YEAR_START_MONTH + fiscalQuarter * 3
  const toMonth = FISCAL_YEAR_START_MONTH + (fiscalQuarter + 1) * 3

  const fromDate = new Date(date.getFullYear() + fiscalYearOffset, fromMonth, 1)
  const toDate = new Date(date.getFullYear() + fiscalYearOffset, toMonth, 0) // Last day of the previous month

  return {
    from: fromDate.toISOString(),
    to: toDate.toISOString(),
  }
}

export const getDynamicPrices = (
  rowData: any,
  selectedPriceTypes: SelectSellerOptionProps[],
  showPrice: string | number
) => {
  let prices: any = []

  selectedPriceTypes.map((type: SelectSellerOptionProps) => {
    if (type.value === 'spWholeSeller' && rowData.spWholeSeller !== undefined) {
      prices.push(rowData.spWholeSeller)
    }

    if (type.value === 'spSemiWholeSeller' && rowData.spSemiWholeSeller !== undefined) {
      prices.push(rowData.spSemiWholeSeller)
    }

    if (type.value === 'spRetail') {
      prices.push(rowData.spRetail === null ? 0 : rowData.spRetail)
    }
  })

  return prices.join(showPrice)
}
