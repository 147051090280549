import {FC, useEffect} from 'react'
import {useNavigate, useNavigation} from 'react-router-dom'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {ID, KTIcon} from '../../../../../_metronic/helpers'
import {SWAction, SWResource, SWSection} from '../../../../core/shared/core/permissionUtils'
import {MustHavePermission} from '../../../../core/shared/core/permissionUtils'

type Props = {
  id: ID
}
const BankListActionCell: FC<Props> = ({id}) => {
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const navigate = useNavigate()

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm no-wrap'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>

      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        <>
          {MustHavePermission(SWSection.Store, SWResource.PurchaseOrders, SWAction.Update) ? (
            <div
              className='menu-item px-3'
              onClick={() => navigate(`/settings/bank-account/edit/${id}`)}
            >
              <a className='menu-link px-3'>Edit</a>
            </div>
          ) : null}
          {MustHavePermission(SWSection.Store, SWResource.PurchaseOrders, SWAction.View) ? (
            <div
              className='menu-item px-3'
              onClick={() => navigate(`/settings/bank-account/details/${id}`)}
            >
              <a className='menu-link px-3'>Detail</a>
            </div>
          ) : null}
        </>
      </div>
    </>
  )
}
export {BankListActionCell}
