import React, { FC } from 'react'
import { Result } from '../../../../../core/models/Result'
import { CreditNoteSetting, } from '../../../core/_models'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { FormikProvider, useFormik } from 'formik'
import { updateCreditNote } from '../../../core/_requests'
import { toast } from 'react-toastify'
import clsx from 'clsx'
import { KTCard, KTCardBody } from '../../../../../../_metronic/helpers'
import { MustHavePermission, SWSection, SWResource, SWAction } from '../../../../../core/shared/core/permissionUtils'

type Props = {

    creditData: Result | CreditNoteSetting

}


const SaleSettingPage: FC<Props> = ({ creditData }) => {

    const creditSetting = creditData as CreditNoteSetting
    const initialValues: CreditNoteSetting = {
        id: creditSetting.id,
        termsCondition: creditSetting.termsCondition,
        customerNotes: creditSetting.customerNotes
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,

        onSubmit: async (formValues, { setSubmitting }) => {
            setSubmitting(true)

            let creditNote: CreditNoteSetting = {
                id: formValues.id,
                termsCondition: formValues.termsCondition,
                customerNotes: formValues.customerNotes,
            }
            let result: Result
            result = await updateCreditNote(creditNote)
            toast.success("Credit Note Updated Successfully.")
        },
    })

    return (
        <FormikProvider value={formik}>

            <KTCard>
                <div className="card-header">
                    <div className="card-title">
                        <h3>Credit Note</h3>
                    </div>
                </div>
                <form
                    id='kt_modal_add_creditnote_form'
                    className='form'
                    onSubmit={formik.handleSubmit}
                    noValidate
                >
                    <KTCardBody>
                        <div className='mb-lg-5'>
                            {/* <div className='col-6 col-md-6'> */}
                            <label className='form-label'>Terms & Conditions</label>
                            <textarea
                                rows={5}
                                className={clsx(
                                    'form-control form-control-solid mb-3 mb-lg-0',
                                    { 'is-invalid': formik.touched.termsCondition && formik.errors.termsCondition },
                                    {
                                        'is-valid': formik.touched.termsCondition && !formik.errors.termsCondition,
                                    }
                                )}
                                placeholder="Enter the terms and conditions of your business to be displayed in your transaction"
                                {...formik.getFieldProps('termsCondition')}
                                name='termsCondition'
                            />
                            {formik.touched.termsCondition && formik.errors.termsCondition && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.termsCondition}</span>
                                    </div>
                                </div>
                            )}
                            {/* </div> */}

                        </div>

                        <div className='mb-lg-5'>
                            <label className='form-label'>Customer Notes</label>
                            <textarea
                                rows={5}
                                className={clsx(
                                    'form-control form-control-solid mb-3 mb-lg-0',
                                    { 'is-invalid': formik.touched.customerNotes && formik.errors.customerNotes },
                                    {
                                        'is-valid': formik.touched.customerNotes && !formik.errors.customerNotes,
                                    }
                                )}
                                placeholder="Will be displayed on the credit note"
                                {...formik.getFieldProps('customerNotes')}
                                name='customerNotes'
                            />
                            {formik.touched.customerNotes && formik.errors.customerNotes && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.customerNotes}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </KTCardBody>
                    <div className="card-footer text-end">
                        {MustHavePermission(SWSection.Store, SWResource.CreditNoteSettings, SWAction.Update) ?
                            <div className="text-end">
                                <button type='submit' className='btn btn-primary '>
                                    Save
                                </button></div> : null}
                    </div>
                </form>
            </KTCard>

        </FormikProvider>
    )
}

export default SaleSettingPage