import { FC, useEffect, useState } from 'react'
import { useListView } from '../core/ListViewProvider'
import { ID } from '../../../../../_metronic/helpers/crud-helper/models'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'
import { MustHavePermission, SWSection, SWResource, SWAction } from '../../../../core/shared/core/permissionUtils'
import { KTIcon } from '../../../../../_metronic/helpers'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Result } from '../../../../core/models/Result'
import { deleteNewArrival } from '../core/_requests'


type Props = {
  id: ID
}
const NewArrivalActionsCell: FC<Props> = ({ id }) => {
  const { setItemIdForUpdate } = useListView()
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])


  const navigate = useNavigate()

  const onDeleteNewArrival = async () => {
    try {
       
      let result: Result
     result=await  deleteNewArrival(id) as Result
     if (result.hasOwnProperty('succeeded') && result?.succeeded) {
      toast.success('New arrival deleted successfully.')
      navigate('/cms/newarrival')
    }
    else{
      if(result.statusCode === 500){
        toast.error(result.exception)
      }
    }
  }catch (error) {
      toast.error('Error while deleting the new arrival.')
    }
  }

  return (
    <>

      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>

      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* {MustHavePermission(SWSection.Store, SWResource.Brands, SWAction.Update) ? */}
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={()=>{navigate(`/cms/newarrival/edit/${id}`)}}>
              Edit
            </a>
          </div>
          {/* : null} */}
        {/* {MustHavePermission(SWSection.Store, SWResource.Brands, SWAction.Delete) ? */}

        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={onDeleteNewArrival}
          >
            Delete
          </a>
        </div>
        {/* : null} */}
      </div>








    </>
  )
}
export { NewArrivalActionsCell }
