import axios, { AxiosResponse } from 'axios'
import { ID, PaginationFilter } from "../../../../../_metronic/helpers/crud-helper/models"

import { Result } from '../../../../core/models/Result'
import { NewArrival, NewArrivalQueryResponse } from './_models'
const API_URL = process.env.REACT_APP_STORE_API_URL
const NEWARRIVAL_LIST_URL = `${API_URL}/newarrival/search`
const NEWARRIVAL_BY_ID_URL = `${API_URL}/newarrival`
const NEWARRIVAL_ADD_URL = `${API_URL}/newarrival`

const getNewArrivalList = async (filter: PaginationFilter): Promise<NewArrivalQueryResponse> => {
  return await axios
    .post(`${NEWARRIVAL_LIST_URL}`, filter)
    .then((d: AxiosResponse<NewArrivalQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}
const getNewArrivalById = async (id: ID): Promise<Result | NewArrival> => {
  return axios
    .get(`${NEWARRIVAL_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<NewArrival>) => response.data)
    .then((response: NewArrival) => response)
    .catch((err: Result) => {
      return err
    })
}
const createNewArrival = async (newArrival: NewArrival): Promise<Result> => {
  return await axios
    .post(NEWARRIVAL_ADD_URL, {
      name:newArrival.name,     
      productGroupIds:newArrival.productGroupIds,
      uploadImage:newArrival.uploadImage
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const updateNewArrival = (newArrival: NewArrival): Promise<Result> => {
  return axios
    .put(`${NEWARRIVAL_BY_ID_URL}/${newArrival.id}`, {
      id: newArrival.id,
      name:newArrival.name,      
      productGroupIds:newArrival.productGroupIds,
      uploadImage:newArrival.uploadImage,

      isActive:newArrival.isActive
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}

const deleteNewArrival = async (id: ID): Promise<Result | NewArrival> => {
  return axios
    .delete(`${NEWARRIVAL_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<NewArrival>) => response.data)
    .then((response: NewArrival) => response)
    .catch((err: Result) => {
      return err
    })
}


export {
  getNewArrivalList,
  getNewArrivalById,
  createNewArrival,
  updateNewArrival,
  deleteNewArrival,
 

}
