
import { KTCard } from '../../../../_metronic/helpers'
import { CollectionTable } from './collection-table/CollectionTable'
import CollectionListHeader from './components/header/CollectionListHeader'


import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'



const CollectionList = () => {
 
  return (
    <>
      <KTCard>
       <CollectionListHeader/>
       <CollectionTable /> 
      </KTCard>
    </>
  )
}

const CollectionListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <CollectionList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {CollectionListWrapper}
