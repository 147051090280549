import axios, {AxiosResponse} from 'axios'
import {ID, PaginationFilter} from '../../../../../../_metronic/helpers/crud-helper/models'
import {
  Customer,
  CustomerAddress,
  CustomerAddressQueryResponse,
  CustomerDetailDto,
  CustomerDetailforSOModel,
  CustomerQueryResponse,
  CustomerRecord,
  CustomerStatementsReport,
  TransactionHistory,
} from './_models'
import {FileResult, Result} from '../../../../../core/models/Result'
const API_URL = process.env.REACT_APP_STORE_API_URL
const Customer_LIST_URL = `${API_URL}/customers/search`
const Customer_BY_ID_URL = `${API_URL}/customers`
const Customer_ADD_URL = `${API_URL}/customers`
const Customer_Record_URL = `${API_URL}/customers/overview`
const Customer_Address_URL = `${API_URL}/customeraddress/search`
const Customer_Address_URL_ID = `${API_URL}/customeraddress`
const Transaction_History_URL = `${API_URL}/customers/transactionhistory`
const CUSTOMERDETAILSFORSO_URL = `${API_URL}/customers/details-for-so`
const Customer_Statements_URL = `${API_URL}/customers/statement`
const Customer_Statements_Report_URL = `${API_URL}/report/download-customer-ledger`
const Customer_Statements_Report_Export_URL = `${API_URL}/report/download-customer-ledger-excel`

const getCustomerList = async (filter: PaginationFilter): Promise<CustomerQueryResponse> => {
  return await axios
    .post(`${Customer_LIST_URL}`, filter)
    .then((d: AxiosResponse<CustomerQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}
const getCustomerById = async (id: ID): Promise<Result | CustomerDetailDto> => {
  return axios
    .get(`${Customer_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<CustomerDetailDto>) => response.data)
    .then((response: CustomerDetailDto) => response)
    .catch((err: Result) => {
      return err
    })
}
const createCustomer = async (customer: Customer): Promise<Result> => {
  return await axios
    .post(Customer_ADD_URL, {
      firstName: customer.firstName?.trim(),
      lastname: customer.lastName?.trim(),
      companyName: customer.companyName?.trim(),
      printName: customer.printName?.trim(),
      faxNumber: customer.faxNumber?.trim(),
      emailAddress: customer.emailAddress?.trim(),
      website: customer.website?.trim(),
      contactPerson: customer.contactPerson?.trim(),
      mobileNumber: customer.mobileNumber,
      whatsappNumber: customer.whatsappNumber,
      lstNumber: customer.lstNumber?.trim(),
      cstNumber: customer.cstNumber?.trim(),
      gstNumber: customer.gstNumber?.trim(),
      panNumber: customer.panNumber?.trim(),
      aadharNumber: customer.aadharNumber?.trim(),
      gstRegistrationTypeId: customer.gstRegistrationTypeId,
      termId: customer.termId,
      creditLimitInAmount: customer.creditLimitInAmount,
      discountPercent: customer.discountPercent,
      applyGST: customer.applyGST,
      rateTypeId: customer.rateTypeId,
      isInternal: customer.isInternal,
      enableLogin: customer.enableLogin,
      enableCredit: customer.enableCredit,
      tenantTypeId: customer.tenantTypeId,
      linkedTenantId: customer.linkedTenantId,
      userName: customer.userName?.trim(),
      password: customer.password?.trim(),
      billingAddress: customer.billingAddress,
      dateOfBirth: customer.dateOfBirth,
      dateOfAnniversary: customer.dateOfAnniversary,
      shippingAddress: customer.shippingAddress,
      agentId: customer.agentId,
      placeOfSupplyId: customer.placeOfSupplyId,
      uploadImage: customer.uploadImage,
      isVerified: customer.isVerified,
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const updateCustomer = (customer: Customer): Promise<Result> => {
  return axios
    .put(`${Customer_BY_ID_URL}/${customer.id}`, {
      id: customer.id,
      firstName: customer.firstName?.trim(),
      lastname: customer.lastName?.trim(),
      companyName: customer.companyName?.trim(),
      printName: customer.printName?.trim(),
      faxNumber: customer.faxNumber?.trim(),
      emailAddress: customer.emailAddress?.trim(),
      website: customer.website?.trim(),
      contactPerson: customer.contactPerson?.trim(),
      mobileNumber: customer.mobileNumber,
      whatsappNumber: customer.whatsappNumber,
      lstNumber: customer.lstNumber?.trim(),
      cstNumber: customer.cstNumber?.trim(),
      gstNumber: customer.gstNumber?.trim(),
      panNumber: customer.panNumber?.trim(),
      aadharNumber: customer.aadharNumber?.trim(),
      gstRegistrationTypeId: customer.gstRegistrationTypeId,
      termId: customer.termId,
      creditLimitInAmount: customer.creditLimitInAmount,
      discountPercent: customer.discountPercent,
      applyGST: customer.applyGST,
      rateTypeId: customer.rateTypeId,
      isInternal: customer.isInternal,
      tenantTypeId: customer.tenantTypeId,
      linkedTenantId: customer.linkedTenantId,
      enableLogin: customer.enableLogin,
      enableCredit: customer.enableCredit,
      userName: customer.userName?.trim(),
      password: customer.password?.trim(),
      billingAddress: customer.billingAddress,
      shippingAddress: customer.shippingAddress,
      dateOfBirth: customer.dateOfBirth,
      dateOfAnniversary: customer.dateOfAnniversary,
      userId: customer.userId,
      agentId: customer.agentId,
      placeOfSupplyId: customer.placeOfSupplyId,
      accountStatusId: customer.accountStatusId,
      uploadImage: customer.uploadImage,
      imagePath: customer.imagePath,
      isVerified: customer.isVerified,
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}

const deleteCustomer = async (id: ID): Promise<Result | Customer> => {
  return axios
    .delete(`${Customer_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<Customer>) => response.data)
    .then((response: Customer) => response)
    .catch((err: Result) => {
      return err
    })
}

const getRecordById = async (id: ID): Promise<Result | CustomerRecord> => {
  return axios
    .get(`${Customer_Record_URL}?id=${id}`)
    .then((response: AxiosResponse<CustomerRecord>) => response.data)
    .then((response: CustomerRecord) => response)
    .catch((err: Result) => {
      return err
    })
}

const getCustomerAddress = async (
  filter: PaginationFilter
): Promise<CustomerAddressQueryResponse> => {
  return await axios
    .post(`${Customer_Address_URL}`, filter)
    .then((d: AxiosResponse<CustomerAddressQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const getCustomerAddressById = async (id: ID): Promise<Result | CustomerAddress> => {
  return axios
    .get(`${Customer_Address_URL_ID}/${id}`)
    .then((response: AxiosResponse<CustomerAddress>) => response.data)
    .then((response: CustomerAddress) => response)
    .catch((err: Result) => {
      return err
    })
}

const updateCustomerAddress = (customer: CustomerAddress): Promise<Result> => {
  return axios.put(`${Customer_Address_URL_ID}/${customer.id}`, {
    id: customer.id,
    customerId: customer.customerId,
    addressId: customer.addressId,
    addressType: customer.addressType,
    address: customer.address,
  })
}

const createCustomerAddress = (customer: CustomerAddress): Promise<Result> => {
  return axios.post(`${Customer_Address_URL_ID}`, {
    customerId: customer.customerId,
    addressType: customer.addressType,
    address: customer.address,
  })
}

const deleteCustomerAddress = async (id: ID): Promise<Result> => {
  return axios
    .delete(`${Customer_Address_URL_ID}/${id}`)
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const transactionHistory = async (id: ID): Promise<Result | TransactionHistory> => {
  return axios
    .get(`${Transaction_History_URL}/${id}`)
    .then((response: AxiosResponse<TransactionHistory>) => response.data)
    .then((response: TransactionHistory) => response)
    .catch((err: Result) => {
      return err
    })
}

const getCustomerDetailsForSOById = async (id: ID): Promise<CustomerDetailforSOModel> => {
  return axios
    .get(`${CUSTOMERDETAILSFORSO_URL}/${id}`)
    .then((response: AxiosResponse<CustomerDetailforSOModel>) => response.data)
    .then((response: CustomerDetailforSOModel) => response)
}

const getCustomerStatementsReport = async (
  id: ID,
  fromDate: string,
  toDate: string
): Promise<Result | CustomerStatementsReport> => {
  return axios
    .post(`${Customer_Statements_URL}`, {
      customerId: Number(id),
      startDate: fromDate,
      endDate: toDate,
    })
    .then((response: AxiosResponse<CustomerStatementsReport>) => response.data)
    .catch((err: Result) => {
      return err
    })
}

const downloadCustomerStatementsReport = async (
  id: ID,
  fromDate: string,
  toDate: string
): Promise<Result> => {
  return axios
    .post(
      `${Customer_Statements_Report_URL}`,
      {
        customerId: Number(id),
        startDate: fromDate,
        endDate: toDate,
      },
      {
        responseType: 'blob',
      }
    )
    .then(async (response: any) => {
      if (response.type == 'application/json') {
        const jsonData = await response.text()
        const errorData = JSON.parse(jsonData)
        return errorData
      } else {
        let filename = response.headers['content-disposition']
          .split(';')
          .find((n: any) => n.includes('filename='))
          .replace('filename=', '')
          .trim()

        var result: FileResult = {
          data: response.data,
          name: filename,
        }
        return result
      }
    })
    .catch((err: Result) => {
      return err
    })
}

const downloadCustomerStatementsReportExcel = async (
  id: ID,
  fromDate: string,
  toDate: string
): Promise<Result> => {
  return axios
    .post(
      `${Customer_Statements_Report_Export_URL}`,
      {
        customerId: Number(id),
        startDate: fromDate,
        endDate: toDate,
      },
      {
        responseType: 'blob',
      }
    )
    .then(async (response: any) => {
      if (response.type == 'application/json') {
        const jsonData = await response.text()
        const errorData = JSON.parse(jsonData)
        return errorData
      } else {
        let filename = response.headers['content-disposition']
          .split(';')
          .find((n: any) => n.includes('filename='))
          .replace('filename=', '')
          .trim()

        var result: FileResult = {
          data: response.data,
          name: filename,
        }
        return result
      }
    })
    .catch((err: Result) => {
      return err
    })
}

export {
  getCustomerList,
  getCustomerById,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  getRecordById,
  getCustomerAddress,
  getCustomerAddressById,
  updateCustomerAddress,
  createCustomerAddress,
  deleteCustomerAddress,
  transactionHistory,
  getCustomerDetailsForSOById,
  getCustomerStatementsReport,
  downloadCustomerStatementsReport,
  downloadCustomerStatementsReportExcel,
}
