import axios, { AxiosResponse } from 'axios'
import { ID, PaginationFilter } from '../../../../../_metronic/helpers/crud-helper/models'
import { EmailTemplate, EmailTemplateQueryResponse } from './_models'
import { Result } from '../../../../core/models/Result'

const API_URL = process.env.REACT_APP_STORE_API_URL
const EMAILTEMPLATE_BY_ID_URL = `${API_URL}/emailtemplate`
const EMAILTEMPLATE_LIST_URL = `${API_URL}/emailtemplate/search`
const EMAILTEMPLATE_ADD_URL = `${API_URL}/emailtemplate`

const getEmailTemplateList = async (filter: PaginationFilter): Promise<EmailTemplateQueryResponse> => {
  return await axios
    .post(`${EMAILTEMPLATE_LIST_URL}`, filter)
    .then((d: AxiosResponse<EmailTemplateQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}
const getEmailTemplateById = async (id: ID): Promise<Result | EmailTemplate> => {
  return await axios
    .get(`${EMAILTEMPLATE_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<EmailTemplate>) => response.data)
    .then((response: EmailTemplate) => response)
    .catch((err: Result) => {
      return err
    })
}

const createEmailTemplate = async (emailtemplate: EmailTemplate): Promise<Result> => {
  return await axios
    .post(EMAILTEMPLATE_ADD_URL, {
      id: emailtemplate.id,
      templateName: emailtemplate.templateName,
      from: emailtemplate.from,
      cc: emailtemplate.cc,
      bcc: emailtemplate.bcc,
      subject: emailtemplate.subject,
      content: emailtemplate.content,
      setThisToDefault: emailtemplate.setThisToDefault,
      emailTypeId: emailtemplate.emailTypeId
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const updateEmailTemplate = async (emailtemplate: EmailTemplate): Promise<Result> => {
  return await axios
    .put(`${EMAILTEMPLATE_BY_ID_URL}/${emailtemplate.id}`, {
      id: emailtemplate.id,
      templateName: emailtemplate.templateName,
      from: emailtemplate.from,
      cc: emailtemplate.cc,
      bcc: emailtemplate.bcc,
      subject: emailtemplate.subject,
      content: emailtemplate.content,
      setThisToDefault: emailtemplate.setThisToDefault,
      emailTypeId: emailtemplate.emailTypeId

    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}
export {
 createEmailTemplate,updateEmailTemplate,getEmailTemplateById,getEmailTemplateList
}
