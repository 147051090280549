import React, { FC, useEffect, useState } from 'react'
import { Result } from '../../../../../core/models/Result'
import { GSTSetting, OrganizationProfile, } from '../../../core/_models'
import { KTCard, KTCardBody } from '../../../../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { MustHavePermission, SWSection, SWResource, SWAction } from '../../../../../core/shared/core/permissionUtils'
import { format } from 'date-fns'


type Props = {
    orgData: Result | OrganizationProfile
    gstData: Result | GSTSetting
}
const GeneralSettingPage: FC<Props> = ({ orgData, gstData }) => {

    const orgProfile = orgData as OrganizationProfile
    const GSTData = gstData as GSTSetting
    const formatDate = (rowData: any, dateFormat: string) => {
        const date = new Date(rowData);
        return format(date, dateFormat);
    };
    return (
        <>
            <div className="card mb-5 mb-xl-5" id="kt_profile_details_view">
                <div className="card-header">
                    <div className="card-title m-0">
                        <h3 className="fw-bold m-0">Profile Details</h3>
                    </div>
                    {MustHavePermission(SWSection.Store, SWResource.OrganizationProfiles, SWAction.Update) ?
                        <Link to={'/settings/organizationprofile/list'}
                            className="btn btn-sm btn-primary align-self-center"> Edit Profile</Link>
                        : null}

                </div>
                <div className="card-body p-9">
                    <div className="row mb-7">
                        <label className="col-lg-4 fw-semibold text-muted">Organization Name</label>
                        <div className="col-lg-8">
                            <span className="fw-bold fs-6 text-gray-800">{orgProfile.organizationName}</span>
                        </div>
                    </div>
                    <div className="row mb-7">
                        <label className="col-lg-4 fw-semibold text-muted">Location</label>
                        <div className="col-lg-8 fv-row">
                            <span className="fw-semibold text-gray-800 fs-6">{orgProfile?.address?.city}</span>
                        </div>

                    </div>

                    <div className="row mb-7">

                        <label className="col-lg-4 fw-semibold text-muted">
                            Contact Phone
                            <span
                                className="ms-1"
                                data-bs-toggle="tooltip"
                                aria-label="Phone number must be active"
                                data-bs-original-title="Phone number must be active"
                                data-kt-initialized={1}
                            >
                                <i className="ki-duotone ki-information fs-7">
                                    <span className="path1" />
                                    <span className="path2" />
                                    <span className="path3" />
                                </i>{" "}
                            </span>
                        </label>

                        <div className="col-lg-8 d-flex align-items-center">
                            <span className="fw-bold fs-6 text-gray-800 me-2">
                                {orgProfile.address?.phoneNumber}
                            </span>

                        </div>

                    </div>

                    <div className="row mb-7">
                        <label className="col-lg-4 fw-semibold text-muted">Company Site</label>
                        <div className="col-lg-8">
                            <a
                                href={`/${orgProfile.website}`}
                                className="fw-semibold fs-6 text-gray-800 text-hover-primary"
                            >
                                {orgProfile.website}
                            </a>
                        </div>
                    </div>

                    <div className="row mb-7">
                        <label className="col-lg-4 fw-semibold text-muted">Enable Discount on Website</label>
                        <div className="col-lg-8">
                            {orgProfile?.isEnableDiscountForWebCustomer ? 'Yes' : 'No'}
                        </div>
                    </div>

                    <div className="row mb-7">

                        <label className="col-lg-4 fw-semibold text-muted">
                            State
                            <span
                                className="ms-1"
                                data-bs-toggle="tooltip"
                                aria-label="state of origination"
                                data-bs-original-title="state of origination"
                                data-kt-initialized={1}
                            >
                                <i className="ki-duotone ki-information fs-7">
                                    <span className="path1" />
                                    <span className="path2" />
                                    <span className="path3" />
                                </i>{" "}
                            </span>
                        </label>
                        <div className="col-lg-8">
                            <span className="fw-bold fs-6 text-gray-800">{orgProfile?.address?.stateName}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mb-5 mb-xl-5" id="kt_profile_details_view">
                <div className="card-header">
                    <div className="card-title m-0">
                        <h3 className="fw-bold m-0">GST Details</h3>
                    </div>
                    {MustHavePermission(SWSection.Store, SWResource.GSTSettings, SWAction.Update) ?
                        <Link
                            to={"/settings/gstsetting"}
                            className="btn btn-sm btn-primary align-self-center"
                        >
                            Edit GST
                        </Link>
                        : null}
                </div>

                <div className="card-body p-9">

                    <div className="row mb-7">

                        <label className="col-lg-4 fw-semibold text-muted">GST Number</label>

                        <div className="col-lg-8">
                            <span className="fw-bold fs-6 text-gray-800">{GSTData.gstin}</span>
                        </div>

                    </div>

                    <div className="row mb-7">

                        <label className="col-lg-4 fw-semibold text-muted">GST Registered Date</label>

                        <div className="col-lg-8 fv-row">
                            <span className="fw-semibold text-gray-800 fs-6">{formatDate(GSTData.gstRegisteredOn, 'dd MMM yyyy')}</span>
                        </div>

                    </div>

                    <div className="row mb-7">

                        <label className="col-lg-4 fw-semibold text-muted">
                            Business Legal Name
                            <span
                                className="ms-1"
                                data-bs-toggle="tooltip"


                                data-kt-initialized={1}
                            >
                                <i className="ki-duotone ki-information fs-7">
                                    <span className="path1" />
                                    <span className="path2" />
                                    <span className="path3" />
                                </i>{" "}
                            </span>
                        </label>

                        <div className="col-lg-8 d-flex align-items-center">
                            <span className="fw-bold fs-6 text-gray-800 me-2">
                                {GSTData.businessLegalName}
                            </span>

                        </div>

                    </div>

                    <div className="row mb-7">
                        <label className="col-lg-4 fw-semibold text-muted">Business Trade Name</label>
                        <div className="col-lg-8">
                            <a

                                className="fw-semibold fs-6 text-gray-800 text-hover-primary"
                            >
                                {GSTData.businessTradeName}
                            </a>
                        </div>
                    </div>

                    <div className="row mb-7">

                        <label className="col-lg-4 fw-semibold text-muted">
                            Business Registered

                        </label>
                        <div className="col-lg-8">
                            <span className="fw-bold fs-6 text-gray-800">{GSTData.isBusinessRegisterGST ? 'YES' : 'NO'}</span>
                        </div>
                    </div>

                    <div className="row mb-7">
                        <label className="col-lg-4 fw-semibold text-muted">Reverse Charge</label>
                        <div className="col-lg-8">
                            <span className="fw-bold fs-6 text-gray-800">{GSTData.reverseCharge ? 'YES' : 'NO'}</span>
                        </div>
                    </div>
                    <div className="row mb-7">
                        <label className="col-lg-4 fw-semibold text-muted">ImportExport</label>
                        <div className="col-lg-8">
                            <span className="fw-semibold fs-6 text-gray-800">{GSTData.importExport ? 'YES' : 'NO'}</span>
                        </div>
                    </div>
                    <div className="row mb-10">
                        <label className="col-lg-4 fw-semibold text-muted">Composition Scheme</label>
                        <div className="col-lg-8">
                            <span className="fw-semibold fs-6 text-gray-800">{GSTData.compositionScheme ? 'YES' : 'NO'}</span>
                        </div>
                    </div>
                </div>

            </div></>
    )
}

export default GeneralSettingPage