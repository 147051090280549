import {useMemo, useState, useEffect} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from '../core/QueryResponseProvider'

import {CustomHeaderColumn} from './CustomHeaderColumn'
import {CustomRow} from './CustomRow'
import {KTCardBody} from '../../../../../../_metronic/helpers'

import Pagination from '../../../../../core/shared/components/Pagination'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {Loading} from '../../../../../core/shared/components/Loading'
import {ProductStock} from '../core/_model'
import {ProductStockColumns} from './columns'
import { useNavigate } from 'react-router-dom';

const ProductStockTable = () => {
  const navigate = useNavigate()
  const pagination = useQueryResponsePagination()
  const {updateState} = useQueryRequest()
  const rawmaterial = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => rawmaterial, [rawmaterial])
  const columns = useMemo(() => ProductStockColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <>
    <div className="card-header justify-content-between align-items-center">
      <div className="card-title">
        <h3>Products</h3>          
      </div>
    </div>
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_rawmaterial'
          className='table border table-striped table-row-bordered gy-5 gs-7'
          {...getTableProps()}
        >
          <thead>
            <tr className='fw-semibold fs-6 text-gray-800'>
              {headers.map((column: ColumnInstance<ProductStock>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<ProductStock>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination pagination={pagination} isLoading={isLoading} updateState={updateState} />

      {isLoading && <Loading />}
    </KTCardBody>
    <div className="card-footer text-end">
    <button className='btn btn-primary' onClick={() => navigate('/reports/stocks/list')}><i className='ki-duotone ki-left fs-2' /> Back
        </button>
    </div>
    </>

  )
}

export {ProductStockTable}
