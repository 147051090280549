import { lazy, FC, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { MasterLayout } from '../../../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../../../_metronic/helpers'
// import RawMaterialPage from '../../reports/stockReports/raw-material/RawMaterialPage'
import ProductStockPage from './product-stock/ProductStockPage'
import SalesByCustomer from './SalesByCustomer'
import SalesByAgent from './SalesByAgent'
import SalesByProduct from './SalesByProduct'
import SalesByProductGroup from './SalesByProductGroup'
import PurchaseByVendor from './PurchaseByVendor'
import PurchaseByProductGroup from './PurchaseByProductGroup'
import PurchaseByProduct from './PurchaseByProduct'
import ExpenseReportPage from './ExpenseReportPage'
import ExpenseCategoryPage from './ExpenseCategoryPage'
import ReportDetailPage from './ReportDetailPage'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'

const StockRoutes = () => {

  const Breadcrumbs: Array<PageLink> = [
    {
      title: 'Home',
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  return (
    <Routes>
      <Route>
      <Route
          path='list/*'
          element={
            <SuspensedView>
               <PageTitle breadcrumbs={Breadcrumbs}>Reports Center</PageTitle>
              <ReportDetailPage />
            </SuspensedView>
          }
        />

        <Route
          path='productstock/*'
          element={
            <SuspensedView>
              <ProductStockPage />
            </SuspensedView>
          }
        />

        <Route
          path='sales-customer/*'
          element={
            <SuspensedView>
               <PageTitle breadcrumbs={Breadcrumbs}>Sales By Customer</PageTitle>

              <SalesByCustomer />
            </SuspensedView>
          }
        />

        <Route
          path='sales-agent/*'
          element={
            <SuspensedView>
               <PageTitle breadcrumbs={Breadcrumbs}>Sales By Agent</PageTitle>
              <SalesByAgent />
            </SuspensedView>
          }
        />

        <Route
          path='sales-product/*'
          element={
            <SuspensedView>
               <PageTitle breadcrumbs={Breadcrumbs}>Sales By Product</PageTitle>
              <SalesByProduct />
            </SuspensedView>
          }
        />

        <Route
          path='sales-product-group/*'
          element={
            <SuspensedView>
               <PageTitle breadcrumbs={Breadcrumbs}>Sales By ProductGroup</PageTitle>
              <SalesByProductGroup />
            </SuspensedView>
          }
        />

        <Route
          path='purchase-vendor/*'
          element={
            <SuspensedView>
               <PageTitle breadcrumbs={Breadcrumbs}>Purchase By Vendor</PageTitle>
              <PurchaseByVendor />
            </SuspensedView>
          }
        />


        <Route
          path='purchase-product-group/*'
          element={
            <SuspensedView>
               <PageTitle breadcrumbs={Breadcrumbs}>Purchase By ProductGroup</PageTitle>
              <PurchaseByProductGroup />
            </SuspensedView>
          }
        />
        <Route
          path='purchase-product/*'
          element={
            <SuspensedView>
               <PageTitle breadcrumbs={Breadcrumbs}>Purchase By Product</PageTitle>
              <PurchaseByProduct />
            </SuspensedView>
          }
        />

        <Route
          path='expense/*'
          element={
            <SuspensedView>
              <ExpenseReportPage />
            </SuspensedView>
          }
        />

        <Route
          path='expense-category/*'
          element={
            <SuspensedView>
              <ExpenseCategoryPage />
            </SuspensedView>
          }
        />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })

  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { StockRoutes }
