import { useParams } from 'react-router-dom'
import { isNotEmpty } from '../../../../../../_metronic/helpers/crud-helper/helpers';
import { useQuery } from 'react-query';
import { QUERIES } from '../../../../../../_metronic/helpers/crud-helper/consts';

import React from 'react';

import { ProductGroupEditPage } from './ProductGroupEditPage';
import { getProductGroupById } from '../core/_requests';
const ProductGroupEditWrapper = () => {
  const { id } = useParams();
  const enabledQuery: boolean = isNotEmpty(id)
  const {
    isLoading,
    data: result,
    error,
  } = useQuery(
    [`${QUERIES.PRODUCTGROUP_LIST}-${id}`],
    async () => {
      return await getProductGroupById(id)
    },
    {
    
    
      enabled: enabledQuery,
      onError: (err) => {
        console.error(err)
      },
    }
  )
  if (!isLoading && result) {
    return <ProductGroupEditPage isProductLoading={isLoading} data={result} />
  }
  return null
}
export { ProductGroupEditWrapper }
