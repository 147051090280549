import { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Field, FormikProvider, useFormik, validateYupSchema } from 'formik'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { Toast } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify'
import { Account, AccountType } from '../core/_models'
import { Result } from '../../../../core/models/Result'
import { PaginationFilter, isNotEmpty } from '../../../../../_metronic/helpers'
import { camelize } from '../../../../core/utils/StringHelpers'
import { createAccount, getAccountList, getAccountTypeList, updateAccount } from '../core/_requests'
import { StatusOptions } from '../../../../core/models/StatusOptions'
import CustomSelect from '../../../../core/shared/components/CustomSelect'
import { Loading } from '../../../../core/shared/components/Loading'
import { SelectOptionGroup, SelectOptionProps } from '../../../../core/models/SelectOptionProps'
import { getAccountGroupList } from '../../account-group/core/_requests'
// import { AccountGroup } from '../../account-group/core/_models'
type Props = {
  isAccountLoading: boolean
  data: Account | Result
}

const paginationFilter: PaginationFilter = {
  pageNumber: 1,
  pageSize: 500,

}

const editStaffSchema = Yup.object().shape({
  name: Yup.string().required('Please enter Account Name.'),
  accountCode: Yup.string().required('Please enter Account Code.'),
  isSubAccount: Yup.boolean(),
  description: Yup.string(),
  // parentAccountId:
  // Yup.number()
  // .when('isSubAccount',{
  //   is:true,
  //   then:Yup.number().required('ghdddddd')   
  //   ,
  //   otherwise:Yup.number()
  // })
  accountTypeName: Yup.string(),
  parentAccountId: Yup.number()
    .when('isSubAccount',
      ([isSubAccount], schema) => {
        return isSubAccount ? schema.required('Please select the Parent Account') : Yup.number()
      }),
  bankName: Yup.string()
    .when('accountTypeName',
      ([accountTypeName], schema) => {
        return accountTypeName === 'Bank' ? schema.required('Please select bank name') : Yup.string()
      }
    )

})

const AccountForm: FC<Props> = ({ data, isAccountLoading }) => {
  const { setItemIdForUpdate, itemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  let account = data as Account;
  const initialValues:Account = {
    id: account.id,
    name: account.name,
    accountCode: account.accountCode,
    accountTypeId: account.accountTypeId,
    parentAccountId: account.parentAccountId,
    isSubAccount: account.isSubAccount,
    description: account.description,
    accountNumber: account.accountNumber,
    bankName: account.bankName,
    ifsc: account.ifsc
  }

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: editStaffSchema,

    onSubmit: async (formValues, { setSubmitting, setFieldError }) => {
      setSubmitting(true)
      try {
         
        let result: Result

        if (isNotEmpty(formValues.id)) {

          result = await updateAccount(formValues)
        } else {
          result = await createAccount(formValues)
        }
        if (result.hasOwnProperty('succeeded') && result?.succeeded) {
          setSubmitting(true)
          toast.success('Account created successfully');

          cancel(true)
        }
        else {
          toast.error('Error Occurred while updating Account.');
          if (result.statusCode === 400) {
            result.propertyResults.map((error) =>
              setFieldError(camelize(error.propertyName), error.errorMessage)
            )
          }
        }
      } catch (ex) {
        toast.error('Error Occurred .');
        console.error(ex)
      }
    },
  })
  const [accountGrpList, setAccountGrpList] = useState<SelectOptionProps[]>([])
  const [parentGrpList, setParentGrpList] = useState<SelectOptionProps[]>([])
  const [bank, setBank] = useState('')

  let result: any

  useEffect(() => {
    getAccountTypeList(paginationFilter).then((v) => {
      result = v.data as AccountType[]
      let accountArray: any[] = []
      result.map((item: any) => {
        return accountArray.push({ value: item.id, label: item.name })
      })
      setAccountGrpList(accountArray)
    })
    // accountGroupChange();
  }, [formik.values.accountTypeId, bank])


  const parentAccountChange = (e) => {
    formik.setFieldValue('accountTypeId', e.value)
    if (e.label === 'Bank') {
      setBank(e.label)
    }
    else {
      setBank('')
      clearbankDetails();
    }

       const updatedPaginationFilter: PaginationFilter = {
      ...paginationFilter,
      advancedFilter: {
        field: "accountTypeId",
        operator: "eq",
        value: e.value,
      },
    };
    getAccountList(updatedPaginationFilter).then((v) => {

      result = v.data as Account[]
      let accountParentArray: any[] = []
      result
        .map((item: any) => {
          return accountParentArray.push({ value: item.id, label: item.name })
        })
      setParentGrpList(accountParentArray)
    })
   
    accountGroupChange();

  }

  const clearbankDetails = () => {
    formik.setFieldValue('bankName', undefined)
    formik.setFieldValue('ifsc', undefined)
    formik.setFieldValue('accountNumber', undefined)
  }

  const accountGroupChange = () => {
    formik.setFieldValue('name', '')
    formik.setFieldValue('isSubAccount', false)
    formik.setFieldValue('parentAccountId', undefined)
  }


  return (

    <FormikProvider value={formik}>

      <form
        id='kt_modal_add_account_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div
          className='d-flex flex-column'
          id='kt_modal_add_account_scroll'
          data-kt-scroll='true'
        >
          <div className="row mb-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">
              Account Type
            </label>

            <div className="col-lg-8 fv-row fv-plugins-icon-container">
              {/* <Field
                className={clsx(
                  'form-select-solid ms-1',
                  { 'is-invalid': formik.touched.accountTypeId && formik.errors.accountTypeId },
                  {
                    'is-valid': formik.touched.accountTypeId && !formik.errors.accountTypeId,
                  }
                )}
                {...formik.getFieldProps('accountTypeId')}
                name="accountTypeId"
                options={accountGrpList}
                component={CustomSelect}
                placeholder="Select Account Type"
                isMulti={false}
                onDropDownChange={(e) => { parentAccountChange(e) }}
              ></Field> */}
              <Field
                className={clsx(
                  'form-select-solid ms-1',
                  { 'is-invalid': formik.touched.accountTypeId && formik.errors.accountTypeId },
                  {
                    'is-valid': formik.touched.accountTypeId && !formik.errors.accountTypeId,
                  }
                )}
                {...formik.getFieldProps('accountTypeId')}
                name="accountTypeId"
                options={accountGrpList}
                component={CustomSelect}
                placeholder="Select account type"
                isMulti={false}
                onDropDownChange={(e:any) =>  parentAccountChange(e) }
              >
              </Field>
              {formik.touched.accountTypeId && formik.errors.accountTypeId && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.accountTypeId}</span>
                  </div>
                </div>
              )}
            </div>

          </div>



          <div className="row mb-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">
              Account name
            </label>

            <div className="col-lg-8 fv-row fv-plugins-icon-container">
              <input
                placeholder='Account Name'
                {...formik.getFieldProps('name')}
                type='text'
                name='name'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0 ms-1',
                  { 'is-invalid': formik.touched.name && formik.errors.name },
                  {
                    'is-valid': formik.touched.name && !formik.errors.name,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isAccountLoading}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                </div>
              )}
            </div>
          </div>



          <div className="row mb-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">
              Is SubAccount
            </label>

            <div className="col-lg-8 fv-row fv-plugins-icon-container">

              <div className='form-check form-check-custom form-check-solid ms-1'>

                <input
                  className='form-check-input ms-1 '
                  type='checkbox'
                  {...formik.getFieldProps('isSubAccount')}
                  checked={{ ...formik.getFieldProps('isSubAccount') }.value}


                />
                <span className='ms-4'>Make this a sub-account</span>
              </div>
              {formik.touched.isSubAccount && formik.errors.isSubAccount && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.isSubAccount}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {formik.values.isSubAccount ?

            <><div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                Parent Account
              </label>

              <div className="col-lg-8 fv-row fv-plugins-icon-container">
                <Field
                  className={clsx(
                    'form-select-solid ms-1',
                    { 'is-invalid': formik.touched.parentAccountId && formik.errors.parentAccountId },
                    {
                      'is-valid': formik.touched.parentAccountId && !formik.errors.parentAccountId,
                    }
                  )}
                  {...formik.getFieldProps('parentAccountId')}

                  name="parentAccountId"
                  options={parentGrpList}
                  component={CustomSelect}
                  placeholder="Select account"
                  isMulti={false}


                ></Field>
                {formik.touched.parentAccountId && formik.errors.parentAccountId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.parentAccountId}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            </> : ''}



          <div className="row mb-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">
              Account Code
            </label>

            <div className="col-lg-8 fv-row fv-plugins-icon-container">
              <input
                placeholder='Account Code'
                {...formik.getFieldProps('accountCode')}
                type='text'
                name='accountCode'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0 ms-1',
                  { 'is-invalid': formik.touched.accountCode && formik.errors.accountCode },
                  {
                    'is-valid': formik.touched.accountCode && !formik.errors.accountCode,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isAccountLoading} />
              {formik.touched.accountCode && formik.errors.accountCode && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.accountCode}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          {bank === 'Bank' ?
            <><div className="row mb-6">

              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                Account Number
              </label>

              <div className="col-lg-8 fv-row fv-plugins-icon-container">
                <input
                  type='text'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.accountNumber && formik.errors.accountNumber },
                    {
                      'is-valid': formik.touched.accountNumber && !formik.errors.accountNumber,
                    }
                  )}
                  placeholder='accountNumber'
                  {...formik.getFieldProps('accountNumber')}
                  name='accountNumber' />
                {formik.touched.accountNumber && formik.errors.accountNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.accountNumber}</span>
                    </div>
                  </div>
                )}

              </div>
            </div><div className="row mb-6">

                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                  Bank Name
                </label>

                <div className="col-lg-8 fv-row fv-plugins-icon-container">
                  <input
                    type='text'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.bankName && formik.errors.bankName },
                      {
                        'is-valid': formik.touched.bankName && !formik.errors.bankName,
                      }
                    )}
                    placeholder='bankName'
                    {...formik.getFieldProps('bankName')}
                    name='bankName' />
                  {formik.touched.bankName && formik.errors.bankName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.bankName}</span>
                      </div>
                    </div>
                  )}

                </div>
              </div><div className="row mb-6">

                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                  IFSC
                </label>

                <div className="col-lg-8 fv-row fv-plugins-icon-container">
                  <input
                    type='text'
                    className={clsx(
                      'form-control form-control-solid mb-3 mb-lg-0',
                      { 'is-invalid': formik.touched.ifsc && formik.errors.ifsc },
                      {
                        'is-valid': formik.touched.ifsc && !formik.errors.ifsc,
                      }
                    )}
                    placeholder='ifsc'
                    {...formik.getFieldProps('ifsc')}
                    name='ifsc' />
                  {formik.touched.ifsc && formik.errors.ifsc && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.ifsc}</span>
                      </div>
                    </div>
                  )}

                </div>
              </div></> : ''

          }


          <div className='row mb-7'>
          <label className="col-lg-4 col-form-label fw-semibold fs-6">Description</label>
          <div className="col-lg-8 fv-row fv-plugins-icon-container">
            <textarea
              rows={5}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0 required',
                { 'is-invalid': formik.touched.description && formik.errors.description },
                {
                  'is-valid': formik.touched.description && !formik.errors.description,
                }
              )}
              disabled={formik.isSubmitting || isAccountLoading}
              placeholder='Max. 500 characters'
              {...formik.getFieldProps('description')}
              name='description' />
            {formik.touched.description && formik.errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.description}</span>
                </div>
              </div>
            )}
          </div>
          </div>
          <div className='text-center pt-15'>
            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light me-3'
              data-kt-regions-modal-action='cancel'
              disabled={formik.isSubmitting || isAccountLoading}
            >
              Discard
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-regions-modal-action='submit'
              disabled={isAccountLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              <span className='indicator-label'>Submit</span>
              {(formik.isSubmitting || isAccountLoading) && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
      {(formik.isSubmitting || isAccountLoading) && <Loading />}
    </FormikProvider >
  )
}

export { AccountForm }
