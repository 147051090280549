import React, { useEffect, useState } from 'react'
import { AgentRecord, TransactionHistory } from '../core/_models'
import { useNavigate, useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { getRecordById, transactionHistory } from '../core/_requests'
import { formatCurrency } from '../../../../core/utils/helpers'
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers'

const AgentOverview = () => {
    const [recordDetails, setRecordDetails] = useState<AgentRecord>()
    const [transactions, setTransactions] = useState<TransactionHistory>()
    const { id } = useParams()
    useEffect(() => {


        const fetchRecord = async () => {
            const response = await getRecordById(id)
            console.log(response)
            setRecordDetails(response as AgentRecord)
        }
        fetchRecord()

        const transactionHistoryDetails = async () => {
            const response = await transactionHistory(id)
            setTransactions(response as TransactionHistory)
        }
        transactionHistoryDetails()

    }, []);


    const formatDate = (rowData: any, dateFormat: string) => {
        const date = new Date(rowData);
        return format(date, dateFormat);
    };


    const navigate = useNavigate()
    return (


        <>

            <KTCard>
                <KTCardBody>
                    <div className='row justify-content-center g-5'>
                        <div className="col-xl-6 col-lg-6 col-md-4 col-sm-6">
                            <div className='h-100 border border-dashed border-gray-300 rounded text-center p-4'>
                                <span className="fs-3 fw-bold text-gray-800 lh-1">
                                    <span data-kt-countup="true" className="counted" data-kt-initialized="1">{recordDetails?.totalCustomerCount}</span>
                                </span>
                                <span className="fs-6 fw-semibold text-muted d-block lh-1 pt-2">Customer Count</span>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-4 col-sm-6">
                            <div className='h-100 border border-dashed border-gray-300 rounded text-center p-4'>
                                <span className="fs-3 fw-bold text-gray-800 lh-1">
                                    <span data-kt-countup="true" className="counted" data-kt-initialized="1">{recordDetails?.totalSaleOrderCount}</span>
                                </span>
                                <span className="fs-6 fw-semibold text-muted d-block lh-1 pt-2">Sales Count</span>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-4 col-sm-6">
                            <div className='h-100 border border-dashed border-gray-300 rounded text-center p-4'>
                                <span className="fs-3 fw-bold text-gray-800 lh-1">
                                    <span data-kt-countup="true" className="counted" data-kt-initialized="1">{formatCurrency(recordDetails?.totalAgentCommission)}</span>
                                </span>
                                <span className="fs-6 fw-semibold text-muted d-block lh-1 pt-2">Total Commission Earned</span>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-4 col-sm-6">
                            <div className='h-100 border border-dashed border-gray-300 rounded text-center p-4'>
                                <span className="fs-3 fw-bold text-gray-800 lh-1">
                                    <span data-kt-countup="true" className="counted" data-kt-initialized="1">{formatCurrency(recordDetails?.totalPaidCommission)}</span>
                                </span>
                                <span className="fs-6 fw-semibold text-muted d-block lh-1 pt-2">Total Commission Paid</span>
                            </div>
                        </div>
                    </div>
                </KTCardBody>
            </KTCard>
            {/* <div className='row'>
                <div className='mb-5 mb-md-5 col-md-6'>
                    <div className="card pt-4 h-md-100">
                        <div className="card-body pt-0">
                            <div className=" py-3 px-3">
                                <div className="fw-bold text-gray-700 text-center">
                                    <span className="text-center text-success fw-bold fs-1">{recordDetails?.totalCustomerCount}</span>
                                </div>
                                <div className="fw-bold text-primary fs-3 text-center">Customer Count</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mb-5 mb-md-5 col-md-6'>
                    <div className="card pt-4 h-md-100">
                        <div className="card-body pt-0">
                            <div className=" py-3 px-3">
                                <div className="fs-4 fw-bold text-gray-700 text-center">
                                    <span className="wtext-center text-success fw-bold fs-1">{recordDetails?.totalSaleOrderCount}</span>
                                </div>
                                <div className="fw-bold text-primary fs-3 text-center">Sales Count</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className='mb-5 mb-md-5 col-md-6'>
                    <div className="card pt-4 h-md-100">
                        <div className="card-body pt-0">
                            <div className=" py-3 px-3">
                                <div className="fs-4 fw-bold text-gray-700 text-center">
                                    <span className="text-center text-success fw-bold fs-1">{formatCurrency(recordDetails?.totalAgentCommission)}</span>

                                </div>
                                <div className="fw-bold text-primary fs-3 text-center">Total Commission Earned</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mb-5 mb-md-5 col-md-6'>
                    <div className="card pt-4 h-md-100">
                        <div className="card-body pt-0">
                            <div className="py-3 px-3">
                                <div className="fs-4 fw-bold text-gray-700 text-center ">
                                    <span className="text-center text-success fw-bold fs-1">{formatCurrency(recordDetails?.totalPaidCommission)}</span>
                                </div>
                                <div className="fw-bold text-primary fs-3 text-center">Total Commission Paid</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="card pt-4 mt-5">
                <div className="card-header">
                    <div className="card-title">
                        <h3>Transaction History</h3>
                    </div>
                </div>

                <div className="card-body pb-5">
                    <div
                        id="kt_table_customers_payment_wrapper"
                        className="dataTables_wrapper dt-bootstrap4 no-footer"
                    >
                        <div className="table-responsive">
                            <table
                                className="table border table-striped table-row-bordered gy-5 gs-7"
                                id="kt_table_customers_payment"
                            >
                                <thead className="">
                                    <tr className="fw-semibold fs-6 text-gray-800">
                                        <th
                                            className="min-w-100px sorting"
                                            tabIndex={0}
                                            aria-controls="kt_table_customers_payment"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="order No.: activate to sort column ascending"
                                            style={{ width: "144.578px" }}

                                        >
                                            Order No.
                                        </th>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="kt_table_customers_payment"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Status: activate to sort column ascending"
                                            style={{ width: "122.688px" }}
                                        >
                                            Status
                                        </th>
                                        <th
                                            className="sorting"
                                            tabIndex={0}
                                            aria-controls="kt_table_customers_payment"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Amount: activate to sort column ascending"
                                            style={{ width: "118.375px" }}
                                        >
                                            Amount
                                        </th>

                                        <th
                                            className="min-w-100px sorting_disabled"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Date"
                                            style={{ width: "227.188px" }}
                                        >
                                            Date
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="fw-semibold text-gray-600">
                                    {transactions && transactions?.length > 0 ?
                                        (transactions?.map((order) => (
                                            <tr onClick={() => { navigate(`/sales/sale-order/detail/${order.saleOrderId}`) }} style={{ cursor: 'pointer' }}>
                                                <td>
                                                    <a

                                                        className="text-gray-600 text-hover-primary mb-1"
                                                    >
                                                        {order.orderNumber}
                                                    </a>
                                                </td><td>
                                                    {order.orderStatusName === 'Cancelled' ?
                                                        <span className="badge badge-light-danger">
                                                            {order.orderStatusName}
                                                        </span> : <span className="badge badge-light-success">
                                                            {order.orderStatusName}
                                                        </span>}
                                                </td>
                                                <td>{order.orderTotal ? formatCurrency(order.orderTotal) : '₹0.0'} </td>

                                                <td>{formatDate(order.orderDate, 'dd/MM/yyyy')} </td>
                                            </tr>
                                        ))) : <tr>
                                            <td colSpan={4}>
                                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                    No matching records found
                                                </div>
                                            </td>
                                        </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>






    )
}

export default AgentOverview