import React, { FC } from 'react'
import { Result } from '../../../../core/models/Result';
import { Payment } from './core/_model';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { KTCard, KTCardBody } from '../../../../../_metronic/helpers';
type Props = {
  isPaymentLoading: boolean;
  data: Result | Payment;
};
const PaymentDetailPage: FC<Props> = ({ isPaymentLoading, data }) => {
  const paymentData = data as Payment;
  const formatDate = (rowData: any, dateFormat: string) => {
    const date = new Date(rowData);
    return format(date, dateFormat);
  };
  const navigate = useNavigate()
  return (
    <>
      <div className="card">

        <div className="card-header">

          <div className="card-title">
            <h3>Payment Details</h3>
          </div>

        </div>

        <div className="card-body pb-5">

          <div className="row row-cols-1 row-cols-md-2">
            <div className="col">
              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold mb-2">Purchase Order Number :
                  <span className='fs-6 fw-semibold'>  {paymentData.purchaseOrderNumber ? paymentData.purchaseOrderNumber : ' -'}</span>
                </label>


              </div>
            </div>
            <div className="col">
              <div className="fv-row">
                <label className="fs-5 fw-bold mb-2">Payment Number:
                  <span className='fs-6 fw-semibold'> {paymentData.paymentNumber ? paymentData.paymentNumber : ' -'}</span>
                </label>

              </div>
            </div>

          </div>

         
          <div className="row row-cols-1 row-cols-md-2">
            <div className="col">
              <div className="fv-row">
                <label className="fs-5 fw-bold mb-2">Amount Received:
                  <span className='fs-6 fw-semibold'> {paymentData.amountPaid ? paymentData.amountPaid : ' -'}</span>
                </label>

              </div>
            </div>
            <div className="col">
              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold mb-2">Payment Mode :
                  <span className='fs-6 fw-semibold'>  {paymentData.paymentModeName ? paymentData.paymentModeName : '-'}</span>
                </label>


              </div>
            </div>


          </div>
          <div className="row row-cols-1 row-cols-md-2">
            
            <div className="col">
              <div className="fv-row fv-plugins-icon-container">
                <label className="fs-5 fw-bold mb-2">Payment Date :
                  <span className='fs-6 fw-semibold'>  {paymentData.paymentDate ? formatDate(paymentData.paymentDate, 'dd/MM/yyyy') : 'No Date available'}</span>
                </label>


              </div>
            </div>


          </div>
          <div className="row row-cols-1 row-cols-md-2">

            <label className="fs-5 fw-bold mb-2">Notes :
              <span className='fs-6 fw-semibold'>  {paymentData.notes ? paymentData.notes : '-'}</span>
            </label>
          </div>
        </div >
      </div >


<KTCard>
  <KTCardBody>
  <div className='d-flex justify-content-end'>
        <button
          type='button'
          className='btn btn-primary me-3'
          onClick={() => navigate('/purchase/payment/list')}
        >
          Back
        </button>
        <button
          type='button'
          className='btn btn-primary me-3'

        >
          Download Pdf
        </button>

      </div>
  </KTCardBody>
</KTCard>
      

    </>
  )
}

export default PaymentDetailPage
