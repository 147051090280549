import axios, {AxiosResponse} from 'axios'

import {ID, PaginationFilter} from '../../../../../_metronic/helpers/crud-helper/models'
import {Result} from '../../../../core/models/Result'
import {LowProductStockQueryResponse} from './_model'

const API_URL = process.env.REACT_APP_STORE_API_URL

const LOWPRODUCTSTOCK_LIST_URL = `${API_URL}/report/lowproductstock`

const getLowProductStock = (filter: PaginationFilter): Promise<LowProductStockQueryResponse> => {
  return axios
    .post(`${LOWPRODUCTSTOCK_LIST_URL}`, filter)
    .then((d: AxiosResponse<LowProductStockQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

export {getLowProductStock}
