
import {useQuery} from 'react-query'

import React  from 'react'
import { QUERIES } from '../../../../../../_metronic/helpers'
import {  getSalesOrderSetting } from '../../../core/_requests'
import { SaleOrderForm } from './SaleOrderForm'

export const SaleOrderPage = () => {
  const {isLoading, data: result, error: error} = useQuery([`${QUERIES.SO_SETTING}`], async () => {
    return await getSalesOrderSetting()
  })
  
 if (!isLoading && !error && result) {

    return <SaleOrderForm data={result} />
  }
  return (
  
    null
  )
}

