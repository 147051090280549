import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import { useQueryResponseData, useQueryResponseLoading, useQueryResponsePagination } from '../core/QueryResponseProvider'
import {  ProductGroup } from '../core/_models'
import { KTCardBody, KTIcon } from '../../../../../../_metronic/helpers'
import { ProductsColumns } from './columns'
import { CustomHeaderColumn } from './CustomHeaderColumn'
import { CustomRow } from './CustomRow'
import Pagination from '../../../../../core/shared/components/Pagination'
import { useQueryRequest } from '../core/QueryRequestProvider'
import { Loading } from '../../../../../core/shared/components/Loading'
import { MustHavePermission, SWAction, SWResource, SWSection } from '../../../../../core/shared/core/permissionUtils'
import { useNavigate } from 'react-router-dom'
import { ProductGroupListToolbar } from '../components/header/ProductGroupListToolbar'


const ProductGroupTable = () => {
  const pagination = useQueryResponsePagination();
  const { updateState } = useQueryRequest();
  const products = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => products, [products])
  const columns = useMemo(() => ProductsColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  const openAddUserModal = () => {
    //  setItemIdForUpdate(null)    //to redirect to modal
    navigate('/catalog/productgroup/add')   //to redirect to add page

  }
  const navigate = useNavigate();

  return (
    <>
    <div className='card-header'>
      <div  className='card-title justify-content-between w-100'>
        <h3>Product Group</h3>
        <div className='d-flex gap-3'>
        {MustHavePermission(SWSection.Store, SWResource.ProductGroups, SWAction.Create) ?
        <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
          <KTIcon iconName='plus' className='fs-2' />
          Add
        </button>
        :
        null}
        <ProductGroupListToolbar />
        </div>

      </div>
    </div>    
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_location'
          className='table border table-striped table-row-bordered gy-5 gs-7'
          {...getTableProps()}
        >
          <thead>
            <tr className='fw-semibold fs-6 text-gray-800'>
              {headers.map((column: ColumnInstance<ProductGroup>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<ProductGroup>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination pagination={pagination} isLoading={isLoading} updateState={updateState} />

      {isLoading && <Loading />}
    </KTCardBody>
    </>
  )
}

export {ProductGroupTable}
