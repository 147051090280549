import React, {useEffect, useState} from 'react'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/flatpickr.min.css'
import {format} from 'date-fns'
import {toZonedTime} from 'date-fns-tz'
import {Filter, FilterOption} from '../../_models'
import {AutoComplete, AutoCompleteCompleteEvent} from 'primereact/autocomplete'
import {useQuery} from 'react-query'
import {getVendorList} from '../../_request'
import {InputText} from 'primereact/inputtext'
import {Calendar} from 'primereact/calendar'

const PMAdvanceSearch = ({
  refetch,
  onFilterQueryChange,
}: {
  refetch: () => void
  onFilterQueryChange: (query: any) => void
}) => {
  const [advancedSearchVisible, setAdvancedSearchVisible] = useState(false)
  const [filterStatus, updateFilterStatus] = useState<boolean | undefined>()
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined)
  const [filterName, updateFilterName] = useState<string>('')
  const [filterPaymentNumber, updateFilterPaymentNumber] = useState<string>('')
  const [filterPaymentDateFrom, setFilterPaymentDateFrom] = useState<string | undefined | Date>(
    undefined
  )
  const [filterPaymentDateTo, setFilterPaymentDateTo] = useState<string | undefined | Date>(
    undefined
  )
  const [filterOptions, setFilterOptions] = useState<FilterOption>({})
  const [filterQuery, setFilterQuery] = useState({})
  const [items, setItems] = useState<string[]>([])

  const {data: vendorList, isLoading: vendorListLoading} = useQuery({
    queryKey: ['vendorList'],
    queryFn: () =>
      getVendorList({
        pageSize: 100,
      }),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  })

  const search = (event: AutoCompleteCompleteEvent) => {
    if (vendorListLoading) return

    setItems(
      vendorList?.data
        ?.filter((item: any) => item?.printName?.toLowerCase()?.includes(event.query.toLowerCase()))
        ?.map((item) => item?.printName) || ['No vendor found']
    )
  }

  const updateSearchFilters = () => {
    let filters: Filter[] = []
    if (filterStatus != undefined) {
      filters.push({
        field: 'paymentModeName',
        operator: 'eq',
        value: filterStatus,
      })
    }

    if (filterOptions.filterVendorName != undefined && filterOptions.filterVendorName != '') {
      filters.push({
        field: 'vendor.printName',
        operator: 'contains',
        value: filterOptions.filterVendorName,
      })
    }

    if (filterOptions.filterPaymentNumber != undefined && filterOptions.filterPaymentNumber != '') {
      filters.push({
        field: 'paymentNumber',
        operator: 'contains',
        value: filterOptions.filterPaymentNumber,
      })
    }

    if (filterOptions?.filterDates != undefined) {
      let orderDateFilters: Filter[] = []
      if (filterOptions?.filterDates?.[0] !== undefined) {
        const fromDate = toZonedTime(
          new Date(filterOptions?.filterDates?.[0] as Date),
          'Asia/Kolkata'
        )
        orderDateFilters.push({
          field: 'paymentDate',
          operator: 'gte',
          value: format(fromDate, 'yyyy-MM-dd 00:00:00'),
        })
      }

      if (filterOptions?.filterDates?.[1] === null) {
        const toDate = toZonedTime(new Date(filterOptions.filterDates[0] as Date), 'Asia/Kolkata')

        orderDateFilters.push({
          field: 'paymentDate',
          operator: 'lte',
          value: format(toDate, 'yyyy-MM-dd 23:59:59'),
        })
      } else {
        const toDate = toZonedTime(new Date(filterOptions.filterDates[1] as Date), 'Asia/Kolkata')

        orderDateFilters.push({
          field: 'paymentDate',
          operator: 'lte',
          value: format(toDate, 'yyyy-MM-dd 23:59:59'),
        })
      }

      filters.push({
        filters: orderDateFilters,
        logic: 'and',
      })
    }

    if (filters.length > 1) {
      const newFilterQuery = {
        ...filterQuery,
        advancedFilter: {
          filters: filters,
          logic: 'and',
        },
      }
      setFilterQuery(newFilterQuery)
      onFilterQueryChange(newFilterQuery)
    } else if (filters.length === 1) {
      const newFilterQuery = {
        ...filterQuery,
        keyword: searchTerm,
        advancedFilter: filters[0],
      }
      setFilterQuery(newFilterQuery)
      onFilterQueryChange(newFilterQuery)
    } else {
      const newFilterQuery = {
        ...filterQuery,
        keyword: searchTerm,
        advancedFilter: undefined,
      }
      setFilterQuery(newFilterQuery)
      onFilterQueryChange(newFilterQuery)
    }
  }

  const clearFilters = () => {
    setFilterQuery({
      keyword: '',
      advancedFilter: undefined,
    })
    setFilterOptions({})
    refetch()
  }

  const handleDateChange = (selectedDates: Date[], dateName: string) => {
    if (dateName === 'from') {
      if (selectedDates[0] === undefined) {
        setFilterPaymentDateFrom('')
      } else {
        setFilterPaymentDateFrom(selectedDates[0])
      }
    } else if (dateName === 'to') {
      if (selectedDates[0] === undefined) {
        setFilterPaymentDateTo('')
      } else {
        setFilterPaymentDateTo(selectedDates[0])
      }
    }
  }

  return (
    <>
      <div className='card mb-5'>
        <div className='card-header justify-content-between align-items-center'>
          <div className='card-title'>
            <h3>Payment Made Filter</h3>
          </div>
          <button className='btn' onClick={() => setAdvancedSearchVisible(!advancedSearchVisible)}>
            {advancedSearchVisible ? (
              <img src='/media/icons/up-angle.svg' alt='Filter' height={25} width={25} />
            ) : (
              <img src='/media/icons/down-angle.svg' alt='Filter' height={25} width={25} />
            )}
          </button>
        </div>
        {advancedSearchVisible && (
          <>
            <div className='card-body'>
              <div className='row g-5 mb-5'>
                <div className='col-lg-4'>
                  <label className='fs-6 form-label fw-bold text-dark'>Payment Number</label>

                  
                    <InputText
                      name='paymentNumber'
                      placeholder='Enter Payment Number'
                      autoComplete='off'
                      value={filterOptions?.filterPaymentNumber}
                      onChange={(e) =>
                        setFilterOptions({...filterOptions, filterPaymentNumber: e.target.value})
                      }
                      className='form-control'
                      style={{height: '3rem'}}
                    />
                  
                </div>

                <div className='col-lg-4'>
                  <label className='fs-6 form-label fw-bold text-dark'>Vendor Name</label>

                  <div style={{borderRadius: '4px', border: '1px solid #cccccc', padding: '0'}}>
                    <AutoComplete
                      value={filterOptions?.filterVendorName}
                      suggestions={items}
                      minLength={3}
                      placeholder='Enter Vendor Name'
                      showEmptyMessage={true}
                      dropdown
                      completeMethod={search}
                      emptyMessage='No vendor found'
                      name={'vendorName'}
                      onChange={(e) =>
                        setFilterOptions({...filterOptions, filterVendorName: e.value})
                      }
                      onSelect={(e) =>
                        setFilterOptions({...filterOptions, filterVendorName: e.value})
                      }
                      onUnselect={() => setFilterOptions({...filterOptions, filterVendorName: ''})}
                      className='p-autocomplete p-autocomplete-input p-inputtext p-component p-autocomplete-dd-input'
                    />
                  </div>
                </div>
                <div className='col-lg-4'>
                  <label className='fs-6 form-label fw-bold text-dark'>Payment Date</label>
                  <div className='w-100 p-calendar-wraper'>
                    <Calendar
                      value={filterOptions?.filterDates as Date[]}
                      onChange={(e) => {
                        setFilterOptions({...filterOptions, filterDates: e.value as Date[]})
                      }}
                      selectionMode='range'
                      placeholder='Select payment Date'
                      formatDateTime={(value) => format(value, 'dd/MM/yyyy')}
                      readOnlyInput
                      hideOnRangeSelection
                      style={{width: '20rem', height: '3rem'}}
                      showButtonBar
                      className='w-100'
                      inputClassName='form-control form-control-solid'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='card-footer d-flex gap-3 justify-content-end'>
              <button className='btn btn-primary' id='btnSearch' onClick={clearFilters}>
                Clear Filters
              </button>
              <button className='btn btn-primary' id='btnSearch' onClick={updateSearchFilters}>
                Search
              </button>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default PMAdvanceSearch

// PMAdvanceSearch
