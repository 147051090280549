/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import axios from 'axios'
import {useEffect, useState} from 'react'
import {getPermissions} from '../../../../app/modules/auth/core/_requests'
import {useAuth} from '../../../../app/modules/auth'
import {
  MustHavePermission,
  SWSection,
  SWResource,
  SWAction,
} from '../../../../app/core/shared/core/permissionUtils'

export function AsideMenuMain() {
  const intl = useIntl()
  const {currentUser} = useAuth()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Masters</span>
        </div>
      </div>

      <>
        <AsideMenuItemWithSub to='' title='Catalog' icon='gift'>
          <AsideMenuItem
            to='/catalog/brands'
            title='Brands'
            hasBullet={true}
            hasPermission={MustHavePermission(SWSection.Store, SWResource.Brands, SWAction.Search)}
          />
          <AsideMenuItem
            to='/catalog/manufacturer'
            title='Manufacturer'
            hasBullet={true}
            hasPermission={MustHavePermission(
              SWSection.Store,
              SWResource.Manufacturers,
              SWAction.Search
            )}
          />
          <AsideMenuItem
            to='/catalog/categories/list'
            title='Categories'
            hasBullet={true}
            hasPermission={MustHavePermission(
              SWSection.Store,
              SWResource.Categories,
              SWAction.Search
            )}
          />
          <AsideMenuItem
            to='/catalog/productgroup/list'
            title='Product Group'
            hasBullet={true}
            hasPermission={MustHavePermission(
              SWSection.Store,
              SWResource.ProductGroups,
              SWAction.Search
            )}
          />
          <AsideMenuItem
            to='/catalog/product/list'
            title='Products'
            hasBullet={true}
            hasPermission={MustHavePermission(
              SWSection.Store,
              SWResource.Products,
              SWAction.Search
            )}
          />
          <AsideMenuItem to='/catalog/print-barcode' title='Print Barcode' hasBullet={true} />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='' title='Sales' icon='gift'>
          <AsideMenuItem
            to='/sales/agent/list'
            title='Agent'
            hasBullet={true}
            hasPermission={MustHavePermission(SWSection.Store, SWResource.Agents, SWAction.Search)}
          />
          <AsideMenuItem
            to='/sales/customers/list'
            title='Customers'
            hasBullet={true}
            hasPermission={MustHavePermission(
              SWSection.Store,
              SWResource.Customers,
              SWAction.Search
            )}
          />
          <AsideMenuItem
            to='/sales/sale-order/list'
            title='Sale Order'
            hasBullet={true}
            hasPermission={MustHavePermission(
              SWSection.Store,
              SWResource.SaleOrders,
              SWAction.Search
            )}
          />
          <AsideMenuItem
            to='/sales/sale-order/taxed-list'
            title='Taxed Sales Order'
            hasBullet={true}
            hasPermission={MustHavePermission(
              SWSection.Store,
              SWResource.SaleOrders,
              SWAction.Search
            )}
          />
          <AsideMenuItem
            to='/sales/package/list'
            title='Package'
            hasBullet={true}
            hasPermission={MustHavePermission(
              SWSection.Store,
              SWResource.Packages,
              SWAction.Search
            )}
          />
          <AsideMenuItem
            to='/sales/invoice/list'
            title='Invoice'
            hasBullet={true}
            hasPermission={MustHavePermission(
              SWSection.Store,
              SWResource.Invoices,
              SWAction.Search
            )}
          />
          <AsideMenuItem
            to='/sales/shipment/list'
            title='Shipment'
            hasBullet={true}
            hasPermission={MustHavePermission(
              SWSection.Store,
              SWResource.Shipments,
              SWAction.Search
            )}
          />
          {/* <AsideMenuItem to='/sales/pos/add' title='POS' hasBullet={true} /> */}
          <AsideMenuItem hasBullet={true} to='/sales/payment/list' title='Payment' />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='' title='Purchase' icon='gift'>
          <AsideMenuItem
            to='/purchase/vendor/list'
            title='Vendor'
            hasBullet={true}
            hasPermission={MustHavePermission(SWSection.Store, SWResource.Vendors, SWAction.Search)}
          />
          <AsideMenuItem
            to='/purchase/purchase-order/list'
            title='Purchase Order'
            hasBullet={true}
            hasPermission={MustHavePermission(
              SWSection.Store,
              SWResource.PurchaseOrders,
              SWAction.Search
            )}
          />
          <AsideMenuItem
            to='/purchase/expensecategory/list'
            title='Expense Category'
            hasBullet={true}
            hasPermission={MustHavePermission(
              SWSection.Store,
              SWResource.ExpenseCategories,
              SWAction.Search
            )}
          />
          <AsideMenuItem
            to='/purchase/expense/list'
            title='Expense'
            hasBullet={true}
            hasPermission={MustHavePermission(
              SWSection.Store,
              SWResource.Expenses,
              SWAction.Search
            )}
          />
          <AsideMenuItem
            to='/purchase/purchase-receive/list'
            title='Purchase Receive'
            hasBullet={true}
            hasPermission={MustHavePermission(
              SWSection.Store,
              SWResource.PurchaseReceives,
              SWAction.Search
            )}
          />
          <AsideMenuItem to='/purchase/bills/list' title='Bills' hasBullet={true} />
          <AsideMenuItem to='/purchase/payment-made/list' title='Payment Made' hasBullet={true} />
        </AsideMenuItemWithSub>

        <AsideMenuItem to='/reports/stocks/list' title='Reports' icon='ki-duotone ki-basket' />

        {/* <AsideMenuItemWithSub title='Low Stock Reports' to='' icon='ki-duotone ki-basket'  >
          <AsideMenuItem hasBullet={true}  to='/reports/lowstock/product/list' title='Products' />
        </AsideMenuItemWithSub> */}

        <AsideMenuItemWithSub to='' icon='gift' title='Settings'>
          <AsideMenuItem to='/settings/general' title='General' hasBullet={true} />

          <AsideMenuItem to='/settings/salesetting' title='Sales' hasBullet={true} />
          <AsideMenuItem
            to='/settings/role/list'
            title='Role'
            hasBullet={true}
            hasPermission={MustHavePermission(SWSection.Store, SWResource.Roles, SWAction.View)}
          />
          <AsideMenuItem
            to='/settings/staff/list'
            title='Staff'
            hasBullet={true}
            hasPermission={MustHavePermission(SWSection.Store, SWResource.Staffs, SWAction.View)}
          />
          <AsideMenuItem
            to='/settings/account/list'
            title='Account'
            hasBullet={true}
            hasPermission={MustHavePermission(SWSection.Store, SWResource.Accounts, SWAction.View)}
          />
          <AsideMenuItem
            to='/settings/customervendor'
            title='Customer Vendor'
            hasBullet={true}
            hasPermission={MustHavePermission(
              SWSection.Store,
              SWResource.CustomerVendorSettings,
              SWAction.View
            )}
          />
          <AsideMenuItem
            to='/settings/purchasesetting'
            title='Purchase Order'
            hasBullet={true}
            hasPermission={MustHavePermission(
              SWSection.Store,
              SWResource.PurchaseOrderSettings,
              SWAction.View
            )}
          />
          <AsideMenuItem
            to='/settings/paymentmode/list'
            title='Payment Mode'
            hasBullet={true}
            hasPermission={MustHavePermission(
              SWSection.Store,
              SWResource.PaymentModes,
              SWAction.View
            )}
          />
          <AsideMenuItem
            to='/settings/autonumbergeneration-setting'
            title='Series Setting'
            hasBullet={true}
            hasPermission={MustHavePermission(
              SWSection.Store,
              SWResource.AutoNumberGenerationGroups,
              SWAction.View
            )}
          />

          <AsideMenuItem
            to='/settings/bank-account/list'
            title='Bank Account'
            hasBullet={true}
            hasPermission={MustHavePermission(SWSection.Store, SWResource.Accounts, SWAction.View)}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='' icon='gift' title='CMS'>
          <AsideMenuItem to='/cms/banner' title='Banner' hasBullet={true} />
          <AsideMenuItem to='/cms/seo' title='SEO' hasBullet={true} />
          <AsideMenuItem to='/cms/testimonial' title='Testimonial' hasBullet={true} />
          <AsideMenuItem to='/cms/collection' title='Collection' hasBullet={true} />
          <AsideMenuItem to='/cms/newarrival' title='New Arrivals' hasBullet={true} />
        </AsideMenuItemWithSub>
      </>
    </>
  )
}
