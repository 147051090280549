import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {KTCard, KTCardBody} from '../../../../../_metronic/helpers'
import {useState} from 'react'
import {useQuery} from 'react-query'
import {getBillsList} from '../_requests'
import {Loading} from '../../../../core/shared/components/Loading'
import {formatCurrency, formatDate} from '../../../../core/utils/helpers'
import BillAdvancedSearch from '../components/BillAdvanceSearch'
import {BillListListToolbar} from '../components/BillListToolbar'
import {BillsActionsCell} from '../components/bill-table/BillsActionsCell'
import {useImmer} from 'use-immer'
import {InputText} from 'primereact/inputtext'
// import BillAdvancedSearch from '../conponents/BillAdvanceSearch'

const BillsListTable = () => {
  const [filterQuery, setFilterQuery] = useState({})
  const [globalFilterValue, setGlobalFilterValue] = useState<string>('')
  const [paginationFilters, setPaginationFilters] = useImmer({
    pageNumber: 1,
    pageSize: 10,
    orderBy: ['billDate desc'] as string[],
    order: 'desc' as 'asc' | 'desc',
    first: 0,
    sortField: 'billDate',
  })

  const handleFilterQueryChange = (newFilterQuery: any) => {
    setFilterQuery(newFilterQuery)
  }

  const {
    data: response,
    refetch,
    isLoading,
  } = useQuery(
    ['getBillRecords', paginationFilters, filterQuery],
    async () => {
      return await getBillsList({
        ...filterQuery,
        ...paginationFilters,
        order: paginationFilters.order as 'asc' | 'desc',
      })
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const onSort = (event: any) => {
    setPaginationFilters((draft) => {
      draft.pageNumber = event.page === undefined ? 1 : (event.page as number) + 1
      draft.pageSize = event.rows
      draft.first = event.first
      draft.sortField = event.sortField
      event.sortField && (draft.order = event.sortOrder === 1 ? 'asc' : 'desc')
      event.sortField &&
        (draft.orderBy = [`${event.sortField} ${event.sortOrder === 1 ? 'asc' : 'desc'}`])
    })
  }

  const renderHeader = () => (
    <div className='flex justify-content-end'>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          placeholder='Keyword Search'
          value={globalFilterValue}
          onChange={(e) => setGlobalFilterValue(e.target.value)}
        />
      </span>
    </div>
  )

  const header = renderHeader()

  const filteredData = response?.data?.filter((item) =>
    Object.values(item).some((value: any) => {
      return (
        typeof value === 'string' && value.toLowerCase().includes(globalFilterValue.toLowerCase())
      )
    })
  )
  const leftContent = (
    <span className='mx-3' style={{color: 'var(--text-color)', userSelect: 'none'}}>
      {`Showing ${(response?.pagination?.totalCount as number) > 0 ? (paginationFilters.first as number) + 1 : 0} to 
            ${Math.min(
              (response?.pagination?.currentPage as number) *
                (response?.pagination?.pageSize as number),
              response?.pagination?.totalCount as number
            )} 
            out of ${response?.pagination?.totalCount} Records`}
    </span>
  )

  return (
    <>
      <BillAdvancedSearch refetch={refetch} onFilterQueryChange={handleFilterQueryChange} />
      <KTCard>
        <div className='card-header justify-content-bewteen align-items-center'>
          <div className='card-title'>
            <h3>Bills</h3>
          </div>
          <BillListListToolbar />
        </div>
        <KTCardBody className='py-4'>
          <DataTable
            value={filteredData}
            stripedRows
            header={header}
            showGridlines
            filterDisplay='menu'
            tableClassName='table table-bordered table-hover mb-0'
            tableStyle={{minWidth: '50rem'}}
            sortOrder={paginationFilters.order === 'asc' ? 1 : -1}
            sortField={paginationFilters?.sortField}
            loading={isLoading}
            sortMode='single'
            currentPageReportTemplate='Showing {first} to {last} of {totalRecords} records'
            paginatorClassName='dataTables_paginate paging_bootstrap_full_number mt-0'
            paginator
            paginatorLeft={leftContent}
            rows={paginationFilters.pageSize}
            totalRecords={response?.pagination?.totalCount}
            emptyMessage='No Bills found.'
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            onSort={onSort}
            onPage={onSort}
            first={paginationFilters.first}
            lazy
          >
            <Column
              field={'billNumber'}
              header={'Bill Number'}
              sortable={true}
              sortField='billNumber'
            />
            <Column
              field={'billDate'}
              header={'Bill Date'}
              body={(rowData) => formatDate(rowData?.billDate, 'dd MMM yyyy')}
              sortable={true}
              sortField='billDate'
            />
            <Column
              field={'vendorName'}
              header={'Vendor Name'}
              sortable={true}
              sortField='vendor.printName'
            />

            <Column
              field={'paymentStatusName'}
              header={'Payment Status'}
              sortable={true}
              sortField='paymentStatus.name'
            />
            <Column
              field={'orderTotal'}
              header={'Order Total'}
              body={(rowData) => formatCurrency(rowData?.orderTotal)}
              sortable={true}
              sortField='orderTotal'
            />
            <Column
              field={'amountPaid'}
              header={'Amount Paid'}
              body={(rowData) => formatCurrency(rowData?.amountPaid)}
              sortable={true}
              sortField='amountPaid'
            />
            <Column
              field={'actions'}
              header={'Actions'}
              body={(rowData) => (
                <BillsActionsCell id={rowData.id} paymentStatusId={rowData.paymentStatusId} />
              )}
            />
          </DataTable>

          {isLoading && <Loading />}
        </KTCardBody>
      </KTCard>
    </>
  )
}

export {BillsListTable}
