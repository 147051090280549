import {useQuery} from 'react-query'
import React  from 'react'
import { QUERIES } from '../../../../../_metronic/helpers'
import {  getEmailSetting } from '../../core/_requests'
import { EmailSettingForm } from './EmailSettingForm'

export const EmailSettingPage = () => {
  const {isLoading, data: result, error: error} = useQuery([`${QUERIES.EMAIL_SETTING}`], async () => {
    return await getEmailSetting()
  })
  
 if (!isLoading && !error && result) {

    return <EmailSettingForm data={result} />
  }
  return (
  
    null
  )
}

