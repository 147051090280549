import axios, { AxiosResponse } from 'axios'
import { ID, PaginationFilter } from "../../../../../_metronic/helpers/crud-helper/models"

import { Result } from '../../../../core/models/Result'
import { Testimonial, TestimonialQueryResponse } from './_models'
const API_URL = process.env.REACT_APP_STORE_API_URL
const Testimonial_LIST_URL = `${API_URL}/testimonial/search`
const Testimonial_BY_ID_URL = `${API_URL}/testimonial`
const Testimonial_ADD_URL = `${API_URL}/testimonial`

const getTestimonialList = async (filter: PaginationFilter): Promise<TestimonialQueryResponse> => {
  return await axios
    .post(`${Testimonial_LIST_URL}`, filter)
    .then((d: AxiosResponse<TestimonialQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}
const getTestimonialById = async (id: ID): Promise<Result | Testimonial> => {
  return axios
    .get(`${Testimonial_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<Testimonial>) => response.data)
    .then((response: Testimonial) => response)
    .catch((err: Result) => {
      return err
    })
}
const createTestimonial = async (testimonial: Testimonial): Promise<Result> => {
  return await axios
    .post(Testimonial_ADD_URL, {
      name: testimonial.name?.trim(),
      uploadImage: testimonial.uploadImage,
      imagePath: testimonial.imagePath,
      description: testimonial.description,
      designation: testimonial.designation

    })
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const updateTestimonial = (testimonial: Testimonial): Promise<Result> => {
  return axios
    .put(`${Testimonial_BY_ID_URL}/${testimonial.id}`, {
      id: testimonial.id,
      name: testimonial.name?.trim(),
      uploadImage: testimonial.uploadImage,
      imagePath: testimonial.imagePath,
      description: testimonial.description,
      designation: testimonial.designation
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}

const deleteTestimonial = async (id: ID): Promise<Result | Testimonial> => {
  return axios
    .delete(`${Testimonial_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<Testimonial>) => response.data)
    .then((response: Testimonial) => response)
    .catch((err: Result) => {
      return err
    })
}


export {
  getTestimonialList,
  getTestimonialById,
  createTestimonial,
  updateTestimonial,
  deleteTestimonial,
 

}
