import React, {useState} from 'react'
import {format} from 'date-fns'
import {toZonedTime} from 'date-fns-tz'
import {Filter, FilterOption, Vendor} from '../_models'
import {AutoComplete, AutoCompleteCompleteEvent} from 'primereact/autocomplete'
import {useQuery} from 'react-query'
import {getVendorList} from '../../paymentMade/_request'
import {Calendar} from 'primereact/calendar'
import {MultiSelect} from 'primereact/multiselect'
import {getPaymentStatus} from '../../../../core/shared/core/_request'
import {InputText} from 'primereact/inputtext'

const BillAdvancedSearch = ({
  refetch,
  onFilterQueryChange,
}: {
  refetch: () => void
  onFilterQueryChange: (query: any) => void
}) => {
  const [advancedSearchVisible, setAdvancedSearchVisible] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined)
  const [filterOptions, setFilterOptions] = useState<FilterOption>({
    filterBillNumber: '',
    filterDates: undefined,
    filterOrderStatus: [],
    filterPaymentStatus: [],
    filterVendorName: '',
  })
  const [filterQuery, setFilterQuery] = useState({})
  const [items, setItems] = useState<string[]>([])

  const {data: vendorList, isLoading: vendorListLoading} = useQuery({
    queryKey: ['vendorList'],
    queryFn: () =>
      getVendorList({
        pageSize: 100,
      }),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  })

  const {data: paymentStatusList} = useQuery({
    queryKey: ['paymentStatusList'],
    queryFn: () => getPaymentStatus(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  })

  const search = (event: AutoCompleteCompleteEvent) => {
    if (vendorListLoading) return

    setItems(
      vendorList?.data
        ?.filter((item: any) => item?.printName?.toLowerCase()?.includes(event.query.toLowerCase()))
        ?.map((item) => item?.printName) || ['No vendor found']
    )
  }

  const updateSearchFilters = () => {
    let filters: Filter[] = []
    if (filterOptions?.filterPaymentStatus != undefined) {
      if (filterOptions?.filterPaymentStatus?.length == 1) {
        filters.push({
          field: 'PaymentStatusId',
          operator: 'eq',
          value: Number(filterOptions?.filterPaymentStatus[0].id),
        })
      } else {
        let paymentStatusFilters: Filter = {
          logic: 'or',
          filters: [],
        }
        if (filterOptions?.filterPaymentStatus.length > 1) {
          filterOptions?.filterPaymentStatus?.map((item: any) => {
            paymentStatusFilters?.filters?.push({
              field: 'PaymentStatusId',
              operator: 'eq',
              value: Number(item.id),
            })
          })
          filters.push(paymentStatusFilters)
        }
      }
    }

    if (filterOptions.filterVendorName != undefined && filterOptions.filterVendorName != '') {
      filters.push({
        field: 'vendor.printName',
        operator: 'contains',
        value: filterOptions.filterVendorName,
      })
    }

    if (filterOptions.filterBillNumber != undefined && filterOptions.filterBillNumber != '') {
      filters.push({
        field: 'billNumber',
        operator: 'contains',
        value: filterOptions.filterBillNumber,
      })
    }
    if (filterOptions?.filterDates != undefined) {
      let orderDateFilters: Filter[] = []
      if (filterOptions?.filterDates?.[0] !== undefined) {
        const fromDate = toZonedTime(
          new Date(filterOptions?.filterDates?.[0] as Date),
          'Asia/Kolkata'
        )
        orderDateFilters.push({
          field: 'billDate',
          operator: 'gte',
          value: format(fromDate, 'yyyy-MM-dd 00:00:00'),
        })
      }

      if (filterOptions?.filterDates?.[1] === null) {
        const toDate = toZonedTime(new Date(filterOptions.filterDates[0] as Date), 'Asia/Kolkata')

        orderDateFilters.push({
          field: 'billDate',
          operator: 'lte',
          value: format(toDate, 'yyyy-MM-dd 23:59:59'),
        })
      } else {
        const toDate = toZonedTime(new Date(filterOptions.filterDates[1] as Date), 'Asia/Kolkata')

        orderDateFilters.push({
          field: 'BillDate',
          operator: 'lte',
          value: format(toDate, 'yyyy-MM-dd 23:59:59'),
        })
      }

      filters.push({
        filters: orderDateFilters,
        logic: 'and',
      })
    }

    if (filters.length > 1) {
      const newFilterQuery = {
        ...filterQuery,
        advancedFilter: {
          filters: filters,
          logic: 'and',
        },
      }
      setFilterQuery(newFilterQuery)
      onFilterQueryChange(newFilterQuery)
    } else if (filters.length === 1) {
      const newFilterQuery = {
        ...filterQuery,
        keyword: searchTerm,
        advancedFilter: filters[0],
      }
      setFilterQuery(newFilterQuery)
      onFilterQueryChange(newFilterQuery)
    } else {
      const newFilterQuery = {
        ...filterQuery,
        keyword: searchTerm,
        advancedFilter: undefined,
      }
      setFilterQuery(newFilterQuery)
      onFilterQueryChange(newFilterQuery)
    }
  }

  const clearFilters = () => {
    setFilterQuery({
      keyword: '',
      advancedFilter: undefined,
    })
    setFilterOptions({
      filterBillNumber: '',
      filterDates: undefined,
      filterOrderStatus: [],
      filterPaymentStatus: [],
      filterVendorName: '',
    })
    onFilterQueryChange({})
    refetch()
  }

  return (
    <>
      <div className='card mb-5'>
        <div className='card-header justify-content-between align-items-center'>
          <div className='card-title'>
            <h3>Bill Filter</h3>
          </div>
          <button className='btn' onClick={() => setAdvancedSearchVisible(!advancedSearchVisible)}>
            {advancedSearchVisible ? (
              <img src='/media/icons/up-angle.svg' alt='Filter' height={25} width={25} />
            ) : (
              <img src='/media/icons/down-angle.svg' alt='Filter' height={25} width={25} />
            )}
          </button>
        </div>
        {advancedSearchVisible && (
          <>
            <div className='card-body'>
              <div className='row g-5 mb-5'>
                <div className='col-lg-4'>
                  <label className='fs-6 form-label fw-bold text-dark'>Bill Number</label>
                  <div className=''>
                    <InputText
                      name='billNumber'
                      placeholder='Enter Bill Number'
                      autoComplete='off'
                      value={filterOptions?.filterBillNumber}
                      onChange={(e) =>
                        setFilterOptions({...filterOptions, filterBillNumber: e.target.value})
                      }
                      style={{height: '3rem'}}
                      className='form-control'
                    />
                  </div>
                </div>

                <div className='col-lg-4'>
                  <label className='fs-6 form-label fw-bold text-dark'>Vendor Name</label>
                  <div style={{borderRadius: '4px', border: '1px solid #cccccc', padding: '0'}}>
                    <AutoComplete
                      value={filterOptions?.filterVendorName}
                      suggestions={items}
                      minLength={3}
                      placeholder='Enter Vendor Name'
                      showEmptyMessage={true}
                      dropdown
                      completeMethod={search}
                      emptyMessage='No vendor found'
                      name={'vendorName'}
                      onChange={(e) =>
                        setFilterOptions({...filterOptions, filterVendorName: e.value})
                      }
                      onSelect={(e) =>
                        setFilterOptions({...filterOptions, filterVendorName: e.value})
                      }
                      onUnselect={() => setFilterOptions({...filterOptions, filterVendorName: ''})}
                      className='p-autocomplete p-autocomplete-input p-inputtext p-component p-autocomplete-dd-input'
                    />
                  </div>
                </div>
                <div className='col-lg-4'>
                  <label className='fs-6 form-label fw-bold text-dark'>Bill Date</label>
                  <div className='w-100 p-calendar-wraper'>
                    <Calendar
                      value={filterOptions?.filterDates as Date[]}
                      onChange={(e) => {
                        setFilterOptions({...filterOptions, filterDates: e.value as Date[]})
                      }}
                      selectionMode='range'
                      placeholder='Select Bill Date'
                      formatDateTime={(value) => format(value, 'dd/MM/yyyy')}
                      readOnlyInput
                      hideOnRangeSelection
                      style={{width: '20rem', height: '3rem'}}
                      showButtonBar
                      className='w-100'
                    />
                  </div>
                </div>
                <div className='col-lg-4'>
                  <label className='fs-6 form-label fw-bold text-dark'>Payment Status</label>
                  <div className='w-100'>
                    <MultiSelect
                      value={filterOptions.filterPaymentStatus}
                      onChange={(e) =>
                        setFilterOptions({...filterOptions, filterPaymentStatus: e.value})
                      }
                      options={paymentStatusList}
                      optionLabel='name'
                      display='chip'
                      onRemove={(e) =>
                        setFilterOptions({...filterOptions, filterPaymentStatus: e.value})
                      }
                      placeholder='Select Payment Status'
                      maxSelectedLabels={3}
                      style={{height: '3rem'}}
                      className='w-100'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='card-footer d-flex gap-3 justify-content-end'>
              <button className='btn btn-primary' id='btnSearch' onClick={clearFilters}>
                Clear Filters
              </button>
              <button className='btn btn-primary' id='btnSearch' onClick={updateSearchFilters}>
                Search
              </button>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default BillAdvancedSearch
