import axios, { AxiosResponse } from 'axios'
import { ID, PaginationFilter } from "../../../../../../_metronic/helpers/crud-helper/models"
import { Brand, BrandQueryResponse } from './_models'
import { Result } from '../../../../../core/models/Result'
const API_URL = process.env.REACT_APP_STORE_API_URL
const Brand_LIST_URL = `${API_URL}/brand/search`
const Brand_BY_ID_URL = `${API_URL}/brand`
const Brand_ADD_URL = `${API_URL}/brand`

const getBrandList = async (filter: PaginationFilter): Promise<BrandQueryResponse> => {
  return await axios
    .post(`${Brand_LIST_URL}`, filter)
    .then((d: AxiosResponse<BrandQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}
const getBrandById = async (id: ID): Promise<Result | Brand> => {
  return axios
    .get(`${Brand_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<Brand>) => response.data)
    .then((response: Brand) => response)
    .catch((err: Result) => {
      return err
    })
}
const createBrand = async (brand: Brand): Promise<Result> => {
  return await axios
    .post(Brand_ADD_URL, {
      name: brand.name?.trim(),
      printName:brand.printName?.trim()
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const updateBrand = (brand: Brand): Promise<Result> => {
  return axios
    .put(`${Brand_BY_ID_URL}/${brand.id}`, {
      id: brand.id,
      name: brand.name?.trim(),
      printName:brand.printName?.trim(),
      isActive: brand.isActive

    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}

const deleteBrand = async (id: ID): Promise<Result | Brand> => {
  return axios
    .delete(`${Brand_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<Brand>) => response.data)
    .then((response: Brand) => response)
    .catch((err: Result) => {
      return err
    })
}
export {
  getBrandList,
  getBrandById,
  createBrand,
  updateBrand,
  deleteBrand

}
