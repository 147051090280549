import React, {FC, useState} from 'react'
import Select from 'react-select'
import {Filter, initialQueryState} from '../../../../../../_metronic/helpers'
// import { useQueryRequest } from '../../core/QueryRequestProvider';
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/flatpickr.min.css'
import {InvoiceListToolbar} from './InvoiceListToolbar'
import {format} from 'date-fns'
import {toZonedTime} from 'date-fns-tz'
import {FilterOption} from '../../core/_models'
import {InputText} from 'primereact/inputtext'
import {Calendar} from 'primereact/calendar'
import {MultiSelect} from 'primereact/multiselect'
import {useQuery} from 'react-query'
import {getCustomerList} from '../../../salesOrder/core/_requests'
import {AutoComplete, AutoCompleteCompleteEvent} from 'primereact/autocomplete'

interface Props {
  refetch: () => void
  onFilterQueryChange: (query: any) => void
}

const InvoiceAdvancedSearch: FC<Props> = ({refetch, onFilterQueryChange}) => {
  const [advancedSearchVisible, setAdvancedSearchVisible] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined)

  const [filterOptions, setFilterOptions] = useState<FilterOption>({
    filterCustomerName: '',
    filterInvoiceNumber: '',
    filterDates: undefined,
    filterInvoiceStatus: [],
  })
  const [filterQuery, setFilterQuery] = useState({})

  const invoiceStatusOptions = [
    {id: 1, name: 'Created'},
    {id: 2, name: 'Confirmed'},
    {id: 3, name: 'Cancelled'},
    {id: 4, name: 'Partially Packed'},
    {id: 5, name: 'Fully Packed'},
    {id: 6, name: 'Partially Delivered'},
    {id: 7, name: 'Delivered'},
  ]

  const [items, setItems] = useState<any>([])

  const {data: customerList, isLoading: customerListLoading} = useQuery({
    queryKey: ['customerList'],
    queryFn: () =>
      getCustomerList({
        pageSize: 100,
      }),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  })

  const search = (event: AutoCompleteCompleteEvent) => {
    if (customerListLoading) return

    setItems(
      customerList?.data
        ?.filter((item: any) => item?.printName?.toLowerCase()?.includes(event.query.toLowerCase()))
        ?.map((item) => item?.printName) || ['No Customer found']
    )
  }

  const updateSearchFilters = () => {
    let filters: Filter[] = []
    if (filterOptions?.filterInvoiceStatus != undefined) {
      if (filterOptions?.filterInvoiceStatus?.length == 1) {
        filters.push({
          field: 'InvoiceStatusId',
          operator: 'eq',
          value: Number(filterOptions?.filterInvoiceStatus[0].id),
        })
      } else {
        let invoiceStatusFilters: Filter = {
          logic: 'or',
          filters: [],
        }
        if (filterOptions?.filterInvoiceStatus.length > 1) {
          filterOptions?.filterInvoiceStatus?.map((item: any) => {
            invoiceStatusFilters?.filters?.push({
              field: 'InvoiceStatusId',
              operator: 'eq',
              value: Number(item.id),
            })
          })
          filters.push(invoiceStatusFilters)
        }
      }
    }

    if (filterOptions.filterCustomerName != undefined && filterOptions.filterCustomerName != '') {
      filters.push({
        field: 'saleorder.customer.printName',
        operator: 'contains',
        value: filterOptions.filterCustomerName,
      })
    }

    if (filterOptions.filterInvoiceNumber != undefined && filterOptions.filterInvoiceNumber != '') {
      filters.push({
        field: 'invoiceNumber',
        operator: 'contains',
        value: filterOptions.filterInvoiceNumber,
      })
    }

    if (filterOptions?.filterDates != undefined) {
      let invoiceDateFilters: Filter[] = []
      if (filterOptions?.filterDates?.[0] !== undefined) {
        const fromDate = toZonedTime(
          new Date(filterOptions?.filterDates?.[0] as Date),
          'Asia/Kolkata'
        )
        invoiceDateFilters.push({
          field: 'invoiceDate',
          operator: 'gte',
          value: format(fromDate, 'yyyy-MM-dd 00:00:00'),
        })
      }

      if (filterOptions?.filterDates?.[1] === null) {
        const toDate = toZonedTime(new Date(filterOptions.filterDates[0] as Date), 'Asia/Kolkata')

        invoiceDateFilters.push({
          field: 'invoiceDate',
          operator: 'lte',
          value: format(toDate, 'yyyy-MM-dd 23:59:59'),
        })
      } else {
        const toDate = toZonedTime(new Date(filterOptions.filterDates[1] as Date), 'Asia/Kolkata')

        invoiceDateFilters.push({
          field: 'invoiceDate',
          operator: 'lte',
          value: format(toDate, 'yyyy-MM-dd 23:59:59'),
        })
      }

      filters.push({
        filters: invoiceDateFilters,
        logic: 'and',
      })
    }

    if (filters.length > 1) {
      const newFilterQuery = {
        ...filterQuery,
        advancedFilter: {
          filters: filters,
          logic: 'and',
        },
      }
      setFilterQuery(newFilterQuery)
      onFilterQueryChange(newFilterQuery)
    } else if (filters.length === 1) {
      const newFilterQuery = {
        ...filterQuery,
        keyword: searchTerm,
        advancedFilter: filters[0],
      }
      setFilterQuery(newFilterQuery)
      onFilterQueryChange(newFilterQuery)
    } else {
      const newFilterQuery = {
        ...filterQuery,
        keyword: searchTerm,
        advancedFilter: undefined,
      }
      setFilterQuery(newFilterQuery)
      onFilterQueryChange(newFilterQuery)
    }
  }

  const clearFilters = () => {
    setFilterQuery({
      keyword: '',
      advancedFilter: undefined,
    })
    setFilterOptions({
      filterCustomerName: '',
      filterDates: undefined,
      filterInvoiceNumber: '',
      filterInvoiceStatus: [],
    })
    onFilterQueryChange({})
    refetch()
  }
  return (
    <>
      <div className='card mb-5'>
        <div className='card-header'>
          <div className='card-title justify-content-between w-100'>
            <h3>Invoice Filter</h3>
            <button
              className='btn'
              onClick={() => setAdvancedSearchVisible(!advancedSearchVisible)}
            >
              {advancedSearchVisible ? (
                <img src='/media/icons/up-angle.svg' alt='Filter' height={25} width={25} />
              ) : (
                <img src='/media/icons/down-angle.svg' alt='Filter' height={25} width={25} />
              )}
            </button>
          </div>
        </div>
        {advancedSearchVisible && (
          <>
            <div className='card-body'>
              {/* <div className="row">
                            <div className="col-lg-6">
                                <div className="input-group">
                                    <i className="ki-duotone ki-magnifier fs-3 text-gray-500 position-absolute top-50 translate-middle ms-5 me-5">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                    <input
                                        type="text"
                                        className="form-control form-control-solid ps-10"
                                        id="txtsearchText"
                                        placeholder="Search Invoice"
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                    <button className="btn btn-primary" id="btnSearch" onClick={updateSearchFilters}>
                                        Search
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <button
                                    className="btn btn-link"
                                    onClick={() => setAdvancedSearchVisible(!advancedSearchVisible)}
                                >
                                    Advanced Search
                                </button>
                            </div>
                            <div className='col-lg-3 '>
                                <InvoiceListToolbar />
                            </div>
                        </div>
                     */}

              <div className='collapse' id='kt_advanced_search_form'>
                <div className='separator separator-dashed mt-9 mb-6'></div>
              </div>

              <div className='row g-5'>
                <div className='col-xl-4 col-lg-6 col-md-4'>
                  <label className='fs-6 form-label fw-bold text-dark'>Invoice Number</label>
                  
                    <InputText
                      name='invoiceNumber'
                      placeholder='Enter Invoice Number'
                      autoComplete='off'
                      value={filterOptions?.filterInvoiceNumber}
                      onChange={(e) =>
                        setFilterOptions({...filterOptions, filterInvoiceNumber: e.target.value})
                      }
                      className='form-control'
                      style={{height: '3rem'}}
                    />
                  
                </div>

                <div className='col-xl-4 col-lg-6 col-md-4'>
                  <label className='fs-6 form-label fw-bold text-dark'>Customer Name</label>

                  <div style={{borderRadius: '4px', border: '1px solid #cccccc', padding: '0'}}>
                    <AutoComplete
                      value={filterOptions?.filterCustomerName}
                      suggestions={items}
                      minLength={3}
                      placeholder='Enter Vendor Name'
                      showEmptyMessage={true}
                      dropdown
                      completeMethod={search}
                      emptyMessage='No vendor found'
                      name={'vendorName'}
                      onChange={(e) =>
                        setFilterOptions({...filterOptions, filterCustomerName: e.value})
                      }
                      onSelect={(e) =>
                        setFilterOptions({...filterOptions, filterCustomerName: e.value})
                      }
                      onUnselect={() =>
                        setFilterOptions({...filterOptions, filterCustomerName: ''})
                      }
                      className='p-autocomplete p-autocomplete-input p-inputtext p-component p-autocomplete-dd-input'
                    />
                  </div>
                </div>

                <div className='col-xl-4 col-lg-6 col-md-4'>
                  <label className='fs-6 form-label fw-bold text-dark'>Invoice Date</label>
                  <div className='w-100 p-calendar-wraper'>
                    <Calendar
                      value={filterOptions?.filterDates as Date[]}
                      onChange={(e) => {
                        setFilterOptions({...filterOptions, filterDates: e.value as Date[]})
                      }}
                      selectionMode='range'
                      placeholder='Select Invoice Date'
                      formatDateTime={(value) => format(value, 'dd/MM/yyyy')}
                      readOnlyInput
                      hideOnRangeSelection
                      style={{width: '20rem', height: '3rem'}}
                      showButtonBar
                      className='w-100'
                      inputClassName='form-control form-control-solid'
                    />
                  </div>
                </div>

                <div className='col-lg-4'>
                  <label className='fs-6 form-label fw-bold text-dark'>Status</label>
                  <div className='w-100'>
                    <MultiSelect
                      value={filterOptions.filterInvoiceStatus}
                      onChange={(e) =>
                        setFilterOptions({...filterOptions, filterInvoiceStatus: e.value})
                      }
                      options={invoiceStatusOptions}
                      optionLabel='name'
                      display='chip'
                      selectAllLabel='Select All'
                      onRemove={(e) =>
                        setFilterOptions({...filterOptions, filterInvoiceStatus: e.value})
                      }
                      placeholder='Select Payment Status'
                      maxSelectedLabels={3}
                      style={{height: '3rem'}}
                      className='w-100'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='card-footer d-flex gap-3 justify-content-end'>
              <button className='btn btn-primary' id='btnSearch' onClick={clearFilters}>
                Clear Filters
              </button>
              <button className='btn btn-primary' id='btnSearch' onClick={updateSearchFilters}>
                Search
              </button>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default InvoiceAdvancedSearch
