import axios from 'axios'
import { AuthModel, UserModel } from './_models'

const API_URL = process.env.REACT_APP_STORE_API_URL

export const LOGIN_URL = `${API_URL}/staff/login`
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/staff/profile`
export const REQUEST_PASSWORD_URL = `${API_URL}/staff/forgot-password`
export const GET_PERMISSIONS=`${API_URL}/staff/permissions`
export const REFRESH_TOKEN_URL=`${API_URL}/staff/refresh-token`

// Server should return AuthModel
export function login(username: string, password: string ,tenantId:number) {
  const headers = {
    "tenant": tenantId
  };
  return axios.post<AuthModel>(LOGIN_URL, {
    username,
    password,
    
  }, { headers: headers })
}


export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  const headers = {
     "Authorization": `Bearer ${token}`, 
   

  };
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    headers: headers
  })
}

export function getPermissions(token: string) {   

  const headers = {
    "Authorization": `Bearer ${token}`, 
  };

  return axios.get<string[]>(GET_PERMISSIONS, {
    headers: headers
  })
}

export function refreshtoken(token: string, refreshToken: string) {
  const headers = {
    "tenant": 'ho'
  };
  return axios.post<AuthModel>(REFRESH_TOKEN_URL, {
    token,
    refreshToken,
  },{
    headers: headers
  })
}

