import {useNavigate} from 'react-router-dom'
import {
  MustHavePermission,
  SWAction,
  SWResource,
  SWSection,
} from '../../../../core/shared/core/permissionUtils'
import {KTIcon} from '../../../../../_metronic/helpers'

const BillListListToolbar = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className='d-flex justify-content-end align-items-center gap-2'>
        {MustHavePermission(SWSection.Store, SWResource.PurchaseOrders, SWAction.Create) ? (
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => navigate('/purchase/bills/add')}
          >
            <KTIcon iconName='plus' className='fs-2' />
            Add
          </button>
        ) : null}
      </div>
    </>
  )
}

export {BillListListToolbar}
