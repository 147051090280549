import {KTCard} from '../../../../../_metronic/helpers'
import ProductAdvancedSearch from './components/header/header/ProductAdvancedSearch'
import ProductStockListHeader from './components/header/header/ProductStockListHeader'

import RawMaterialListHeader from './components/header/header/ProductStockListHeader'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ProductStockTable} from './productstock-table/ProductStockTable'

const ProductStockList = () => {
  return (
    <>
    <ProductAdvancedSearch/>
      <KTCard>
        <ProductStockTable />
      </KTCard>
    </>
  )
}
const ProductStockListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ProductStockList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ProductStockListWrapper}
