import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import { useQueryResponseData, useQueryResponseLoading, useQueryResponsePagination } from '../core/QueryResponseProvider'
import { BrandColumns } from './columns'
import { CustomHeaderColumn } from './CustomHeaderColumn'
import { CustomRow } from './CustomRow'
import { KTCardBody } from '../../../../../_metronic/helpers'

import Pagination from '../../../../core/shared/components/Pagination'
import { useQueryRequest } from '../core/QueryRequestProvider'
import { Loading } from '../../../../core/shared/components/Loading'
import { SEO } from '../core/_models'


const SEOTable = () => {
  const pagination = useQueryResponsePagination();
  const { updateState } = useQueryRequest();
  const brand = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => brand, [brand])
  const columns = useMemo(() => BrandColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_seo'
          className='table border table-striped table-row-bordered gy-5 gs-7'
          {...getTableProps()}
        >
          <thead>
            <tr className='fw-semibold fs-6 text-gray-800'>
              {headers.map((column: ColumnInstance<SEO>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<SEO>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination pagination={pagination} isLoading={isLoading} updateState={updateState} />

      {isLoading && <Loading />}
    </KTCardBody>
  )
}

export {SEOTable}
