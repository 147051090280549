import {Field, FormikProvider, useFormik} from 'formik'
import * as Yup from 'yup'
import React, {useEffect, useState} from 'react'
import {ID, KTSVG, NewLine} from '../../../../../_metronic/helpers'

import {
  getBillDetails,
  getOrganizatinProfileAddress,
  getPlaceOfSupplyList,
  getVendorById,
  getVendorDetailsForBills,
  updateBillRecord,
} from '../_requests'
import Select from 'react-select'
import clsx from 'clsx'
import ProductSearchbar from '../../../../core/shared/components/ProductSearchbar'
import ProductSelection from '../../../../core/shared/components/ProductSelection'
import {useImmer} from 'use-immer'
import {
  BillDetails,
  BillItems,
  OtherCharges,
  PaginationFilter,
  Product,
  ReceivedBillItems,
  Vendor,
  VendorDetailsForBill,
} from '../_models'
import {OrganizationProfileAddress} from '../../../settings/core/_models'
import {getOtherChargeList} from '../../../../core/shared/core/_request'
import CustomSelect from '../../../../core/shared/components/CustomSelect'
import {formatCurrency, formatPercentage} from '../../../../core/utils/helpers'
import {useNavigate, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {Result} from '../../../../core/models/Result'
import {camelize} from '../../../../core/utils/StringHelpers'
import {useQuery} from 'react-query'
import '../assets/CalenderStyle.css'
import {toZonedTime} from 'date-fns-tz'
import {format} from 'date-fns'
import CustomFlatPicker from '../../../../core/shared/components/CustomFlatPicker'
import OtherChargesTable from '../../../../core/shared/components/OtherChargesTable'
import CalendarInput from '../../../../core/shared/components/CalendarInput'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {InputNumber} from 'primereact/inputnumber'

const editBillSchema = Yup.object().shape({
  billNumber: Yup.string().required('Please enter Bill number'),
  dueDate: Yup.date(),
  billDate: Yup.date().required('Please enter Bill Date.'),
  notes: Yup.string(),
})

const BillEditPage = () => {
  const navigate = useNavigate()
  const [isTaxable, setIsTaxable] = useState<boolean>(false)

  const {id} = useParams()

  const {isLoading: isBillDetailsLoading, data: result} = useQuery<BillDetails>(
    ['getBillData'],
    async () => {
      return await getBillDetails(id)
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err)
      },
    }
  )

  const [initialValues, setInitialValues] = useState({
    vendorId: 0,
    billDate: new Date(),
    dueDate: new Date(),
    billNumber: 'undefined',
    subTotal: 0,
    roundOff: 0,
    orderTotal: 0,
    taxAmount: 0,
    billItemList: [] as Product[],
  })

  useEffect(() => {
    if (!isBillDetailsLoading && result) {
      const billItemTaxDtos = result.itemList as ReceivedBillItems[]
      setInitialValues({
        vendorId: result.vendorId,
        billDate: new Date(result.billDate),
        dueDate: new Date(result.dueDate),
        billNumber: result.billNumber,
        subTotal: 0,
        roundOff: 0,
        orderTotal: 0,
        taxAmount: 0,
        billItemList: result.itemList.map((item) => ({
          ...item,
          unitPrice: item.productPrice || 0,
          taxRateId: billItemTaxDtos?.find((taxItem) => item.billItemId === taxItem.billItemId)
            ?.taxId,
          taxRateValue: billItemTaxDtos?.find((taxItem) => item.billItemId === taxItem.billItemId)
            ?.taxPercent,
        })) as Product[],
      })

      formik.setFieldValue('vendorId', result.vendorId)
      formik.setFieldValue('billDate', new Date(result.billDate))
      formik.setFieldValue('dueDate', result.dueDate ? new Date(result.dueDate) : undefined)
      formik.setFieldValue('billNumber', result.billNumber)
    }
  }, [isBillDetailsLoading, result])

  const [vendorData, setVendorData] = useState<VendorDetailsForBill>()
  const [billItems, updateBillItems] = useImmer<BillItems[]>([])
  const [otherCharges, setOtherCharges] = useImmer<OtherCharges[]>([])
  const [errorMessage, updateErrorMessage] = useState<string>('')

  const [selectedAddress, setSelectedAddress] = useState<{
    value: string
    label: string
    id: number
  } | null>(null)
  const [addresses, setAddresses] = useState<{
    data: OrganizationProfileAddress[]
    pagination: any
  }>()

  const handleApiError = (
    result: Result,
    setFieldError: (field: string, message: string) => void
  ) => {
    if (result.statusCode === 400) {
      result.propertyResults.forEach((error) =>
        setFieldError(camelize(error.propertyName), error.errorMessage)
      )
    }
    toast.error('An error occurred while saving the purchase order.')
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: editBillSchema,
    onSubmit: async (formValues, {setFieldError, setSubmitting}) => {
      setSubmitting(true)
      try {
        // Validations
        updateErrorMessage('')
        const validateBillItems = () => {
          const hasProducts = billItems?.filter((item) => !item.isDeleted).length > 0
          const hasInvalidQuantity =
            billItems.filter((item) => item?.quantity !== undefined && item.quantity <= 0).length >
            0
          const hasInvalidUnitPrice =
            billItems.filter((item) => item?.unitPrice !== undefined && item.unitPrice <= 0)
              .length > 0

          if (!hasProducts) {
            window.scrollTo(0, 0)
            updateErrorMessage('At least one product is required')
            return false
          }

          if (hasInvalidQuantity) {
            window.scrollTo(0, 0)
            updateErrorMessage('Quantity cannot be 0')
            return false
          }

          if (hasInvalidUnitPrice) {
            updateErrorMessage('Unit Price cannot be 0')
            window.scrollTo(0, 0)
            return false
          }

          return true
        }

        if (!validateBillItems()) {
          return
        }

        const billDate = toZonedTime(formValues.billDate as Date, 'Asia/Kolkata')
        const dueDate =
          formValues.dueDate && toZonedTime(new Date(formValues.dueDate), 'Asia/Kolkata')
        const billDateString = format(billDate, 'yyyy-MM-dd')
        const dueDateString = dueDate && format(dueDate, 'yyyy-MM-dd')

        const resultPayload = {
          id: id,
          vendorId: initialValues.vendorId,
          billDate: billDateString,
          roundOff: formValues.roundOff,
          orderTotal: formValues.orderTotal,
          orderSubTotal: formValues.subTotal,
          totalOtherCharges: otherCharges.reduce(
            (total, charge) => total + (charge.isDeleted ? 0 : charge.amount || 0),
            0
          ),
          dueDate: dueDate ? dueDateString : undefined,
          billNumber: formValues.billNumber,
          deliveryAddressId: selectedAddress?.id || 0,
          billItemList: billItems.map((item) => ({
            productId: item.productId,
            quantity: item.quantity,
            productPrice: item.unitPrice,
            // subTotal: item.subTotal,
            TaxId: isTaxable ? item.taxRateId : undefined,
            taxRateValue: isTaxable ? item.taxRateValue : undefined,
            isDeleted: item.isDeleted,
            billItemId: item.billItemId || undefined,
          })),
          otherCharges: otherCharges.map((charge) => ({
            id: charge.id || 0,
            label: charge.label,
            amount: charge.amount,
            isDeleted: charge.isDeleted,
          })),
        }

        const result = await updateBillRecord(resultPayload)
        if (result.hasOwnProperty('succeeded') && result?.succeeded) {
          toast.success('Your bill has been updated successfully!')
          navigate('/purchase/bills/list')
        } else {
          handleApiError(result, setFieldError)
        }
      } catch (error) {
        console.error('Error during form submission:', error)
      } finally {
        setSubmitting(false)
      }
    },
  })

  // Vendor handling
  const onVendorChange = async (e: any) => {
    try {
      const vendor = await getVendorDetailsForBills(initialValues?.vendorId)
      setVendorData(vendor as VendorDetailsForBill)
    } catch (error) {
      console.error('Error fetching vendor:', error)
    }
  }

  useEffect(() => {
    const taxable =
      vendorData?.gstTreamentName === 'Registered Business – Composition' ||
      vendorData?.gstTreamentName === 'Registered Business – Regular'
    setIsTaxable(taxable)
  }, [vendorData])

  const calculateTotal = () => {
    let subTotal: number = 0
    let otherChargesTotal: number = 0
    let taxAmount: number = 0

    billItems.forEach((item) => {
      if (item.quantity && item.unitPrice) {
        subTotal += Number(item.quantity) * Number(item.unitPrice)
        taxAmount +=
          (Number(item?.unitPrice) * Number(item?.quantity) * (item?.taxRateValue ?? 0)) / 100 ?? 0
      }
    })

    otherCharges
      .filter((t) => !t.isDeleted)
      .forEach((item) => {
        otherChargesTotal = otherChargesTotal + (item.amount || 0)
      })

    const tempTotal = isTaxable
      ? subTotal + otherChargesTotal + taxAmount
      : subTotal + otherChargesTotal
    const total = Math.round(tempTotal)

    // Update formik values
    formik.setFieldValue('subTotal', subTotal)
    formik.setFieldValue('taxAmount', taxAmount)
    formik.setFieldValue('roundOff', total - tempTotal)
    formik.setFieldValue('orderTotal', total)
  }

  const fetchAddress = async () => {
    try {
      const response = await getOrganizatinProfileAddress()
      const addressList = response as OrganizationProfileAddress[]
      setAddresses({
        data: addressList,
        pagination: {},
      })

      // Set default address if available
      if (addressList && addressList.length > 0) {
        const defaultAddress = addressList[0]
        setSelectedAddress({
          value: defaultAddress.displayAddress ?? '',
          label: defaultAddress.displayAddress ?? '',
          id: defaultAddress.id,
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (formik.values.vendorId) {
      onVendorChange(formik.values.vendorId)
    }

    fetchAddress()
  }, [formik.values.vendorId])

  //change delivery address
  const handleAddressChange = (selected: {value: string; label: string; id: number} | null) => {
    setSelectedAddress(selected)
  }

  const onProductsChange = (product: Product, quantity: number = 1) => {
    updateBillItems((items) => {
      const existingItem = items.find((p) => p.productId === product.id)
      const unitPrice =
        formik.values.billItemList?.find((item) => item.rowNumber === product.rowNumber)
          ?.unitPrice || product.unitPrice
      const taxRateValue =
        formik.values.billItemList?.find((item) => item.rowNumber === product.rowNumber)
          ?.taxRateValue ||
        product.taxRateValue ||
        0
      const taxRateId =
        formik.values.billItemList?.find((item) => item.rowNumber === product.rowNumber)
          ?.taxRateId ||
        product.taxRateId ||
        0

      if (existingItem && existingItem.quantity !== undefined) {
        existingItem.quantity += quantity
        existingItem.total = Number(unitPrice) * existingItem.quantity
        existingItem.taxRateId = taxRateId
        existingItem.taxRateValue = taxRateValue
        formik.setFieldValue(
          `billItemList[${existingItem.rowNumber}].quantity`,
          existingItem.quantity
        )
      } else {
        const newItem = {
          rowNumber: items.length,
          productId: product.id as number,
          productName: product.name,
          quantity: quantity,
          unitPrice: billItems.find((item) => item.rowNumber === product.rowNumber)?.unitPrice || 0,
          total: billItems.find((item) => item.rowNumber === product.rowNumber)?.total || 0,
          taxRateId: taxRateId,
          taxRateValue: taxRateValue,
          isDeleted: false,
        }
        items.push(newItem)
        formik.setFieldValue(`billItemList[${newItem.rowNumber}].quantity`, newItem.quantity)
      }
    })
  }

  const handleDelete = (rowNumber: number) => {
    updateBillItems((draft) => {
      const items = draft.find((t) => t.rowNumber === rowNumber)
      if (items) {
        items.isDeleted = true
      }
    })
  }

  // Populate billItems from result.itemList
  useEffect(() => {
    if (result) {
      updateBillItems(
        result.itemList.map((item, index) => ({
          rowNumber: index,
          billItemId: item?.billItemId,
          productId: item?.productId,
          productName: item?.productName,
          quantity: item?.quantity,
          unitPrice: item?.productPrice,
          total: item?.subTotal,
          taxRateId: item.billItemTaxDtos?.find((taxItem) => item.billItemId === taxItem.billItemId)
            ?.taxId,
          taxRateValue: item.billItemTaxDtos?.find(
            (taxItem) => item.billItemId === taxItem.billItemId
          )?.taxPercent,
          isDeleted: false,
        }))
      )

      let updatedOtherCharges = [] as OtherCharges[]
      result.otherCharges.map((charge, index) => {
        updatedOtherCharges.push({
          rowNumber: index,
          id: charge.id,
          label: charge.label,
          amount: charge.amount,
          isDeleted: false,
        })
      })
      setOtherCharges(updatedOtherCharges)
    }
  }, [result])

  const calculateItemTotals = (unitPrice: number, quantity: number) => {
    let subTotal = parseFloat(((unitPrice || 0) * (quantity || 0)).toFixed(2))

    return {
      subTotal,
    }
  }

  const updateItemTotal = (rowNumber: number) => {
    updateBillItems((items) => {
      const item = items.find((t) => t.rowNumber === rowNumber)
      if (item) {
        const {unitPrice, quantity} = item

        const totals = calculateItemTotals(unitPrice ?? 0, quantity ?? 0)

        Object.assign(item, totals)
      }
    })
  }

  const updateBillItem = (rowNumber: number, updates: Partial<BillItems>) => {
    updateBillItems((items) => {
      const item = items.find((t) => t.rowNumber === rowNumber)
      if (item) {
        Object.assign(item, updates)
      }
    })
  }

  const unitPriceBody = (rowData: BillItems) => {
    return (
      <InputNumber
        inputStyle={{maxWidth: 100}}
        value={rowData.unitPrice}
        onChange={(e) => {
          const unitPrice = e.value as number
          updateBillItem(rowData.rowNumber, {unitPrice})
          updateItemTotal(rowData.rowNumber)
        }}
        mode='currency'
        currency='INR'
        locale='en-IN'
      />
    )
  }
  const quantityBody = (rowData: BillItems) => {
    return (
      <InputNumber
        inputStyle={{maxWidth: 80}}
        value={rowData.quantity}
        onValueChange={(e) => {
          const quantity = e.value as number
          updateBillItem(rowData.rowNumber, {quantity})
          updateItemTotal(rowData.rowNumber)
        }}
      />
    )
  }

  const actionBody = (rowData: BillItems) => {
    return (
      <button
        type='button'
        className='btn btn-sm btn-icon btn-light-danger mt-2'
        onClick={(e: any) => {
          handleDelete(rowData?.rowNumber)
        }}
      >
        <i className='ki-duotone ki-cross fs-1'>
          <span className='path1' />
          <span className='path2' />
        </i>
      </button>
    )
  }

  // New useEffect to execute calculateItemTotals when vendor changes
  useEffect(() => {
    if (billItems.length > 0) {
      billItems.forEach((item) => {
        const totals = calculateItemTotals(item.unitPrice ?? 0, item.quantity ?? 0)
        updateBillItems((draft) => {
          const existingItem = draft.find((i) => i.rowNumber === item.rowNumber)
          if (existingItem) {
            Object.assign(existingItem, totals)
          }
        })
      })
      // After updating individual items, recalculate the total
      calculateTotal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorData, isTaxable, otherCharges, billItems])

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className='form'>
        {errorMessage != '' && <div className='alert alert-danger'>{errorMessage}</div>}
        <div className='d-flex flex-column flex-xl-row gap-5 gap-lg-5 mb-5'>
          <div className='card flex-row-fluid'>
            <div className='card-header'>
              <div className='card-title w-100'>
                <div className='row mt-3 w-100 justify-content-between'>
                  <label className='col-6 col-form-label fw-bold fs-4'>Bill Details</label>
                </div>
              </div>
            </div>
            <div className='card-body'>
              <div className='row align-items-center'>
                <div className='col-xxl-2 col-xl-3 col-lg-3 col-md-4'>
                  <label className='form-label required'>Vendor</label>
                </div>
                <div className='col-xxl-6 col-xl-6 col-lg-9 col-md-8'>
                  {result?.vendorName || 'vendorName'}
                </div>
              </div>
              {result && (
                <>
                  <div className='row mt-5'>
                    <div className='col-xxl-2 col-xl-3 col-lg-3 col-md-4'></div>
                    <div className='col-xxl-10 col-xl-9 col-lg-9 col-md-8'>
                      <div className='row '>
                        {/* Billing Address Column */}
                        <div className='col-xxl-4 col-xl-6 col-lg-6 col-md-6'>
                          <div className='d-flex align-items-center gap-20 mb-1'>
                            <h6 className='mb-1'>Vendor Address</h6>
                          </div>
                          <p className='mb-0'>
                            {vendorData?.displayBillingAddress && (
                              <NewLine>{vendorData?.displayBillingAddress}</NewLine>
                            )}
                          </p>
                        </div>
                        {/* Shipping Address Column */}
                        <div className='col-xxl-4 col-xl-6 col-lg-6 col-md-6'>
                          <div className='d-flex align-items-center gap-20 mb-1'>
                            <h6 className='mb-1'>Delivery Address</h6>
                          </div>
                          <p className='mb-0'>
                            <Select
                              className='form-select-solid'
                              value={selectedAddress}
                              onChange={handleAddressChange}
                              options={(addresses?.data || []).map((address) => ({
                                value: address.displayAddress || '',
                                label: address.displayAddress || '',
                                id: address.id,
                              }))}
                              formatOptionLabel={({value}) => (
                                <div style={{whiteSpace: 'pre-line'}}>{value}</div>
                              )}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className='row mt-5 mb-0 align-items-center'>
                <div className='col-xxl-2 col-xl-3 col-lg-3 col-md-4'>
                  <label className='form-label required'>Bill Number</label>
                </div>
                <div className='col-xxl-3 col-xl-3 col-lg-9 col-md-8'>
                  <Field
                    type='text'
                    className={clsx('form-control')}
                    {...formik.getFieldProps('billNumber')}
                    name={'billNumber'}
                    value={formik.values.billNumber || initialValues?.billNumber}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      formik.setFieldValue('billNumber', e.target.value)
                    }
                  />
                  {formik.touched.billNumber && formik.errors.billNumber && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.billNumber as string}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mt-5 mb-0 align-items-center'>
                <div className='col-xxl-2 col-xl-3 col-lg-3 col-md-4'>
                  <label className='form-label required'>Bill Date</label>
                </div>
                <div className='col-xxl-3 col-xl-3 col-lg-9 col-md-8'>
                  <div className='p-calendar-wraper w-100'>
                    <CalendarInput
                      name='billDate'
                      value={
                        formik.values.billDate &&
                        !isNaN(Date.parse(formik.values.billDate as unknown as string))
                          ? new Date(formik.values.billDate)
                          : null
                      }
                      setFieldValue={formik.setFieldValue}
                      placeholder='Bill Date'
                      className='form-control'
                    />
                  </div>
                </div>
              </div>

              <div className='row align-items-center mt-5'>
                <div className='col-xxl-2 col-xl-3 col-lg-3 col-md-4'>
                  <label className='form-label'>Due Date</label>
                </div>
                <div className='col-xxl-3 col-xl-3 col-lg-9 col-md-8'>
                  <div className='p-calendar-wraper w-100'>
                    <CalendarInput
                      name='dueDate'
                      value={
                        formik.values.dueDate &&
                        !isNaN(Date.parse(formik.values.dueDate as unknown as string))
                          ? new Date(formik.values.dueDate)
                          : null
                      }
                      setFieldValue={formik.setFieldValue}
                      placeholder='Due Date'
                      className='form-control'
                      minDate={new Date(formik.values.billDate)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='card flex-row-fluid'>
          <div className='card-header'>
            <div className='card-title'>
              <h3>Item Details</h3>
            </div>
          </div>
          <div className='card-body'>
            <ProductSearchbar onSelect={onProductsChange} />
          </div>
          <div className='separator'></div>
          <ProductSelection onSelect={onProductsChange} />
          <div className='card-body pt-0'>
            <DataTable
              value={billItems.filter((t) => t.isDeleted == false)}
              tableClassName='table table-bordered table-hover mb-0'
              stripedRows
            >
              <Column field='productName' header='Product'></Column>
              <Column field='quantity' header='Quantity' body={quantityBody}></Column>
              <Column field='unitPrice' header='Unit Price' body={unitPriceBody}></Column>
              {isTaxable && (
                <Column
                  field='taxRateValue'
                  header='Tax'
                  body={(rowData: BillItems) => formatPercentage(rowData.taxRateValue)}
                ></Column>
              )}
              <Column
                field='subTotal'
                header='Total'
                body={(rowData) => formatCurrency(rowData.subTotal)}
              ></Column>
              <Column field='actions' header='Actions' body={actionBody}></Column>
            </DataTable>

            <div className='row mb-7'>
              <div className='col-xl-6 col-lg-12 order-xl-1 col-md-12 mb-3'></div>
              <div className='col-xl-6 col-lg-12 order-xl-2 col-md-12 mt-7'>
                <div className='px-5 py-2 bg-light text-dark border'>
                  <div className='d-flex justify-content-between align-items-center mb-2'>
                    <label className='fs-4 mt-3'>Sub Total</label>
                    <span className='fs-4 mt-3 '>
                      ₹{' '}
                      {formik?.values?.subTotal?.toLocaleString('en-IN', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  </div>
                  <div className='separator mb-2'></div>
                  <OtherChargesTable
                    otherCharges={otherCharges}
                    setOtherCharges={setOtherCharges}
                  />
                  {isTaxable && (
                    <>
                      <div className='d-flex justify-content-between mb-2'>
                        <label className='fs-4 mt-3'>Tax Amount</label>
                        <span className='fs-4 mt-3 '>
                          ₹{' '}
                          {formik.values?.taxAmount !== undefined &&
                          !Number.isNaN(Number(formik.values.taxAmount))
                            ? Number(formik.values.taxAmount).toFixed(2)
                            : '0.00'}
                        </span>
                      </div>
                    </>
                  )}
                  <div className='d-flex justify-content-between mb-2'>
                    <label className='fs-4 mt-3'>Round Off</label>

                    <span className='fs-4 mt-3 '>
                      ₹{' '}
                      {formik.values?.roundOff !== undefined &&
                      !Number.isNaN(Number(formik.values.roundOff))
                        ? Number(formik.values.roundOff).toFixed(2)
                        : '0.00'}
                    </span>
                  </div>
                  <div className='separator mb-2'></div>
                  <div className='d-flex justify-content-between mb-7'>
                    <label className='fs-4 mt-3'>Total</label>
                    <span className='fs-4 mt-3 '>
                      ₹{' '}
                      {formik.values.orderTotal !== undefined &&
                      !Number.isNaN(parseInt(formik.values.orderTotal.toString()))
                        ? formik.values.orderTotal?.toLocaleString('en-IN', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : '0.00'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer text-end'>
            <button
              type='reset'
              className='btn btn-light me-3'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_1'
              disabled={formik.isSubmitting}
            >
              Discard
            </button>
            <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
              <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h2>Confirmation</h2>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>
                  <div className='modal-body'>
                    <div className='w-100'>
                      <div className='fv-row mb-10 d-flex justify-content-center exclamation fs-14'>
                        <i className='las la-exclamation-circle'></i>
                      </div>

                      <div className='fv-row text-center'>
                        <h5>Are you sure you want to discard ?</h5>
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer'>
                    <button
                      type='button'
                      className='btn btn-light'
                      data-bs-dismiss='modal'
                      onClick={() => navigate('/purchase/bills/list')}
                    >
                      Yes
                    </button>
                    <button type='button' className='btn btn-primary' data-bs-dismiss='modal'>
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-color-modal-action='submit'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              <span className='indicator-label'>Submit</span>
              {formik.isSubmitting && (
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </FormikProvider>
  )
}

export default BillEditPage
