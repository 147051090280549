import {useQuery} from 'react-query'
import React  from 'react'
import { QUERIES } from '../../../../../../_metronic/helpers'
import { GSTForm } from './GSTForm'
import { getGSTById } from '../../../core/_requests'

export const GSTPage = () => {
  const {isLoading, data: result, error: error} = useQuery([`${QUERIES.GST_SETTING}`], async () => {
    return await getGSTById()
  })
  
 if (!isLoading && !error && result) {
    return <GSTForm  data={result} isGSTLoading={false} />
  }
  return ( 
    null
  )
}

