import { CollectionListSearchComponent } from "./CollectionListSearchComponent"
import { CollectionListToolbar } from "./CollectionListToolbar"

const CollectionListHeader = () => {

  return (
    <div className='card-header'>
      {/* <CollectionListSearchComponent /> */}
      {/* begin::Card toolbar */}
      <div className="card-title">
        <h3>Collections</h3>
      </div>
      <div className='card-toolbar'>
        <CollectionListToolbar />
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export default CollectionListHeader
