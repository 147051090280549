import clsx from 'clsx';
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem } from '../../models/DropdownItem';
import { MenuComponent } from '../../../../_metronic/assets/ts/components';
import { KTIcon } from '../../../../_metronic/helpers';

type ActionDropdownProps = {
    buttonLabel: string;
    items: DropdownItem[];
};

const ActionDropdown: React.FC<ActionDropdownProps> = ({ buttonLabel, items }) => {
    // const [isOpen, setIsOpen] = useState(false);
    // const toggleRef = useRef<HTMLDivElement>(null);

    // const toggleDropdown = () => setIsOpen(!isOpen);

    // useEffect(() => {
    //     const handleClickOutside = (event: MouseEvent) => {
    //         if (toggleRef.current && !toggleRef.current.contains(event.target as Node)) {
    //             setIsOpen(false);
    //         }
    //     };

    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => document.removeEventListener('mousedown', handleClickOutside);
    // }, []);

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    const handleItemClick = (item: DropdownItem) => (event: React.MouseEvent) => {
        if (item.onClick) {
            event.preventDefault(); // Prevent the default action if onClick is provided
            item.onClick();
            // setIsOpen(false); // Optionally close the dropdown after item click
        }
    };

    return (
        <>
            <a
                className='btn btn-light btn-active-light-primary btn-sm border no-wrap'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
            >
                {buttonLabel}
                <KTIcon iconName='down' className='fs-5 m-0' />
            </a>
            <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4'
                data-kt-menu='true'
            >
                {items.map(item => (
                    <div className='menu-item px-3' key={item.id}>
                        <Link
                            key={item.id}
                            className="menu-link px-3"
                            to={item.href}
                            onClick={handleItemClick(item)}
                        >
                            {item.label}
                        </Link>
                    </div>
                ))}

            </div>

        </>

    );
};

export default ActionDropdown

// <div
//     className={clsx(
//         'btn-group',
//         { 'open': isOpen }
//     )}
//     ref={toggleRef}>
//     <button onClick={toggleDropdown} className="btn btn-secondary" type='button'>
//         <div className="web-action">{buttonLabel}  <span className="caret"></span></div>
//         <div className="mob-action"><i className="fa fa-navicon"></i></div>
//     </button>
//     {isOpen && (
//         <ul className="dropdown-menu" role="menu">
//             {items.map(item => (
//                 <li key={item.id}>
//                     <Link
//                         key={item.id}
//                         className="dropdown-item"
//                         to={item.href}
//                         onClick={handleItemClick(item)}
//                     >
//                         {item.label}
//                     </Link>
//                 </li>
//             ))}
//         </ul>
//     )}
// </div>
