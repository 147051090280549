import {type} from 'os'
import {ID, Response} from '../../../../../_metronic/helpers/crud-helper/models'
export type LowProductStock = {
  id?: ID
  productId?: number
  productName?: string
  availableQuantity?: number
  lowStockAlert?: number
  lastUpdatedOn?: Date| string
  colorId?: number
  colorName?: string
  polishingTypeId?: number
  polishingTypeName?: string
  unitId?: number
  unitName?: string
}
export type LowProductStockQueryResponse = Response<Array<LowProductStock>>
export const initialLowProductStock: LowProductStock = {
  id: 0,
  productId: 0,
  productName: '',
  availableQuantity: 0,
  lowStockAlert: 0,
  lastUpdatedOn: new Date(),
  colorId: 0,
  colorName: '',
  polishingTypeId: 0,
  polishingTypeName: '',
  unitId: 0,
  unitName: '',
}


export type LowProductStockFilters ={
  productName?:string
  colorId?:number
  polishingTypeId?:number
  lastUpdatedOn?:Date | string
}

export const initialLowProductStockFilters:LowProductStockFilters = {
  productName:'',
  colorId:undefined,
  polishingTypeId:undefined
}