import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { TestimonialListWrapper } from './TestimonialList'
import TestimonialAddPage from './pages/TestimonialAddPage'
import { TestimonialWrapper } from './pages/TestimonialWrapper'




const Breadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const TestimonialPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Testimonial</PageTitle>
              <TestimonialListWrapper />
            </>
          }
        />

        <Route
          path='add'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Add Testimonial</PageTitle>
              <TestimonialAddPage />
            </>
          }
        />

        <Route
          path='edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Edit Testimonial</PageTitle>
              <TestimonialWrapper />
            </>
          }
        /> 

      </Route>
      <Route index element={<Navigate to='/cms/testimonial/list' />} />
    </Routes>
  )
}

export default TestimonialPage
