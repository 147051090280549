import { NewArrivalListSearchComponent } from "./NewArrivalListSearchComponent"
import { NewArrivalListToolbar } from "./NewArrivalListToolbar"


const NewArrivalListHeader = () => {

  return (
    <div className='card-header'>
      {/* <NewArrivalListSearchComponent /> */}
      {/* begin::Card toolbar */}
      <div className="card-title">
        <h3>New Arrivals</h3>
      </div>
      <div className='card-toolbar'>
        <NewArrivalListToolbar />
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export default NewArrivalListHeader
