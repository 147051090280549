import axios, { AxiosResponse } from 'axios'
import { ID, PaginationFilter } from '../../../../../_metronic/helpers/crud-helper/models'
import { Account, AccountQueryResponse, AccountType, AccountTypeQueryResponse } from './_models'
import { Result } from '../../../../core/models/Result'

const API_URL = process.env.REACT_APP_STORE_API_URL
const ACCOUNT_BY_ID_URL = `${API_URL}/account`
const ACCOUNT_LIST_URL = `${API_URL}/account/search`
const ACCOUNT_ADD_URL = `${API_URL}/account`
const ACCOUNTTYPE_BY_ID_URL = `${API_URL}/accounttype`
const ACCOUNTTYPE_LIST_URL = `${API_URL}/accounttype/search`
const getAccountList = async (filter: PaginationFilter): Promise<AccountQueryResponse> => {
  return await axios
    .post(`${ACCOUNT_LIST_URL}`, filter)
    .then((d: AxiosResponse<AccountQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}
const getAccountById = async (id: ID): Promise<Result | Account> => {
  return await axios
    .get(`${ACCOUNT_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<Account>) => response.data)
    .then((response: Account) => response)
    .catch((err: Result) => {
      return err
    })
}

const createAccount = async (account: Account): Promise<Result> => {
  return await axios
    .post(ACCOUNT_ADD_URL, {
      name: account.name,
      accountTypeId: account.accountTypeId,
      description: account.description,
      accountCode: account.accountCode,
      isSubAccount: account.isSubAccount,
      parentAccountId: account.parentAccountId,
      accountNumber:account.accountNumber,
      bankName:account.bankName,
      ifsc:account.ifsc
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const updateAccount = async (account: Account): Promise<Result> => {
  return await axios
    .put(`${ACCOUNT_BY_ID_URL}/${account.id}`, {
      id: account.id,
      accountTypeId: account.accountTypeId,
      name: account.name,
      description: account.description,
      accountCode: account.accountCode,
      isSubAccount: account.isSubAccount,
      parentAccountId: account.parentAccountId,
      accountNumber:account.accountNumber,
      bankName:account.bankName,
      ifsc:account.ifsc

    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}

const getAccountTypeList = async (filter: PaginationFilter): Promise<AccountTypeQueryResponse> => {
  return await axios
    .post(`${ACCOUNTTYPE_LIST_URL}`, filter)
    .then((d: AxiosResponse<AccountTypeQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const getAccountTypeById = async (id: ID): Promise<Result | AccountType> => {
  return await axios
    .get(`${ACCOUNTTYPE_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<AccountType>) => response.data)
    .then((response: AccountType) => response)
    .catch((err: Result) => {
      return err
    })
}
export {
  getAccountList, getAccountById, createAccount, updateAccount,getAccountTypeList,getAccountTypeById
}
