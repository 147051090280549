import {useState} from 'react'
import {KTCard, KTCardBody, KTIcon, PaginationFilter} from '../../../../../_metronic/helpers'

import {getExpenseList} from '../core/_requests'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {
  MustHavePermission,
  SWSection,
  SWResource,
  SWAction,
} from '../../../../core/shared/core/permissionUtils'
import {useQuery} from 'react-query'
import {useNavigate} from 'react-router-dom'
import {formatCurrency, formatDate} from '../../../../core/utils/helpers'
import {useImmer} from 'use-immer'
import {InputText} from 'primereact/inputtext'

const ExpenseList = () => {
  const [filterQuery, setFilterQuery] = useState({})
  const [globalFilterValue, setGlobalFilterValue] = useState<string>('')
  const [paginationFilters, setPaginationFilters] = useImmer({
    pageNumber: 1,
    pageSize: 10,
    orderBy: ['expenseDate desc'] as string[],
    order: 'desc' as 'asc' | 'desc',
    first: 0,
    sortField: 'expenseDate',
  })

  const navigate = useNavigate()

  const handleFilterQueryChange = (newFilterQuery: any) => {
    setFilterQuery(newFilterQuery)
  }
  const {data: expenseResponse, isLoading} = useQuery(
    ['expense', filterQuery, paginationFilters],
    () => {
      return getExpenseList({
        ...filterQuery,
        ...paginationFilters,
        order: paginationFilters.order as 'asc' | 'desc',
      })
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const onSort = (event: any) => {
    setPaginationFilters((draft) => {
      draft.pageNumber = event.page === undefined ? 1 : (event.page as number) + 1
      draft.pageSize = event.rows
      draft.first = event.first
      draft.sortField = event.sortField
      event.sortField && (draft.order = event.sortOrder === 1 ? 'asc' : 'desc')
      event.sortField &&
        (draft.orderBy = [`${event.sortField} ${event.sortOrder === 1 ? 'asc' : 'desc'}`])
    })
  }

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className='actions'>
        <a
          className='btn btn-light btn-active-light-primary btn-sm'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
        >
          Actions
          <KTIcon iconName='down' className='fs-5 m-0' />
        </a>
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
          data-kt-menu='true'
        >
          {/* {MustHavePermission(SWSection.Store, SWResource.Vendors, SWAction.Update) ? */}

          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              onClick={() => navigate(`/purchase/expense/edit/${rowData.id}`)}
            >
              Edit Expense
            </a>
          </div>
          {/* : null} */}
          {/* {MustHavePermission(SWSection.Store, SWResource.Vendors, SWAction.View) ? */}

          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              onClick={() => navigate(`/purchase/expense/details/${rowData.id}`)}
            >
              View Detail
            </a>
          </div>
          {/* : null} */}
        </div>
      </div>
    )
  }

  const renderHeader = () => (
    <div className='flex justify-content-end'>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          placeholder='Keyword Search'
          value={globalFilterValue}
          onChange={(e) => setGlobalFilterValue(e.target.value)}
        />
      </span>
    </div>
  )

  const header = renderHeader()

  const filteredData = expenseResponse?.data?.filter((item) =>
    Object.values(item).some((value: any) => {
      return (
        typeof value === 'string' && value.toLowerCase().includes(globalFilterValue.toLowerCase())
      )
    })
  )
  const leftContent = (
    <span className='mx-3' style={{color: 'var(--text-color)', userSelect: 'none'}}>
      {`Showing ${(expenseResponse?.pagination?.totalCount as number) > 0 ? (paginationFilters.first as number) + 1 : 0} to 
            ${Math.min(
              (expenseResponse?.pagination?.currentPage as number) *
                (expenseResponse?.pagination?.pageSize as number),
              expenseResponse?.pagination?.totalCount as number
            )} 
            out of ${expenseResponse?.pagination?.totalCount} Records`}
    </span>
  )

  return (
    <>
      {/* <ExpenseAdvancedSearch onSearch={handleFilterQueryChange} />  */}
      <KTCard>
        <div className='card-header align-items-center justify-content-between'>
          <div className='card-title'>
            <h3>Expense</h3>
          </div>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => navigate('/purchase/expense/add')}
          >
            <KTIcon iconName='plus' className='fs-2' />
            Add
          </button>
        </div>
        <KTCardBody>
          <>
            <div className='d-flex justify-content-end mt-4'>
              <div className='m-0'></div>
            </div>
            <div>
              <DataTable
                value={filteredData}
                stripedRows
                header={header}
                showGridlines
                filterDisplay='menu'
                tableClassName='table table-bordered table-hover mb-0'
                tableStyle={{minWidth: '50rem'}}
                loading={isLoading}
                sortMode='single'
                currentPageReportTemplate='Showing {first} to {last} of {totalRecords} records'
                paginatorClassName='dataTables_paginate paging_bootstrap_full_number mt-0'
                paginator
                paginatorLeft={leftContent}
                rows={paginationFilters.pageSize}
                totalRecords={expenseResponse?.pagination?.totalCount}
                emptyMessage='No Bills found.'
                rowsPerPageOptions={[10, 25, 50, 100]}
                first={paginationFilters?.first}
                onSort={onSort}
                onPage={onSort}
                sortField={paginationFilters.orderBy[0]?.split(' ')[0]}
                sortOrder={paginationFilters.order === 'asc' ? 1 : -1}
                lazy
              >
                <Column
                  headerClassName='fw-semibold fs-6 text-gray-800'
                  field='expenseCategory'
                  header={'Expense Category'}
                  sortable
                  style={{minWidth: '6rem'}}
                  sortField='expenseCategory.name'
                />
                <Column
                  headerClassName='fw-semibold fs-6 text-gray-800'
                  field='expenseDate'
                  header={'Expense Date'}
                  sortable
                  style={{minWidth: '6rem'}}
                  body={(rowData) => formatDate(rowData.expenseDate, 'dd MMM yyyy')}
                  sortField='expenseDate'
                />
                <Column
                  headerClassName='fw-semibold fs-6 text-gray-800'
                  field='expenseAmount'
                  header={'Expense Amount'}
                  sortable
                  style={{minWidth: '6rem'}}
                  body={(rowData) => formatCurrency(rowData.expenseAmount)}
                  sortField='expenseAmount'
                />
                {MustHavePermission(SWSection.Store, SWResource.Expenses, SWAction.Update) ? (
                  <Column
                    // field=''
                    headerClassName='fw-semibold fs-6 text-gray-800'
                    header={'Action'}
                    body={actionBodyTemplate}
                  />
                ) : null}
              </DataTable>
            </div>
          </>
        </KTCardBody>
      </KTCard>
    </>
  )
}

export default ExpenseList
