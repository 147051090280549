import React, { useEffect, useState } from 'react'
import { KTCard, KTSVG } from '../../../../../_metronic/helpers'
import { Field, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'

import { useQuery } from 'react-query'
import CustomSelect from '../../../../core/shared/components/CustomSelect'
import { SelectOptionProps } from '../../../../core/models/SelectOptionProps'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Result } from '../../../../core/models/Result'
import { camelize } from '../../../../core/utils/StringHelpers'
import { format } from 'date-fns'
import { formatDate } from '../../../../core/utils/helpers'
import { getPaymentModeList } from '../../../settings/paymode/core/_requests'
import { getBankList } from '../../../purchases/paymentMade/_request'
import { getSalesOrderById } from '../../salesOrder/core/_requests'
import { CreatePaymentRequestModel } from '../core/_model'
import { PaymentMode } from '../../../settings/paymode/core/_models'
import { createPayment } from '../core/_request'
import { getCustomerList } from '../../customer/customer-list/core/_requests'
import CustomAsyncSelect from '../../../../core/shared/components/CustomAsyncSelect'
import { Customer } from '../../customer/customer-list/core/_models'
import CalendarInput from '../../../../core/shared/components/CalendarInput'

const initialValues = {
    saleOrderId: undefined,
    amountReceived: undefined,
    customerId: undefined,
    paymentDate: format(new Date(), 'yyyy-MM-dd'),
    paymentModeId: undefined,
    referenceNumber: '',
    isAdvance: true,
    notes: '',
    chequeBankName: '',
    chequeNumber: '',
    chequeDate: format(new Date(), 'yyyy-MM-dd'),
    bankId: undefined,
}

const addPaymentSchema = Yup.object().shape({
    customerId: Yup.number().required('Please Select Customer'),
    paymentDate: Yup.date().required('Please Enter Payment Date'),
    paymentModeId: Yup.number().required('Please Select Payment Mode'),
    referenceNumber: Yup.string(), //.required('Please Enter Reference Number')

    // Conditional validation for cheque-specific fields when payment mode is cheque
    chequeBankName: Yup.string().when('paymentModeId', ([paymentModeId], schema) => {
        return paymentModeId === 2
            ? schema.required('Please Enter Cheque Bank Name')
            : schema.notRequired()
    }),
    chequeNumber: Yup.string().when('paymentModeId', ([paymentModeId], schema) => {
        return paymentModeId === 2
            ? schema
                .required('Please Enter Cheque Number')
                .min(6, 'Cheque Number must be 6 digits')
                .max(6, 'Cheque Number must be 6 digits')
            : schema.notRequired()
    }),
    chequeDate: Yup.date().when('paymentModeId', ([paymentModeId], schema) => {
        return paymentModeId === 2 ? schema.required('Please Enter Cheque Date') : schema.notRequired()
    }),

    // Conditional validation for bank when payment mode is not cash
    bankId: Yup.number().when('paymentModeId', ([paymentModeId], schema) => {
        return paymentModeId !== 1 ? schema.required('Please Select Bank') : schema.notRequired()
    }),

    amountReceived: Yup.number()
        .required('Please Enter Amount')
        .positive('Amount must be a positive number')
        .min(1, 'Amount must be greater than zero'),

    notes: Yup.string().optional(),
})

const AddPayment = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [customers, setVenders] = useState<SelectOptionProps[]>([])
    const [banks, setBanks] = useState<SelectOptionProps[]>([])
    const { data: paymentModeList } = useQuery({
        queryKey: ['paymentModeList'],
        queryFn: () => getPaymentModeList({ pageSize: 50, pageNumber: 1 }),
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        cacheTime: 0,
    })

    const { data: bankList, isLoading: isBankListLoading } = useQuery({
        queryKey: ['bankList'],
        queryFn: () => getBankList({ pageSize: 500, pageNumber: 1 }),
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        cacheTime: 0,
    })

    const { data: saleOrderDetails, isLoading: isSaleOrderDetailsLoading } = useQuery({
        queryKey: ['saleOrderDetails'],
        queryFn: () => getSalesOrderById(Number(id)),
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        cacheTime: 0,
        enabled: !!id,
    })

    useEffect(() => {
        if (id) {
            formik.setFieldValue('amountReceived', saleOrderDetails?.outStandingAmount)
            formik.setFieldValue('customerId', saleOrderDetails?.customerId)
        }
    }, [id, isSaleOrderDetailsLoading])

    useEffect(() => {
        if (bankList?.data) {
            let bankArray: any[] = []
            bankList?.data.map((item: any) => {
                return bankArray.push({ value: item.id, label: item.name })
            })
            setBanks(bankArray)
        }
    }, [isBankListLoading])

    const handleApiError = (
        result: Result,
        setFieldError: (field: string, message: string) => void
    ) => {
        if (result.statusCode === 400) {
            result.propertyResults.forEach((error) =>
                setFieldError(camelize(error.propertyName), error.errorMessage)
            )
        }
        toast.error(result?.messages[0])
    }

    const formik = useFormik({
        initialValues,
        validationSchema: addPaymentSchema,
        onSubmit: async (formValues, { setFieldError, setSubmitting }) => {
            setSubmitting(true)
            // Add your submission logic here

            const paymentPayload: CreatePaymentRequestModel = {
                saleOrderId: id ? Number(id) : undefined,
                amountReceived: formValues.amountReceived as unknown as number,
                customerId: formValues.customerId as unknown as number,
                paymentDate: formValues.paymentDate,
                paymentModeId: formValues.paymentModeId as unknown as number,
                referenceNumber: formValues.referenceNumber,
                isAdvance: id ? false : true,
                notes: formValues.notes,
                chequeBankName: formValues.paymentModeId === 2 ? formValues.chequeBankName : undefined,
                chequeNumber: formValues.paymentModeId === 2 ? formValues.chequeNumber : undefined,
                chequeDate: formValues.paymentModeId === 2 ? formValues.chequeDate : undefined,
                bankId: formValues.paymentModeId !== 1 ? formValues.bankId : undefined,
            }

            const result = await createPayment(paymentPayload)
            if (result.hasOwnProperty('succeeded') && result?.succeeded) {
                toast.success('Payment made created successfully!')
                navigate('/sales/payment/list')
            } else {
                handleApiError(result, setFieldError)
            }
        },
    })
    return (
        <FormikProvider value={formik}>
            <pre>{JSON.stringify(formik.errors)}</pre>
            <pre>{JSON.stringify(formik.values)}</pre>
            {id && (
                <div className='d-flex flex-column flex-xl-row gap-5 gap-lg-5 mb-5'>
                    <div className='card flex-row-fluid'>
                        <div className='card-header'>
                            <div className='card-title'>
                                <h3>Bill Details</h3>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='table-responsive'>
                                <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
                                    <tbody className='fw-semibold text-gray-600'>
                                        <tr>
                                            <td className='text-muted'>
                                                <div className='d-flex align-items-center'>
                                                    <i className='ki-duotone ki-profile-circle fs-2 me-2'>
                                                        <span className='path1' />
                                                        <span className='path2' />
                                                        <span className='path3' />
                                                    </i>{' '}
                                                    Sale Order Number
                                                </div>
                                            </td>
                                            <td className='fw-bold text-end'>
                                                <div className='d-flex align-items-center justify-content-end'>
                                                    <label className='text-gray-600 text-hover-primary'>
                                                        {saleOrderDetails?.orderNumber || 'SO Number not available'}
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-muted'>
                                                <div className='d-flex align-items-center'>
                                                    <i className='ki-duotone ki-profile-circle fs-2 me-2'>
                                                        <span className='path1' />
                                                        <span className='path2' />
                                                        <span className='path3' />
                                                    </i>{' '}
                                                    Sale Order Date
                                                </div>
                                            </td>
                                            <td className='fw-bold text-end'>
                                                <div className='d-flex align-items-center justify-content-end'>
                                                    <label className='text-gray-600 text-hover-primary'>
                                                        {formatDate(saleOrderDetails?.orderDate, 'dd-MM-yyyy') ||
                                                            'Order Date not available'}
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-muted'>
                                                <div className='d-flex align-items-center'>
                                                    <i className='ki-duotone ki-profile-circle fs-2 me-2'>
                                                        <span className='path1' />
                                                        <span className='path2' />
                                                        <span className='path3' />
                                                    </i>{' '}
                                                    Payment Status{' '}
                                                </div>
                                            </td>
                                            <td className='fw-bold text-end'>
                                                <div className='d-flex align-items-center justify-content-end'>
                                                    <label className='text-gray-600 text-hover-primary'>
                                                        {saleOrderDetails?.paymentStatusName || 'Payment Status not available'}
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='card flex-row-fluid'>
                        <div className='card-header'>
                            <div className='card-title'>
                                <h3>Customer Details</h3>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='table-responsive'>
                                <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
                                    <tbody className='fw-semibold text-gray-600'>
                                        <tr>
                                            <td className='text-muted'>
                                                <div className='d-flex align-items-center'>
                                                    <i className='ki-duotone ki-profile-circle fs-2 me-2'>
                                                        <span className='path1' />
                                                        <span className='path2' />
                                                        <span className='path3' />
                                                    </i>{' '}
                                                    Customer Name
                                                </div>
                                            </td>
                                            <td className='fw-bold text-end'>
                                                <div className='d-flex align-items-center justify-content-end'>
                                                    <label className='text-gray-600 text-hover-primary'>
                                                        {saleOrderDetails?.customerName || 'Customer Name not available'}
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-muted'>
                                                <div className='d-flex align-items-center'>
                                                    <i className='ki-duotone ki-profile-circle fs-2 me-2'>
                                                        <span className='path1' />
                                                        <span className='path2' />
                                                        <span className='path3' />
                                                    </i>{' '}
                                                    Customer Email
                                                </div>
                                            </td>
                                            <td className='fw-bold text-end'>
                                                <div className='d-flex align-items-center justify-content-end'>
                                                    <label className='text-gray-600 text-hover-primary'>
                                                        {saleOrderDetails?.email || 'Customer Email not available'}
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='text-muted'>
                                                <div className='d-flex align-items-center'>
                                                    <i className='ki-duotone ki-profile-circle fs-2 me-2'>
                                                        <span className='path1' />
                                                        <span className='path2' />
                                                        <span className='path3' />
                                                    </i>{' '}
                                                    Customer Phone
                                                </div>
                                            </td>
                                            <td className='fw-bold text-end'>
                                                <div className='d-flex align-items-center justify-content-end'>
                                                    <label className='text-gray-600 text-hover-primary'>
                                                        {saleOrderDetails?.mobileNumber || 'Customer Phone not available'}
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <KTCard>
                <div className='d-flex flex-wrap flex-stack gap-5 gap-lg-10'>
                    <div className='card-header w-100'>
                        <div className='card-title'>
                            <h3>Add Payment Details</h3>
                        </div>
                    </div>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className='card-body d-flex flex-column gap-5'>
                        <div className='row d-flex flex-row align-items-center'>
                            <div className='col-xxl-2 col-xl-3 col-lg-3 col-md-4'>
                                <label className='form-label required'>Payment Date</label>
                            </div>
                            <div className='col-xxl-6 col-xl-6 col-lg-9 col-md-8'>
                                <div className='p-calendar-wraper w-100'>
                                    <CalendarInput
                                        name="paymentDate" // Field name for "Order Date"
                                        value={
                                            formik.values.paymentDate && !isNaN(Date.parse(formik.values.paymentDate))
                                                ? new Date(formik.values.paymentDate)
                                                : null
                                        }
                                        setFieldValue={formik.setFieldValue}
                                        placeholder="Payment Date"
                                        className="form-control w-100"
                                    />
                                </div>
                            </div>
                        </div>
                        {!id && (
                            <div className='row align-items-center'>
                                <div className='col-xxl-2 col-xl-3 col-lg-3 col-md-4'>
                                    <label className='form-label required'>Customer</label>
                                </div>
                                <div className='col-xxl-6 col-xl-6 col-lg-9 col-md-8'>
                                    <Field
                                        className='form-select-solid-solid'
                                        component={CustomAsyncSelect<Customer>}
                                        {...formik.getFieldProps(`customerId`)}
                                        name={`customerId`}
                                        loadOptionFunc={getCustomerList}
                                        searchByField="printName"
                                        labelField="printName"
                                        disabledisActiveFilter={true}
                                        disabled={!!id}
                                        onDropDownChange={(newValue: any) => {
                                            // onCustomerChange(newValue)
                                            formik.setFieldValue('customerId', newValue?.value)

                                        }}
                                    ></Field>
                                    {/* <Field
                    className='form-select-solid'
                    options={customers || []}
                    component={CustomSelect}
                    {...formik.getFieldProps('customerId')}
                    value={formik.values.customerId}
                    name={'customerId'}
                    disabled={!!id}
                    placeholder={'Select Customer'}
                  ></Field> */}
                                    {formik.touched.customerId && formik.errors.customerId && (
                                        <div className='text-danger'>{formik.errors.customerId}</div>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className='row align-items-center'>
                            <div className='col-xxl-2 col-xl-3 col-lg-3 col-md-4'>
                                <label className='form-label'>Reference Number</label>
                            </div>
                            <div className='col-xxl-6 col-xl-6 col-lg-9 col-md-8'>
                                <Field
                                    className='form-control'
                                    type='text'
                                    {...formik.getFieldProps('referenceNumber')}
                                    value={formik.values.referenceNumber}
                                    name={'referenceNumber'}
                                    placeholder={'Enter Reference Number'}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        formik.setFieldValue('referenceNumber', e.target.value)
                                    }}
                                ></Field>
                                {formik.touched.referenceNumber && formik.errors.referenceNumber && (
                                    <div className='text-danger'>{formik.errors.referenceNumber}</div>
                                )}
                            </div>
                        </div>

                        <div className='row d-flex flex-row align-items-center'>
                            <div className='col-xxl-2 col-xl-3 col-lg-3 col-md-4'>
                                <label className='form-label required'>Payment Mode</label>
                            </div>
                            <div className='col-xxl-6 col-xl-6 col-lg-9 col-md-8'>
                                <div className='d-flex flex-row flex-wrap gap-2'>
                                    {paymentModeList?.data?.map((option: PaymentMode) => (
                                        <div key={option.id} className='form-check form-check-inline'>
                                            <input
                                                type='radio'
                                                className='form-check-input'
                                                name='paymentModeId'
                                                id={`paymentMode-${option.id}`}
                                                value={formik.values.paymentModeId}
                                                checked={formik.values.paymentModeId === option.id}
                                                onChange={() => formik.setFieldValue('paymentModeId', option.id)}
                                            />
                                            <label className='form-check-label' htmlFor={`paymentMode-${option.id}`}>
                                                {option.name}
                                            </label>
                                        </div>
                                    ))}
                                    {formik.touched.paymentModeId && formik.errors.paymentModeId && (
                                        <div className='text-danger'>{formik.errors.paymentModeId}</div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {formik.values.paymentModeId == 2 && (
                            <>
                                <div className='row align-items-center'>
                                    <div className='col-xxl-2 col-xl-3 col-lg-3 col-md-4'>
                                        <label className='form-label required'>Cheque Bank Name</label>
                                    </div>
                                    <div className='col-xxl-6 col-xl-6 col-lg-9 col-md-8'>
                                        <Field
                                            className='form-control'
                                            type='text'
                                            {...formik.getFieldProps('chequeBankName')}
                                            value={formik.values.chequeBankName}
                                            name={'chequeBankName'}
                                            placeholder={'Enter Cheque Bank Name'}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                const formattedValue = e.target.value.replace(/[^a-zA-Z]/g, ' ')
                                                formik.setFieldValue('chequeBankName', formattedValue)
                                            }}
                                        ></Field>
                                        {formik.touched.chequeBankName && formik.errors.chequeBankName && (
                                            <div className='text-danger'>{formik.errors.chequeBankName}</div>
                                        )}
                                    </div>
                                </div>

                                <div className='row align-items-center'>
                                    <div className='col-xxl-2 col-xl-3 col-lg-3 col-md-4'>
                                        <label className='form-label required'>Cheque Number</label>
                                    </div>
                                    <div className='col-xxl-6 col-xl-6 col-lg-9 col-md-8'>
                                        <Field
                                            className='form-control'
                                            type='text'
                                            {...formik.getFieldProps('chequeNumber')}
                                            value={formik.values.chequeNumber}
                                            name={'chequeNumber'}
                                            placeholder={'Enter Cheque Number'}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                const numericValue = e.target.value.replace(/\D/g, '')
                                                formik.setFieldValue('chequeNumber', numericValue)
                                            }}
                                        />
                                        {formik.touched.chequeNumber && formik.errors.chequeNumber && (
                                            <div className='text-danger'>{formik.errors.chequeNumber}</div>
                                        )}
                                    </div>
                                </div>


                                <div className='row align-items-center'>
                                    <div className='col-xxl-2 col-xl-3 col-lg-3 col-md-4'>
                                        <label className='form-label required'>Cheque Date</label>
                                    </div>
                                    <div className='col-xxl-6 col-xl-6 col-lg-9 col-md-8'>
                                        <div className='w-100 p-calendar-wraper'>
                                        <CalendarInput
                                            name="chequeDate" // Field name for "Order Date"
                                            value={
                                                formik.values.chequeDate && !isNaN(Date.parse(formik.values.chequeDate))
                                                    ? new Date(formik.values.chequeDate)
                                                    : null
                                            }
                                            setFieldValue={formik.setFieldValue}
                                            placeholder="Cheque Date"
                                            className="form-control w-100"
                                        />
                                        </div>
                                    </div>
                                </div>

                            </>
                        )}
                        {formik.values.paymentModeId !== 1 && (


                            <div className='row align-items-center'>
                                <div className='col-xxl-2 col-xl-3 col-lg-3 col-md-4'>
                                    <label className='form-label required'>Deposited In</label>
                                </div>
                                <div className='col-xxl-6 col-xl-6 col-lg-9 col-md-8'>
                                    <Field
                                        className='form-select-solid'
                                        options={banks || []}
                                        component={CustomSelect}
                                        {...formik.getFieldProps('bankId')}
                                        value={formik.values.bankId}
                                        name={'bankId'}
                                        placeholder={'Select Bank'}
                                    ></Field>
                                    {formik.touched.bankId && formik.errors.bankId && (
                                        <div className='text-danger'>{formik.errors.bankId}</div>
                                    )}
                                </div>
                            </div>


                        )}
                        <div className='row d-flex flex-row align-items-center'>
                            <div className='col-xxl-2 col-xl-3 col-lg-3 col-md-4'>
                                <label className='form-label required'>Amount Received</label>
                            </div>
                            <div className='col-xxl-6 col-xl-6 col-lg-9 col-md-8'>
                                <Field
                                    className='form-control'
                                    type='text'
                                    {...formik.getFieldProps('amountReceived')}
                                    value={formik.values.amountReceived}
                                    name={'amountReceived'}
                                    // disabled={!!id}
                                    placeholder={'Enter Amount Paid'}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const numericValue = e.target.value.replace(/\D/g, '')
                                        formik.setFieldValue('amountReceived', numericValue)
                                    }}
                                />
                                {formik.touched.amountReceived && formik.errors.amountReceived && (
                                    <div className='text-danger'>{formik.errors.amountReceived}</div>
                                )}
                            </div>
                        </div>

                        <div className='row d-flex flex-row align-items-center'>
                            <div className='col-xxl-2 col-xl-3 col-lg-3 col-md-4'>
                                <label className='form-label'>Notes</label>
                            </div>
                            <div className='col-xxl-6 col-xl-6 col-lg-9 col-md-8'>
                                <textarea
                                    rows={3}
                                    className='form-control'
                                    placeholder='Notes'
                                    {...formik.getFieldProps('notes')}
                                    name={'notes'}
                                ></textarea>
                            </div>
                        </div>

                        {/* Add other form fields similarly */}
                    </div>
                    <div className='card-footer text-end'>
                        <button
                            type='reset'
                            className='btn btn-light me-3'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_1'
                            disabled={formik.isSubmitting}
                        >
                            Discard
                        </button>
                        <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                            <div className='modal-dialog modal-dialog-centered'>
                                <div className='modal-content'>
                                    <div className='modal-header'>
                                        <h2>Confirmation</h2>
                                        <div
                                            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                            data-bs-dismiss='modal'
                                            aria-label='Close'
                                        >
                                            <KTSVG
                                                path='/media/icons/duotune/arrows/arr061.svg'
                                                className='svg-icon svg-icon-2x'
                                            />
                                        </div>
                                    </div>
                                    <div className='modal-body'>
                                        <div className='w-100'></div>
                                        <div className='fv-row text-center'>
                                            <div className='fv-row text-center'>
                                                <h5>Are you sure you want to discard ?</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='modal-footer'>
                                        <button
                                            type='button'
                                            className='btn btn-light'
                                            data-bs-dismiss='modal'
                                            onClick={() => navigate('/purchase/payment-made/list')}
                                        >
                                            Yes
                                        </button>
                                        <button type='button' className='btn btn-primary' data-bs-dismiss='modal'>
                                            No
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            type='submit'
                            className='btn btn-primary'
                            data-kt-color-modal-action='submit'
                            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                        >
                            <span className='indicator-label'>Submit</span>
                            {formik.isSubmitting && (
                                <span className='indicator-progress'>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </KTCard>
        </FormikProvider>
    )
}

export default AddPayment
