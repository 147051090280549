import {useNavigate} from 'react-router-dom'
import {KTIcon} from '../../../../../../_metronic/helpers'
import {
  MustHavePermission,
  SWSection,
  SWResource,
  SWAction,
} from '../../../../../core/shared/core/permissionUtils'
const AgentListToolbar = () => {
  const navigate = useNavigate()
  const openAddUserModal = () => {
    navigate(`/sales/agent/add`)
  }

  return (
    <>
      {/* <div className="m-0">
        <AgentListFilter />
      </div> */}
      {MustHavePermission(SWSection.Store, SWResource.Agents, SWAction.Create) ? (
        <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
          <KTIcon iconName='plus' className='fs-2' />
          Add
        </button>
      ) : null}
    </>
  )
}

export {AgentListToolbar}
