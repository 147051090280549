import React, {FC, useEffect, useState} from 'react'
import {KTCard, KTCardBody, PaginationFilter} from '../../../../../_metronic/helpers'
import {Result} from '../../../../core/models/Result'
import {Agent, AgentRecord, TransactionHistory} from '../core/_models'
import {format} from 'date-fns'
import {Link, Outlet, useLocation, useNavigate, useParams} from 'react-router-dom'
import {getAgentById, getRecordById, transactionHistory} from '../core/_requests'
import {useQuery} from 'react-query'
import {Loading} from '../../../../core/shared/components/Loading'

const AgentDetailPage = () => {
  const {id} = useParams()
  const [recordDetails, setRecordDetails] = useState<AgentRecord>()
  const [transactions, setTransactions] = useState<TransactionHistory>()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname === `/sales/agent/detail/${id}`) {
      navigate(`/sales/agent/detail/${id}/overview`)
    }
  }, [location.pathname, id, navigate])

  const {
    isLoading,
    data: result,
    error,
  } = useQuery(
    ['agentById'],
    async () => {
      return await getAgentById(Number(id))
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  )

  if (isLoading) return <Loading />
  const agent = result as Agent

  // const [orderDetails, setOrderDetails] = useState<SalesOrder[]>([])

  // useEffect(() => {
  //     const fetchData = async () => {
  //         try {
  //             const updatedPaginationFilter: PaginationFilter = {
  //                 ...paginationFilter,
  //                 advancedFilter: {
  //                     field: "customerId",
  //                     operator: "eq",
  //                     value: parseInt(id as string),
  //                 },
  //             };
  //             // const response = await getSalesOrderList(updatedPaginationFilter);
  //             // setOrderDetails(response.data as SalesOrder[]);
  //             const response = await getRecordById(id)
  //             setRecordDetails(response as AgentRecord)

  //         } catch (error) {

  //         }
  //     };
  //     const transactionHistoryDetails = async () => {
  //         const response = await transactionHistory(id)
  //         setTransactions(response as TransactionHistory)
  //     }

  //     transactionHistoryDetails()

  //     fetchData();
  // }, []);

  return (
    <div id='kt_app_content' className='app-content  flex-column-fluid '>
      <div id='kt_app_content_container' className='app-container  container-xxl '>
        <div className='row'>
          <div className='col-md-12 col-lg-12 col-xl-4 flex-column'>
            <div className='card mb-5 mb-xl-8'>
              <div className='card-body pt-15'>
                <div className='d-flex flex-center flex-column mb-5'>
                  <div className='symbol symbol-100px symbol-circle mb-7 bg-gray border'>
                    <img src='/media/img/avatar.png' alt='image' />
                  </div>

                  <a href='#' className='fs-3 text-gray-800 text-hover-primary fw-bold mb-1'>
                    {`${agent.firstName} ${agent.lastName}`}
                  </a>

                  <div className='fs-5 fw-semibold text-muted mb-6'>Agent</div>
                </div>

                <div className='separator separator-dashed my-3' />
                <div className='row fs-6'>
                  <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-12'>
                    <div className='fw-bold mt-5'>Agent Code</div>
                    <div className='text-gray-600'>{agent.agentCode}</div>
                  </div>

                  <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-12'>
                    <div className='fw-bold mt-5'>Mobile Number</div>
                    <div className='text-gray-600'>
                      <a href='#' className='text-gray-600 text-hover-primary'>
                        {agent.mobileNumber}
                      </a>
                    </div>
                  </div>
                  <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-12'>
                    <div className='fw-bold mt-5'>Email Address</div>
                    <div className='text-gray-600'>{agent.emailAddress}</div>
                  </div>
                  <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-12'>
                    <div className='fw-bold mt-5'>Agent Status</div>
                    <div className='text-gray-600'>{agent.isActive ? 'Active' : 'InActive'}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12 col-lg-12 col-xl-8 d-flex flex-column gap-5 gap-lg-5'>
            <KTCard>
              <KTCardBody>
                <ul
                  className='nav nav-custom profile-nav nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold'
                  role='tablist'
                >
                  <li className='nav-item' role='presentation'>
                    <Link
                      className={
                        `nav-link text-active-primary ` +
                        (location.pathname === `/sales/agent/detail/${id}/overview` && 'active')
                      }
                      to={`/sales/agent/detail/${id}/overview`}
                    >
                      Overview{' '}
                    </Link>
                  </li>

                  <li className='nav-item' role='presentation'>
                    <Link
                      className={
                        `nav-link text-active-primary ` +
                        (location.pathname === `/sales/agent/detail/${id}/profile` && 'active')
                      }
                      to={`/sales/agent/detail/${id}/profile`}
                    >
                      Profile{' '}
                    </Link>
                  </li>

                  <li className='nav-item' role='presentation'>
                    <Link
                      className={
                        `nav-link text-active-primary ` +
                        (location.pathname === `/sales/agent/detail/${id}/commission` && 'active')
                      }
                      to={`/sales/agent/detail/${id}/commission`}
                    >
                      Commission{' '}
                    </Link>
                  </li>
                  <li className='nav-item' role='presentation'>
                    <Link
                      className={
                        `nav-link text-active-primary ` +
                        (location.pathname === `/sales/agent/detail/${id}/payout` && 'active')
                      }
                      to={`/sales/agent/detail/${id}/payout`}
                    >
                      Payout{' '}
                    </Link>
                  </li>
                </ul>
              </KTCardBody>
            </KTCard>
            <div>
              <Outlet></Outlet>
            </div>
            <KTCard>
              <KTCardBody>
                <div className='d-flex justify-content-end'>
                  <button
                    className='btn btn-primary'
                    onClick={() => {
                      navigate('/sales/agent/list')
                    }}
                  >
                    {' '}
                    <i className='ki-duotone ki-left fs-2' /> Back
                  </button>
                </div>
              </KTCardBody>
            </KTCard>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgentDetailPage
