import axios, { AxiosResponse } from 'axios'
import { CreateInVoicePayload, CustomerDetailforSOModel, InvoiceDetails, InvoiceQueryResponse, SaleOrderForInvoice, UpdateInVoicePayload,  } from './_models'
import { ID, PaginationFilter } from "../../../../../_metronic/helpers/crud-helper/models"
import { FileResult, Result } from '../../../../core/models/Result'
const API_URL = process.env.REACT_APP_STORE_API_URL
const INVOICE_BYID_URL = `${API_URL}/invoices`
const INVOICE_ADD_URL = `${API_URL}/invoices`
const INVOICE_LIST_URL = `${API_URL}/invoices/search`
const CUSTOMERDETAILSFORSO_URL = `${API_URL}/customers/details-for-so`
const GENERATE_PDF_URL = `${API_URL}/invoices/downloadpdf`
const UPDATE_INVOICE__STATUS = `${API_URL}/invoices/update-status`
const SALE_ORDER_FOR_INVOICE = `${API_URL}/saleorders/sale-order-for-invoice`

const getInvoiceList = (filter: PaginationFilter): Promise<InvoiceQueryResponse> => {
  
  return axios
    .post(`${INVOICE_LIST_URL}`, filter)
    .then((d: AxiosResponse<InvoiceQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}
const getInvoiceById = async (id: ID): Promise<InvoiceDetails> => {
  return await axios
    .get(`${INVOICE_BYID_URL}/${id}`)
    .then((response: AxiosResponse<InvoiceDetails>) => response.data)
    .then((response: InvoiceDetails) => response)
    .catch((err) => {
      return err
    })
}

const getCustomerDetailsForSOById = async (id: ID): Promise<CustomerDetailforSOModel> => {
  return axios
    .get(`${CUSTOMERDETAILSFORSO_URL}/${id}`)
    .then((response: AxiosResponse<CustomerDetailforSOModel>) => response.data)
    .then((response: CustomerDetailforSOModel) => response)
}
const createInvoice = async (invoice: CreateInVoicePayload): Promise<Result> => {
  return await axios
    .post(INVOICE_ADD_URL, invoice)
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const updateInvoice = (invoice:UpdateInVoicePayload): Promise<Result> => {
  return axios
    .put(`${INVOICE_BYID_URL}/${invoice.id}`, invoice)
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}
const invoiceGeneratePdf = async (id: ID): Promise<Result | FileResult> => {
  return await axios
    .get(`${GENERATE_PDF_URL}/${id}`, {
      responseType: 'blob'
    })
    .then((response: AxiosResponse<any>) => {

      let filename = response.headers['content-disposition']
        .split(';')
        .find((n: any) => n.includes('filename='))
        .replace('filename=', '')
        .trim();

      var result: FileResult = {
        data: response.data,
        name: filename
      }

      return result
    })
    .catch((err: Result) => {
      return err
    })
}


const updateInvoiceStatus = async (id: ID, note: string): Promise<Result> => {
  return await axios.put(`${UPDATE_INVOICE__STATUS}`, {
    invoiceId: id,
    invoiceStatusId: 3,
    notes: note
  }).then((d) => d.data).catch((err) => { return err})
}

const getSalesOrderInvoiceById = async (id: ID): Promise<SaleOrderForInvoice> => {
  return await axios
    .get(`${SALE_ORDER_FOR_INVOICE}/${id}`)
    .then((response: AxiosResponse<SaleOrderForInvoice>) => response.data)
    .then((response: SaleOrderForInvoice) => response)
    .catch((err) => {
      return err
    })
}

export {
  API_URL,
  getInvoiceById,
  getInvoiceList,
  createInvoice,
  getCustomerDetailsForSOById,
  updateInvoice,
  invoiceGeneratePdf,
  getSalesOrderInvoiceById,
  updateInvoiceStatus
}
