import {FC, useEffect, useState} from 'react'
import {useListView} from '../core/ListViewProvider'
import {ID} from '../../../../../../_metronic/helpers/crud-helper/models'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {useNavigate} from 'react-router-dom'
import { MustHavePermission, SWSection, SWResource, SWAction } from '../../../../../core/shared/core/permissionUtils'
import { KTIcon } from '../../../../../../_metronic/helpers'

type Props = {
  id: ID
}
const PaymentActionsCell: FC<Props> = ({id}) => {
  const {setItemIdForUpdate} = useListView()
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }
  const navigate = useNavigate()
  return (
    <>
        <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>
      
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4'
        data-kt-menu='true'
      >
      
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={() => navigate(`/purchase/payment/detail/${id}`)}>
            Detail
          </a>
        </div>
        {/* <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='download_pdf'
            onClick={()=>{
              // saleOrderpdf(id)
            }}
          >
            Download PDF
          </a>
        </div> */}
       
      </div>
    </>
  )
}
export {PaymentActionsCell}
