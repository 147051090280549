import axios, {AxiosResponse} from 'axios'

import {ID, PaginationFilter} from '../../../../../../_metronic/helpers/crud-helper/models'
import {Result} from '../../../../../core/models/Result'
import {ProductStockQueryResponse} from './_model'

const API_URL = process.env.REACT_APP_STORE_API_URL
const PRODUCTSTOCK_LIST_URL = `${API_URL}/productstock/search`

const getProductStockList = (filter: PaginationFilter): Promise<ProductStockQueryResponse> => {
  return axios
    .post(`${PRODUCTSTOCK_LIST_URL}`, filter)
    .then((d: AxiosResponse<ProductStockQueryResponse>) => {
   
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

export {getProductStockList}
