import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {InputNumber} from 'primereact/inputnumber'
import {Dropdown} from 'primereact/dropdown'
import {Button} from 'primereact/button'
import {formatCurrency} from '../../utils/helpers'
import {useQuery} from 'react-query'
import {getOtherChargeList} from '../core/_request'
import {SelectProps} from '../../models/SelectOptionProps'
import {OtherCharge} from '../core/_model'
import {Loading} from './Loading'
import {useState} from 'react'
import {Field} from 'formik'
import CustomSelect from './CustomSelect'
import {ro} from 'date-fns/locale'
import {relative} from 'path'

const OtherChargesTable = ({otherCharges, setOtherCharges}) => {
  const [errorMessages, setErrorMessages] = useState<Record<number, string>>({})

  const {data: chargesListResponse, isLoading: isChargesListLoading} = useQuery(
    ['chargesList'],
    () =>
      getOtherChargeList({
        pageNumber: 1,
        pageSize: 500,
      })
  )

  if (isChargesListLoading) return <Loading />

  const chargeOptions: SelectProps[] =
    chargesListResponse?.data?.map(({name}: OtherCharge) => ({
      value: String(name),
      label: String(name),
    })) || []

  // Handle dropdown change
  const onDropdownChange = (rowNumber: number, newValue: {label: string}) => {
    setOtherCharges((draft) => {
      const charge = draft.find((t) => t.rowNumber === rowNumber)
      if (charge) {
        charge.label = newValue?.label || ''
      }
    })
  }

  const onAmountChange = (rowNumber: number, newValue: number | null) => {
    if (newValue === null || isNaN(newValue)) {
      setErrorMessages((prev) => ({
        ...prev,
        [rowNumber]: 'Please enter a valid number.',
      }))
      return
    }

    setErrorMessages((prev) => {
      const {[rowNumber]: _, ...rest} = prev // Safely remove the error for the current rowNumber
      return rest
    })

    setOtherCharges((draft) => {
      const charge = draft.find((t) => t.rowNumber === rowNumber)
      if (charge) {
        charge.amount = newValue
      }
    })
  }

  // Handle delete row
  const onDeleteRow = (rowNumber) => {
    setOtherCharges((draft) => {
      const charge = draft.find((t) => t.rowNumber === rowNumber)
      if (charge) {
        charge.isDeleted = true
      }
    })
  }

  // Handle add row
  const handleAddOtherChargesRow = () => {
    setOtherCharges((draft) => {
      draft.push({
        rowNumber: draft.length > 0 ? draft[draft.length - 1].rowNumber + 1 : 1,
        label: '',
        amount: null,
        isDeleted: false,
      })
    })
  }

  const amountColumnBody = (rowData) => (
    <>
      <div className='p-inputgroup'>
        <InputNumber
          value={rowData.amount}
          //   onValueChange={(e) => onAmountChange(rowData.rowNumber, e.value ?? null)} // Convert undefined to null
          onChange={(e) => onAmountChange(rowData.rowNumber, e.value ?? null)} // Convert undefined to null
          mode='currency'
          className='w-full'
          inputClassName='form-control'
          currency='INR'
          locale='en-IN'
          inputStyle={{textAlign: 'right'}} // Align text to the right
        />
      </div>
      {errorMessages[rowData.rowNumber] && (
        <small className='p-error' style={{position: 'absolute', bottom: '-2px'}}>
          {errorMessages[rowData.rowNumber]}
        </small>
      )}
    </>
  )

  return (
    <div>
      {otherCharges.filter((t) => !t.isDeleted).length > 0 && (
        <DataTable
          value={otherCharges.filter((t) => t.isDeleted === false)}
          className='p-datatable-sm p-datatable-gridlines p-datatable-responsive-scroll p-datatable-other-charges'
          rowClassName={() => 'relative'}
          header={null} // No header
          tableStyle={{minWidth: '26rem'}}
        >
          {/* Dropdown for Label */}
          <Column
            className='pb-4'
            body={(rowData) => (
              <Field
                className='form-select-solid'
                options={chargeOptions}
                component={CustomSelect}
                selectedValue={rowData?.label}
                name={`otherCharges[${rowData.rowNumber}].label`}
                onDropDownChange={(newValue: any) => {
                  console.log('Dropdown Change Event:', newValue)
                  onDropdownChange(rowData.rowNumber, newValue)
                  //   if (newValue === null) {
                  //     setOtherCharges((oc) => {
                  //       oc[ocitem.rowNumber].label = ''
                  //     })
                  //   } else {
                  //     setOtherCharges((oc) => {
                  //       oc[ocitem.rowNumber].label = newValue.label
                  //     })
                  //   }
                }}
                isClearable={true}
                isMulti={false}
              ></Field>
              // <Dropdown
              //     value={chargeOptions.find(
              //         (option) => option.label.trim().toLowerCase() === rowData.label.trim().toLowerCase()
              //     ) || null}
              //     options={chargeOptions}
              //     onChange={(e) => {
              //         console.log('Dropdown Change Event:', e.value);
              //         onDropdownChange(rowData.rowNumber, e.value);
              //     }}
              //     placeholder="Select Charge"
              //     className="w-full"
              // />
            )}
            // style={{ width: '210px' }}
          />

          {/* Input Number for Amount */}
          <Column
            className='pb-4'
            body={amountColumnBody}
            // style={{ width: '30%' }}
            // style={{ width: '190px' }}
          />

          {/* Delete Button */}
          <Column
            className='pb-4'
            body={(rowData) => (
              <Button
                type='button'
                icon='pi pi-times'
                className='p-button-rounded p-button-danger p-button-sm text-center'
                style={{height: '25px', width: '25px'}}
                onClick={() => onDeleteRow(rowData.rowNumber)}
              />
            )}
            // style={{ textAlign: 'center', width: '5%' }}
          />

          {/* Amount Display */}
          <Column
            className='pb-4'
            body={(rowData) => (
              <span className='p-text-right'>{formatCurrency(rowData.amount)}</span>
            )}
            // style={{ textAlign: 'right', width: '35%' }}
            // style={{ width: '190px' }}
          />
        </DataTable>
      )}
      <div className='form-group mt-3'>
        <Button
          type='button'
          label='+ Add another Charges'
          className='p-button-rounded p-button-sm p-button-primary'
          onClick={handleAddOtherChargesRow}
        />
      </div>
    </div>
  )
}

export default OtherChargesTable
