import { useQuery } from 'react-query'
import { useListView } from '../core/ListViewProvider'
import { isNotEmpty } from '../../../../../_metronic/helpers/crud-helper/helpers'
import { QUERIES } from '../../../../../_metronic/helpers/crud-helper/consts'
import { useParams } from 'react-router-dom'
import { getNewArrivalById } from '../core/_requests'
import NewArrivalEditPage from './NewArrivalEditPage'




const NewArrivalWrapper = () => {
  const {id}=useParams()
  const { itemIdForUpdate, setItemIdForUpdate } = useListView()
  const enabledQuery: boolean = isNotEmpty(id)
  const {
    isLoading,
    data: result,
    error,
  } = useQuery(
    `${QUERIES.NEW_ARRIVALS}-${id}`,
    async () => {
      return await getNewArrivalById(id)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

 
  if (!isLoading && !error && result) {
    return <NewArrivalEditPage isNewArrivalLoading={isLoading} data={result} />
  }

  return null
}

export { NewArrivalWrapper }
