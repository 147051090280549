import { Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { AccountWrapper } from './pages/AccountList'
import { MustHavePermission, SWSection, SWResource, SWAction } from '../../../core/shared/core/permissionUtils'
import UnAuthorized from '../../errors/components/UnAuthorized'
const Breadcrumbs: Array<PageLink> = [
    {
        title: 'Home',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
    {
        title: 'Settings',
        path: '',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
]

const AccountPage = () => {
    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='list'
                    index
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Account </PageTitle>
                            {MustHavePermission(SWSection.Store, SWResource.Accounts, SWAction.Search) ?
                            <AccountWrapper />
                            :<UnAuthorized/>}
                        </>
                    }
                />

               

            </Route>
        </Routes>
    )
}

export default AccountPage
