import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Yup from "yup";
import { Field, FormikProvider, useFormik } from 'formik';
import { Banner, Page, Section, initialBanner } from '../core/_models';
import { KTSVG, PaginationFilter, isNotEmpty } from '../../../../../_metronic/helpers';
import { Result } from '../../../../core/models/Result';
import { createBanner, getPageList, getSectionList } from '../core/_requests';
import { toast } from 'react-toastify';
import { camelize } from '../../../../core/utils/StringHelpers';
import UploadImage from '../../../../core/shared/components/UploadImage';
import clsx from 'clsx';
import CustomSelect from '../../../../core/shared/components/CustomSelect';
import { SelectOptionProps } from '../../../../core/models/SelectOptionProps';
import { FileUploadRequest } from '../../../../core/models/FileUploadRequest';

const paginationFilter: PaginationFilter = {
  pageNumber: 1,
  pageSize: 500,


};

const BannerAddPage = () => {

  const API_URL = process.env.REACT_APP_IMAGE_API_URL
  const [selectedMobImage, setSelectedMobImage] = useState<FileUploadRequest>({})
  const [selectedWebImage, setSelectedWebImage] = useState<FileUploadRequest>({})
  const [pageList, setPageList] = useState<SelectOptionProps[]>([])
  const [sectionList, setSectionList] = useState<SelectOptionProps[]>([])


  const navigate = useNavigate()

  const bannerSchema = Yup.object().shape({
    wImagePath: Yup.string().required('Please upload Home Page Banner image'),
    mobImagePath: Yup.string().required('Please upload Home Page Banner image'),
    name: Yup.string()
      .matches(/^[A-Za-z0-9\-_', .]*$/, "Only alphanumeric, _, -, ' and space are allowed for this field")
      .required("Please enter First name"),
    pageId: Yup.number().required('Please select the page'),
    sectionId: Yup.number().required('Please select the page'),
    title: Yup.string().required('Please enter title'),
    linkUrl: Yup.string().required("Please enter URL.")

  })

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: initialBanner,
    validationSchema: bannerSchema,

    onSubmit: async (formValues, { setSubmitting, setFieldError }) => {

      setSubmitting(true)
      let bannerData: Banner = {
        id: formValues.id,
        name: formValues.name,
        pageId: formValues.pageId,
        sectionId: formValues.sectionId,
        shortDescription: formValues.shortDescription,
        title: formValues.title,
        linkUrl: formValues.linkUrl

      }

      if (isNotEmpty(selectedMobImage.data)) {
        bannerData.uploadMobileImage = selectedMobImage;
      }

      if (isNotEmpty(selectedWebImage.data)) {
        bannerData.uploadWebImage = selectedWebImage;
      }

      try {
        let result: Result;
        result = await createBanner(bannerData)
        if (result.hasOwnProperty('succeeded') && result?.succeeded) {
          setSubmitting(true)
          toast.success("Banner added successfully.")
          navigate('/cms/banner/list')

        }
        else {
          if (result.statusCode === 400) {
            result.propertyResults.map((error) =>
              setFieldError(camelize(error.propertyName), error.errorMessage),
              toast.error(result.exception)

            )
          }
        }

      } catch (ex) {
        console.error(ex)
        toast.error("Error caught during adding banner.")
      }
    },
  })

  useEffect(() => {
    let result: any

    getPageList(paginationFilter).then((v) => {
      result = v.data as Page[]
      let pageArray: any[] = []
      result.map((item: any) => {
        return pageArray.push({ value: item.id, label: item.name })
      })
      setPageList(pageArray)
    })

    getSectionList(paginationFilter).then((v) => {
      result = v.data as Section[]
      let sectionArray: any[] = []
      result.map((item: any) => {
        return sectionArray.push({ value: item.id, label: item.name })
      })
      setSectionList(sectionArray)
    })
  }, [])

  return (
    <FormikProvider value={formik}>
      <form
        id='banner_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate

      >
        <div className="card mb-5 mb-xl-5">
          <div className="card-header">
            <div className="card-title">
              <h3 className="fw-bold">Home Page Banner</h3>
            </div>
          </div>

          
            <div className="card-body">
              <div className="row mb-5">
                <label className="col-xxl-2 col-xl-3 col-lg-3 col-md-3 col-form-label fw-semibold fs-6">
                  Banner Image
                </label>

                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6">
                  <input type="hidden" {...formik.getFieldProps('wImagePath')}></input>

                  <UploadImage

                    HandleUpload={(img: FileUploadRequest) => {
                      setSelectedWebImage(img);
                      formik.setFieldValue('wImagePath', img.name);

                    }}
                    HandleRemove={() => { setSelectedWebImage({}) }} />
                  <div className='form-text'>  Upload image for Web view</div>

                  {formik.touched.wImagePath && formik.errors.wImagePath && (
                    <div className='fv-help-block text-center'>
                      <span role='alert ' className="text-danger">{formik.errors.wImagePath}</span>
                    </div>
                  )}
                </div>

                <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6">
                  <input type="hidden" {...formik.getFieldProps('mobImagePath')}></input>

                  <UploadImage
                    HandleUpload={(img: FileUploadRequest) => {
                      setSelectedMobImage(img);
                      formik.setFieldValue('mobImagePath', img.extension);
                    }}
                    HandleRemove={() => {
                      setSelectedMobImage({});
                    }}
                  />
                  <div className='form-text'> Upload image for mobile view</div>

                  {formik.touched.mobImagePath && formik.errors.mobImagePath && (
                    <div className='fv-help-block text-center'>
                      <span role='alert ' className="text-danger">{formik.errors.mobImagePath}</span>
                    </div>
                  )}
                </div>
              </div>

              <div className="row mb-md-5">

                <label className="col-xxl-2 col-xl-3 col-lg-3 col-md-3 col-form-label fw-semibold fs-6">
                  Banner Name
                </label>

                <div className="col-xxl-8 col-xl-6 col-lg-9 col-md-9">
                  <Field
                    type='text'
                    className={clsx(
                      'form-control form-control-solid mb-lg-0',
                      { 'is-invalid': formik.touched.name && formik.errors.name },
                      {
                        'is-valid': formik.touched.name && !formik.errors.name,
                      }
                    )}
                    placeholder='Name'
                    {...formik.getFieldProps('name')}
                    name='name'
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.name}</span>
                      </div>
                    </div>
                  )}
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />
                </div>

              </div>

              <div className="row mb-md-5">
                <label className="col-xxl-2 col-xl-3 col-lg-3 col-md-3 col-form-label fw-semibold fs-6">
                  Banner Title
                </label>

                <div className="col-xxl-8 col-xl-6 col-lg-9 col-md-9">
                  <Field
                    type='text'
                    className={clsx(
                      'form-control form-control-solid mb-lg-0',
                      { 'is-invalid': formik.touched.title && formik.errors.title },
                      {
                        'is-valid': formik.touched.title && !formik.errors.title,
                      }
                    )}
                    placeholder='Title'
                    {...formik.getFieldProps('title')}
                    name='title'
                  />
                  {formik.touched.title && formik.errors.title && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.title}</span>
                      </div>
                    </div>
                  )}
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />
                </div>

              </div>

              <div className="row mb-md-5">

                <label className="col-xxl-2 col-xl-3 col-lg-3 col-md-3 col-form-label fw-semibold fs-6">
                  Link URL
                </label>

                <div className="col-xxl-8 col-xl-6 col-lg-9 col-md-9">
                  <Field
                    type='text'
                    className={clsx(
                      'form-control form-control-solid mb-lg-0',
                      { 'is-invalid': formik.touched.linkUrl && formik.errors.linkUrl },
                      {
                        'is-valid': formik.touched.linkUrl && !formik.errors.linkUrl,
                      }
                    )}
                    placeholder='Link Url'
                    {...formik.getFieldProps('linkUrl')}
                    name='linkUrl'
                  />
                  {formik.touched.linkUrl && formik.errors.linkUrl && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.linkUrl}</span>
                      </div>
                    </div>
                  )}
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />


                </div>

              </div>

              <div className="row mb-md-5">

                <label className="col-xxl-2 col-xl-3 col-lg-3 col-md-3 col-form-label fw-semibold fs-6">
                  Page
                </label>

                <div className="col-xxl-8 col-xl-6 col-lg-9 col-md-9">
                  <Field
                    className='form-select-solid w-100'
                    component={CustomSelect}
                    id="pageId"
                    {...formik.getFieldProps('pageId')}
                    name="pageId"
                    options={pageList}
                  >
                  </Field>

                  {formik.touched.pageId && formik.errors.pageId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.pageId}</span>
                      </div>
                    </div>
                  )}
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />
                </div>

              </div>

              <div className="row mb-md-5">

                <label className="col-xxl-2 col-xl-3 col-lg-3 col-md-3 col-form-label fw-semibold fs-6">
                  Section
                </label>

                <div className="col-xxl-8 col-xl-6 col-lg-9 col-md-9">

                  <Field
                    className='form-select-solid'
                    component={CustomSelect}
                    id="sectionId"
                    {...formik.getFieldProps('sectionId')}
                    name="sectionId"
                    options={sectionList}


                  >
                  </Field>
                  {formik.touched.sectionId && formik.errors.sectionId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.sectionId}</span>
                      </div>
                    </div>
                  )}
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />


                </div>

              </div>

              <div className="row">

                <label className="col-xxl-2 col-xl-3 col-lg-3 col-md-3 col-form-label fw-semibold fs-6">
                  Short Description
                </label>

                <div className="col-xxl-8 col-xl-6 col-lg-9 col-md-9">
                  <textarea
                    rows={4}
                    className={clsx(
                      'form-control form-control-solid mb-lg-0',
                      { 'is-invalid': formik.touched.shortDescription && formik.errors.shortDescription },
                      {
                        'is-valid': formik.touched.shortDescription && !formik.errors.shortDescription,
                      }
                    )}
                    placeholder='Short Description'
                    {...formik.getFieldProps('shortDescription')}
                    name='shortDescription'
                  />
                  {formik.touched.shortDescription && formik.errors.shortDescription && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.shortDescription}</span>
                      </div>
                    </div>
                  )}
                  <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />


                </div>

              </div>

            </div>
            <div className="card-footer text-end">
            <button
                type='reset'
                className='btn btn-light me-3'
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_1"
                disabled={formik.isSubmitting}
              >
                Discard
              </button>
              <div className="modal fade" tabIndex={-1} id="kt_modal_1">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h2>Confirmation</h2>
                      <div
                        className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <KTSVG
                          path="/media/icons/duotune/arrows/arr061.svg"
                          className="svg-icon svg-icon-2x"
                        />
                      </div>
                    </div>
                    <div className="modal-body">
                      <div className="w-100">

                        {/* <div className="fv-row mb-10 d-flex justify-content-center exclamation fs-14">
												<i className="las la-exclamation-circle"></i>
											</div> */}

                        <div className="fv-row text-center">
                          <h5>Are you sure you want to discard ?</h5>
                        </div>

                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-light"
                        data-bs-dismiss="modal"
                        onClick={() => navigate('/cms/banner/list')}
                      >
                        Yes
                      </button>
                      <button type="button" className="btn btn-primary" data-bs-dismiss="modal">
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type='submit'
                className='btn btn-primary'
                data-kt-color-modal-action='submit'
                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
              >
                <span className='indicator-label'>Submit</span>
                {(formik.isSubmitting) && (
                  <span className='indicator-progress'>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>          
        </div>
      </form>
    </FormikProvider>
  )

}

export default BannerAddPage