import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {Field, FormikProvider, useFormik} from 'formik'
import clsx from 'clsx'
import {isNotEmpty} from '../../../../../_metronic/helpers/crud-helper/helpers'
import {Result} from '../../../../core/models/Result'
import {camelize} from '../../../../core/utils/StringHelpers'
import CustomSelect from '../../../../core/shared/components/CustomSelect'
import {Loading} from '../../../../core/shared/components/Loading'
import {SelectOptionProps} from '../../../../core/models/SelectOptionProps'
import {PaginationFilter} from '../../../../../_metronic/helpers/crud-helper/models'
import {getStateList} from '../../../../core/shared/core/_request'
import {State} from '../../../../core/shared/core/_model'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {initialAgent} from '../core/_models'
import {createAgent} from '../core/_requests'
import Flatpickr from 'react-flatpickr'
import {FileUploadRequest} from '../../../../core/models/FileUploadRequest'
import UploadImage from '../../../../core/shared/components/UploadImage'
import {format} from 'date-fns'
import {toZonedTime} from 'date-fns-tz'
import CalendarInput from '../../../../core/shared/components/CalendarInput'

type Props = {
  isAgentLoading: boolean
}

const paginationFilter: PaginationFilter = {
  pageNumber: 1,
  pageSize: 500,
  advancedFilter: {
    field: 'isActive',
    operator: 'eq',
    value: true,
  },
}

const AgentSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(
      /^[A-Za-z0-9\-_', .]*$/,
      "Only alphanumeric, _, -, ' and space are allowed for this field"
    )
    .required('Please enter First name'),
  lastName: Yup.string()
    .matches(
      /^[A-Za-z0-9\-_', .]*$/,
      "Only alphanumeric, _, -, ' and space are allowed for this field"
    )
    .required('Please enter Last name'),
  companyName: Yup.string().matches(
    /^[A-Za-z0-9\-_', .]*$/,
    "Only alphanumeric, _, -, ' and space are allowed for this field"
  ),
  mobileNumber: Yup.string()
    .matches(/^[7-9]\d{9}$/, {
      message: 'Please enter valid phone number.',
      excludeEmptyString: false,
    })
    .required('Please enter Phone Number'),
  whatsappNumber: Yup.string().matches(/^[7-9]\d{9}$/, {
    message: 'Please enter valid phone number.',
    excludeEmptyString: false,
  }),
  commissionPercent: Yup.number()
    .max(99.99, 'Commssion cannot be more than 100%')
    .moreThan(-1, 'Commission percent cannot be negative'),
  dateOfBirth: Yup.date()
    .max(new Date(), 'Date of birth cannot be greater than the current date')
    .min(new Date('1900-01-01'), 'Date of birth cannot be earlier than January 1, 1900'),
  dateOfAnniversary: Yup.date()

    .max(new Date(), 'Date of Anniversary cannot be greater than the current date')
    .min(new Date('1900-01-01'), 'Date of Anniversary cannot be earlier than January 1, 1900'),
  addressLine1: Yup.string().matches(
    /^[A-Za-z0-9\-_', .]*$/,
    "Only alphanumeric, _, -, ' and space are allowed for this field"
  ),
  addressLine2: Yup.string().matches(
    /^[A-Za-z0-9\-_', .]*$/,
    "Only alphanumeric, _, -, ' and space are allowed for this field"
  ),
  city: Yup.string().matches(
    /^[A-Za-z0-9\-_', .]*$/,
    "Only alphanumeric, _, -, ' and space are allowed for this field"
  ),
  emailAddress: Yup.string().email().required('Please enter Email Address'),
  statusId: Yup.number(),
  enableLogin: Yup.boolean(),
  userName: Yup.string().when('enableLogin', {
    is: true,
    then(schema) {
      return schema.required('Please enter UserName.')
    },
  }),
  password: Yup.string().when('enableLogin', {
    is: true,
    then(schema) {
      return schema
        .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/, {
          message:
            'Password should be atleast 6 characters including letter,digit and 1 special symbol',
        })
        .required('Please enter password.')
    },
  }),
  zipCode: Yup.string().matches(/^[0-9]{6}$/, 'Must be exactly 6 digits'),
  agentCode: Yup.string().required('Enter Agent Code'),
  //agentImagePath:Yup.string().required('Please upload agent image'),
})

const AgentAddPage: FC<Props> = ({isAgentLoading}) => {
  const [phoneNumber, setPhoneNumber] = useState(false)
  const navigate = useNavigate()
  const today = new Date()
  const minDate = new Date('1900-01-01')
  // const cancel = (withRefresh?: boolean) => {
  //   if (withRefresh) {
  //     refetch();
  //   }
  //   setItemIdForUpdate(undefined);
  // };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialAgent,
    validationSchema: AgentSchema,

    onSubmit: async (formValues, {setFieldError, setSubmitting}) => {
      setSubmitting(true)
      try {
        if (
          (formValues?.dateOfBirth as Date) > today ||
          (formValues?.dateOfBirth as Date) < minDate
        ) {
          formik.setFieldError('dateOfBirth', 'Please enter valid Date')
          return
        }

        if (
          (formValues?.dateOfAnniversary as Date) > today ||
          (formValues?.dateOfAnniversary as Date) < minDate
        ) {
          formik.setFieldError('dateOfAnniversary', 'Please enter valid Date')
          return
        }
        if (formValues.dateOfBirth) {
          const dob = toZonedTime(formValues.dateOfBirth, 'Asia/Kolkata')
          formValues.dateOfBirth = format(dob, 'yyyy-MM-dd')
        }
        if (formValues.dateOfAnniversary) {
          const doa = toZonedTime(formValues.dateOfAnniversary, 'Asia/Kolkata')
          formValues.dateOfAnniversary = format(doa, 'yyyy-MM-dd')
        }

        let result: Result
        if (isNotEmpty(selectedImage.data)) {
          formValues.uploadImage = selectedImage
        }
        result = await createAgent(formValues)
        if (result.hasOwnProperty('succeeded') && result?.succeeded) {
          setSubmitting(true)
          toast.success('Agent updated successfully.')
          navigate('/sales/agent/list')

          // cancel(true);
        } else {
          if (result.statusCode === 400) {
            result.propertyResults.map(
              (error) => setFieldError(camelize(error.propertyName), error.errorMessage),
              toast.error('Error while updating Agent')
            )
          }
        }
      } catch (ex) {
        console.error(ex)
      }
    },
  })

  const [stateList, setStateList] = useState<SelectOptionProps[]>([])
  useEffect(() => {
    let result: any
    getStateList(paginationFilter).then((v) => {
      result = v.data as State[]
      let stateArray: any[] = []
      result.map((item: any) => {
        return stateArray.push({value: item.id, label: item.name})
      })
      setStateList(stateArray)
    })
  }, [])

  const customerType = [
    {value: 1, label: 'Wholeseller'},
    {value: 2, label: 'SemiWholeseller'},
    {value: 3, label: 'Retail'},
  ]

  const setWhatsapp = () => {
    if (!phoneNumber) {
      formik.setFieldValue('whatsappNumber', formik.values.mobileNumber)
    } else {
      formik.setFieldValue('whatsappNumber', '')
    }

    setPhoneNumber(!phoneNumber)
  }
  const [selectedImage, setSelectedImage] = useState<FileUploadRequest>({})

  const generateRandomPassword = () => {
    const length = 10
    const alphabet = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const digits = '0123456789'
    const specialChars = '@$!%*?&'

    const getRandomChar = (charset) => {
      const randomIndex = Math.floor(Math.random() * charset.length)
      return charset[randomIndex]
    }

    // Ensure at least one character from each category
    const password =
      getRandomChar(alphabet) +
      getRandomChar(digits) +
      getRandomChar(specialChars) +
      Array.from({length: length - 3}, () => getRandomChar(alphabet + digits + specialChars)).join(
        ''
      )

    // Shuffle the password characters to ensure randomness
    const shuffledPassword = password
      .split('')
      .sort(() => Math.random() - 0.5)
      .join('')

    return shuffledPassword
  }

  const handleGeneratePassword = () => {
    const newPassword = generateRandomPassword()
    formik.setFieldValue('password', newPassword)
    setShowGeneratedPassword(true)
  }
  const [showGeneratedPassword, setShowGeneratedPassword] = useState(false)
  const handleTogglePasswordVisibility = () => {
    setShowGeneratedPassword(!showGeneratedPassword)
  }

  return (
    <FormikProvider value={formik}>
      <form id='kt_modal_add_agent_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='d-flex flex-column flex-lg-row' id='kt_modal_add_agent_scroll'>
          <div className='w-100 w-lg-300px mb-5 me-lg-10'>
            <div className='card'>
              <div className='card-header'>
                <div className='card-title ms-7'>
                  <h3>Agent Profile Image</h3>
                </div>
              </div>
              {/* <input type="hidden" {...formik.getFieldProps('agentImagePath')}></input> */}

              <div className='card-body text-center'>
                <UploadImage
                  HandleUpload={(img: FileUploadRequest) => {
                    setSelectedImage(img)
                    formik.setFieldValue('agentImagePath', img.name)
                  }}
                  HandleRemove={() => {
                    setSelectedImage({})
                  }}
                />
                {/* {formik.touched.agentImagePath && formik.errors.agentImagePath && (
                                        <div className='fv-help-block text-center'>
                                            <span role='alert ' className="text-danger">{formik.errors.agentImagePath}</span>
                                        </div>
                                    )} */}
              </div>
            </div>
          </div>
          <div className='d-flex flex-column flex-row-fluid gap-5 gap-lg-5'>
            <div className='card'>
              <div className='card-header'>
                <div className='card-title'>
                  <h3>Add Agent</h3>
                </div>
              </div>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-xl-6 col-lg-12 col-md-6 mb-5'>
                    <label className='required fw-bold fs-6 mb-2'>First Name</label>

                    <input
                      placeholder='First Name'
                      {...formik.getFieldProps('firstName')}
                      type='text'
                      name='firstName'
                      className={clsx(
                        'form-control form-control-solid mb-lg-0 ps-4',
                        {
                          'is-invalid': formik.touched.firstName && formik.errors.firstName,
                        },
                        {
                          'is-valid': formik.touched.firstName && !formik.errors.firstName,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isAgentLoading}
                    />
                    {formik.touched.firstName && formik.errors.firstName && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.firstName}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-xl-6 col-lg-12 col-md-6 mb-5'>
                    <label className='required fw-bold fs-6 mb-2'>Last Name</label>
                    <input
                      placeholder='Last Name'
                      {...formik.getFieldProps('lastName')}
                      type='text'
                      name='lastName'
                      className={clsx(
                        'form-control form-control-solid mb-lg-0 ps-4',
                        {
                          'is-invalid': formik.touched.lastName && formik.errors.lastName,
                        },
                        {
                          'is-valid': formik.touched.lastName && !formik.errors.lastName,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isAgentLoading}
                    />
                    {formik.touched.lastName && formik.errors.lastName && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.lastName}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row'>
                  <div className='col-xl-6 col-lg-12 col-md-6 mb-5'>
                    <label className='fw-bold fs-6 mb-2'>Company Name</label>
                    <input
                      placeholder='Company Name'
                      {...formik.getFieldProps('companyName')}
                      type='text'
                      name='companyName'
                      className={clsx(
                        'form-control form-control-solid mb-lg-0 ps-4',
                        {
                          'is-invalid': formik.touched.companyName && formik.errors.companyName,
                        },
                        {
                          'is-valid': formik.touched.companyName && !formik.errors.companyName,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isAgentLoading}
                    />
                    {formik.touched.companyName && formik.errors.companyName && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.companyName}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-xl-6 col-lg-12 col-md-6 mb-5'>
                    <label className='required fw-bold fs-6 mb-2'>Email</label>

                    <input
                      placeholder='Email'
                      {...formik.getFieldProps('emailAddress')}
                      type='email'
                      name='emailAddress'
                      className={clsx(
                        'form-control form-control-solid mb-lg-0 ps-4',
                        {
                          'is-invalid': formik.touched.emailAddress && formik.errors.emailAddress,
                        },
                        {
                          'is-valid': formik.touched.emailAddress && !formik.errors.emailAddress,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isAgentLoading}
                      onChange={(e) => {
                        formik.handleChange(e)
                        //if(formik.values.enableLogin )
                        formik.setFieldValue('userName', e.target.value)
                      }}
                    />
                    {formik.touched.emailAddress && formik.errors.emailAddress && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.emailAddress}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row'>
                  <div className='col-xl-6 col-lg-12 col-md-6 mb-5'>
                    <label className='required fw-bold fs-6 mb-2'>Mobile Number</label>
                    <input
                      placeholder='Mobile Number'
                      {...formik.getFieldProps('mobileNumber')}
                      type='text'
                      name='mobileNumber'
                      className={clsx(
                        'form-control form-control-solid mb-lg-0 ps-4',
                        {
                          'is-invalid': formik.touched.mobileNumber && formik.errors.mobileNumber,
                        },
                        {
                          'is-valid': formik.touched.mobileNumber && !formik.errors.mobileNumber,
                        }
                      )}
                      autoComplete='off'
                      onChange={(e) => {
                        if (phoneNumber) {
                          formik.setFieldValue('whatsappNumber', e.target.value)
                          formik.setFieldValue('mobileNumber', e.target.value)
                        } else {
                          formik.setFieldValue('mobileNumber', e.target.value)
                        }
                      }}
                      disabled={formik.isSubmitting || isAgentLoading}
                    />
                    {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.mobileNumber}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='col-xl-6 col-lg-12 col-md-6 mb-5'>
                    <label className='fw-bold fs-6 mb-2'>Whatsapp Number</label>

                    <input
                      placeholder='Whatsapp Number'
                      {...formik.getFieldProps('whatsappNumber')}
                      type='text'
                      name='whatsappNumber'
                      className={clsx(
                        'form-control form-control-solid mb-2 ps-4',
                        {
                          'is-invalid':
                            formik.touched.whatsappNumber && formik.errors.whatsappNumber,
                        },
                        {
                          'is-valid':
                            formik.touched.whatsappNumber && !formik.errors.whatsappNumber,
                        }
                      )}
                      autoComplete='off'
                      onChange={(e) => {
                        if (phoneNumber) {
                          formik.setFieldValue('whatsappNumber', e.target.value)
                          formik.setFieldValue('mobileNumber', e.target.value)
                        } else {
                          formik.setFieldValue('whatsappNumber', e.target.value)
                        }
                      }}
                      disabled={formik.isSubmitting || isAgentLoading}
                    />
                    <div className='form-check form-check-custom form-check-solid'>
                      <small className='text-gray'> Same as Mobile Number</small>
                      <input
                        className='form-check-input ms-3'
                        type='checkbox'
                        checked={phoneNumber}
                        onChange={setWhatsapp}
                        disabled={formik.isSubmitting || isAgentLoading}
                      />

                      {formik.touched.whatsappNumber && formik.errors.whatsappNumber && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.whatsappNumber}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-xl-6 col-lg-12 col-md-6 mb-5'>
                    <label className='fw-bold fs-6 mb-2'>AddressLine 1</label>

                    <input
                      placeholder='addressLine1'
                      {...formik.getFieldProps('addressLine1')}
                      type='text'
                      name='addressLine1'
                      className={clsx(
                        'form-control form-control-solid mb-lg-0 ps-4',
                        {
                          'is-invalid': formik.touched.addressLine1 && formik.errors.addressLine1,
                        },
                        {
                          'is-valid': formik.touched.addressLine1 && !formik.errors.addressLine1,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isAgentLoading}
                    />
                    {formik.touched.addressLine1 && formik.errors.addressLine1 && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.addressLine1}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='col-xl-6 col-lg-12 col-md-6 mb-5'>
                    <label className='fw-bold fs-6 mb-2'>AddressLine 2</label>

                    <input
                      placeholder='AddressLine 2'
                      {...formik.getFieldProps('addressLine2')}
                      type='text'
                      name='addressLine2'
                      className={clsx(
                        'form-control form-control-solid mb-lg-0 ps-4',
                        {
                          'is-invalid': formik.touched.addressLine2 && formik.errors.addressLine2,
                        },
                        {
                          'is-valid': formik.touched.addressLine2 && !formik.errors.addressLine2,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isAgentLoading}
                    />
                    {formik.touched.addressLine2 && formik.errors.addressLine2 && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.addressLine2}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row'>
                  <div className='col-xl-6 col-lg-12 col-md-6 mb-5'>
                    <label className='required fw-bold fs-6 mb-2'>State</label>
                    <Field
                      className={clsx(
                        'form-select-solid',
                        {'is-invalid': formik.touched.stateId && formik.errors.stateId},
                        {
                          'is-valid': formik.touched.stateId && !formik.errors.stateId,
                        }
                      )}
                      component={CustomSelect}
                      options={stateList}
                      placeholder='Select State'
                      id='stateId'
                      name='stateId'
                      onChange={(e: {value: any}) => formik.setFieldValue('stateId', e?.value)}
                    ></Field>
                    {formik.touched.stateId && formik.errors.stateId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.stateId}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-xl-6 col-lg-12 col-md-6 mb-5'>
                    <label className='fw-bold fs-6 mb-2'>City</label>
                    <input
                      placeholder='City'
                      {...formik.getFieldProps('city')}
                      type='text'
                      name='city'
                      className={clsx(
                        'form-control form-control-solid mb-lg-0 ps-4',
                        {
                          'is-invalid': formik.touched.city && formik.errors.city,
                        },
                        {
                          'is-valid': formik.touched.city && !formik.errors.city,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isAgentLoading}
                    />
                    {formik.touched.city && formik.errors.city && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.city}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row'>
                  <div className='col-xl-6 col-lg-12 col-md-6 mb-5'>
                    <label className='fw-bold fs-6 mb-2'>ZipCode</label>

                    <input
                      placeholder='zipCode'
                      {...formik.getFieldProps('zipCode')}
                      type='text'
                      name='zipCode'
                      className={clsx(
                        'form-control form-control-solid mb-lg-0 ps-4',
                        {
                          'is-invalid': formik.touched.zipCode && formik.errors.zipCode,
                        },
                        {
                          'is-valid': formik.touched.zipCode && !formik.errors.zipCode,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isAgentLoading}
                    />
                    {formik.touched.zipCode && formik.errors.zipCode && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.zipCode}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-xl-6 col-lg-12 col-md-6 mb-5'>
                    <label className='fw-bold fs-6 mb-2'>CommissionPercent</label>

                    <input
                      placeholder='commissionPercent'
                      {...formik.getFieldProps('commissionPercent')}
                      type='text'
                      name='commissionPercent'
                      className={clsx(
                        'form-control form-control-solid mb-lg-0 ps-4',
                        {
                          'is-invalid':
                            formik.touched.commissionPercent && formik.errors.commissionPercent,
                        },
                        {
                          'is-valid':
                            formik.touched.commissionPercent && !formik.errors.commissionPercent,
                        }
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isAgentLoading}
                    />
                    {formik.touched.commissionPercent && formik.errors.commissionPercent && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.commissionPercent}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row'>
                  <div className='col-xl-6 col-lg-12 col-md-6 mb-5'>
                    <label className='fw-bold fs-6 mb-2'>Date of Birth</label>
                    <div className='w-100 p-calendar-wraper'>
                      <CalendarInput
                        name='dateOfBirth'
                        placeholder='Date of Birth'
                        className='form-control'
                        value={
                          formik.values.dateOfBirth &&
                          !isNaN(Date.parse(formik.values.dateOfBirth as unknown as string))
                            ? new Date(formik.values.dateOfBirth)
                            : null
                        }
                        setFieldValue={formik.setFieldValue}
                      />

                      {formik.touched.dateOfBirth && formik.errors.dateOfBirth && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.dateOfBirth}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='col-xl-6 col-lg-12 col-md-6 mb-5'>
                    <label className='fw-bold fs-6 mb-2'>Date Of Anniversary</label>

                    <div className='w-100 p-calendar-wraper'>
                      <CalendarInput
                        name='dateOfAnniversary'
                        placeholder='Date of Anniversary'
                        className='form-control'
                        value={
                          formik.values.dateOfAnniversary &&
                          !isNaN(Date.parse(formik.values.dateOfAnniversary as unknown as string))
                            ? new Date(formik.values.dateOfAnniversary)
                            : null
                        }
                        setFieldValue={formik.setFieldValue}
                      />

                      {formik.touched.dateOfAnniversary && formik.errors.dateOfAnniversary && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.dateOfAnniversary}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-xl-6 col-lg-12 col-md-6 mb-5'>
                    <label className='fw-bold fs-6 mb-2'>Rate Applicable</label>

                    <Field
                      className='form-select-solid'
                      options={customerType}
                      component={CustomSelect}
                      {...formik.getFieldProps('rateTypeId')}
                    ></Field>
                    {formik.touched.rateTypeId && formik.errors.rateTypeId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.rateTypeId}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-xl-6 col-lg-12 col-md-6 mb-5'>
                    <label className='fw-bold fs-6 mb-2'>Agent Code</label>

                    <input
                      placeholder='Agent Code'
                      {...formik.getFieldProps('agentCode')}
                      type='text'
                      name='agentCode'
                      className={clsx(
                        'form-control form-control-solid mb-lg-0 ps-4',
                        {
                          'is-invalid': formik.touched.agentCode && formik.errors.agentCode,
                        },
                        {
                          'is-valid': formik.touched.agentCode && !formik.errors.agentCode,
                        }
                      )}
                      autoComplete='off'
                    />
                    {formik.touched.agentCode && formik.errors.agentCode && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.agentCode}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row'>
                  <div className='col-xl-6 col-lg-12 col-md-6 mb-5 '>
                    <div className='form-check form-check-custom form-check-solid'>
                      <label
                        className='d-flex align-items-center fs-6 fw-bold  me-4'
                        htmlFor='enableLogin'
                      >
                        Enable Login
                      </label>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='enableLogin'
                        checked={{...formik.getFieldProps('enableLogin')}.value}
                        {...formik.getFieldProps('enableLogin')}
                        disabled={formik.isSubmitting || isAgentLoading}
                      />
                    </div>
                  </div>
                </div>

                {formik.values.enableLogin ? (
                  <div className='row'>
                    <div className='col-xl-6 col-lg-6 col-md-6 mb-5 '>
                      <label className='fw-bold fs-6 mb-2'>UserName</label>

                      <input
                        placeholder='UserName'
                        {...formik.getFieldProps('userName')}
                        type='text'
                        name='userName'
                        className={clsx(
                          'form-control form-control-solid mb-lg-0 ps-4',
                          {
                            'is-invalid': formik.touched.userName && formik.errors.userName,
                          },
                          {
                            'is-valid': formik.touched.userName && !formik.errors.userName,
                          }
                        )}
                        autoComplete='off'
                        disabled={formik.isSubmitting || isAgentLoading}
                      />

                      {formik.touched.userName && formik.errors.userName && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.userName}</span>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='col-xl-6 col-lg-6 col-md-6 mb-5 '>
                      <label className='fw-bold fs-6 mb-2'>Password</label>

                      <div className='input-group'>
                        <input
                          placeholder='password'
                          {...formik.getFieldProps('password')}
                          type={showGeneratedPassword ? 'text' : 'password'}
                          name='password'
                          className={clsx(
                            'form-control form-control-solid mb-lg-0 ps-4',
                            {
                              'is-invalid': formik.touched.password && formik.errors.password,
                            },
                            {
                              'is-valid': formik.touched.password && !formik.errors.password,
                            }
                          )}
                          autoComplete='off'
                          disabled={formik.isSubmitting || isAgentLoading}
                        />
                        <div className='input-group-append'>
                          <span
                            className={clsx(
                              'ki-duotone cursor-pointer position-absolute translate-middle top-50 end-0 me-5 fs-1',
                              showGeneratedPassword ? 'ki-eye-slash' : 'ki-eye'
                            )}
                            onClick={handleTogglePasswordVisibility}
                          >
                            <span className='path1'></span>
                            <span className='path2'></span>
                            <span className='path3'></span>
                          </span>
                        </div>
                      </div>

                      {formik.touched.password && formik.errors.password && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.password}</span>
                          </div>
                        </div>
                      )}

                      <a
                        onClick={handleGeneratePassword}
                        className='d-flex justify-content-end cursor-pointer text-decoration-underline mt-3'
                      >
                        Generate Password
                      </a>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className='card-footer text-end'>
                <button
                  type='reset'
                  className='btn btn-light me-3'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_1'
                  disabled={formik.isSubmitting}
                >
                  Discard
                </button>
                <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                  <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h2>Confirmation</h2>
                        <div
                          className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                          data-bs-dismiss='modal'
                          aria-label='Close'
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr061.svg'
                            className='svg-icon svg-icon-2x'
                          />
                        </div>
                      </div>
                      <div className='modal-body'>
                        <div className='w-100'>
                          {/* <div className="fv-row mb-10 d-flex justify-content-center exclamation fs-14">
												<i className="las la-exclamation-circle"></i>
											</div> */}

                          <div className='fv-row text-center'>
                            <h5>Are you sure you want to discard ?</h5>
                          </div>
                        </div>
                      </div>
                      <div className='modal-footer'>
                        <button
                          type='button'
                          className='btn btn-light'
                          data-bs-dismiss='modal'
                          onClick={() => navigate('/sales/agent/list')}
                        >
                          Yes
                        </button>
                        <button type='button' className='btn btn-primary' data-bs-dismiss='modal'>
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-color-modal-action='submit'
                  // disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                >
                  <span className='indicator-label'>Submit</span>
                  {formik.isSubmitting && (
                    <span className='indicator-progress'>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      {(formik.isSubmitting || isAgentLoading) && <Loading />}
    </FormikProvider>
  )
}

export {AgentAddPage}
