import axios, {AxiosResponse} from 'axios'
import {ID, PaginationFilter} from '../../../../../../_metronic/helpers/crud-helper/models'
import {
  PaymentMadeRecords,
  PurchaseOrder,
  PurchaseReceiveRecord,
  TransactionHistory,
  Vendor,
  VendorBills,
  VendorBillsData,
  VendorExpenses,
  VendorQueryResponse,
  VendorStatementsReport,
} from './_models'
import {FileResult, Result} from '../../../../../core/models/Result'
import {toZonedTime} from 'date-fns-tz'
import {format} from 'date-fns'
const API_URL = process.env.REACT_APP_STORE_API_URL
const Vendor_LIST_URL = `${API_URL}/vendors/search`
const Vendor_BY_ID_URL = `${API_URL}/vendors`
const Vendor_ADD_URL = `${API_URL}/vendors`
const Transaction_History_URL = `${API_URL}/vendors/transactionhistory`
const Vendor_Record_URL = `${API_URL}/vendors/record`
const Bank_List_URL = `${API_URL}/bank/search`
const Purchase_Order_By_Vendor_URL = `${API_URL}/purchaseorders/by-vendor-id`
const Purchase_Receive_By_Vendor_URL = `${API_URL}/purchasereceives/by-vendor-id`
const Purchase_Bill_By_Vendor_URL = `${API_URL}/bills/by-vendor-id`
const Payment_Made_By_Vendor_URL = `${API_URL}/paymentmade/by-vendor-id`
const Vendor_Expenses_By_Vendor_URL = `${API_URL}/expense/by-vendor-id`
const Vendor_Statements_Report_URL = `${API_URL}/vendors/statement`
const Vendor_Statements_Report_Export_URL = `${API_URL}/report/download-vendor-ledger`
const Vendor_Statements_Report_Export_Excel_URL = `${API_URL}/report/download-vendor-ledger-excel`

const getVendorList = async (filter: PaginationFilter): Promise<VendorQueryResponse> => {
  return await axios
    .post(`${Vendor_LIST_URL}`, filter)
    .then((d: AxiosResponse<VendorQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const getVendorById = async (id: ID): Promise<Result | Vendor> => {
  return await axios
    .get(`${Vendor_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<Vendor>) => response.data)
    .then((response: Vendor) => response)
    .catch((err: Result) => {
      return err
    })
}

const getVendorPurchaseOrders = async (id: ID): Promise<Result | PurchaseOrder> => {
  return axios
    .post(`${Purchase_Order_By_Vendor_URL}`, {
      vendorId: id,
    })
    .then((response: AxiosResponse<PurchaseOrder>) => response.data)
    .catch((err: Result) => {
      return err
    })
}

const getVendorPurchaseReceiveById = async (id: ID): Promise<Result | PurchaseReceiveRecord> => {
  return axios
    .post(`${Purchase_Receive_By_Vendor_URL}`, {
      vendorId: id,
    })
    .then((response: AxiosResponse<PurchaseReceiveRecord>) => response.data)
    .catch((err: Result) => {
      return err
    })
}

const getVendorBillsByVendorId = async (id: ID): Promise<Result | VendorBills> => {
  return axios
    .post(`${Purchase_Bill_By_Vendor_URL}`, {
      vendorId: id,
    })
    .then((response: AxiosResponse<VendorBills>) => response.data)
    .catch((err: Result) => {
      return err
    })
}

const getPaymentMadeRecordsByVendorId = async (id: ID): Promise<Result | PaymentMadeRecords> => {
  return axios
    .post(`${Payment_Made_By_Vendor_URL}`, {
      vendorId: id,
    })
    .then((response: AxiosResponse<PaymentMadeRecords>) => response.data)
    .catch((err: Result) => {
      return err
    })
}

const getVendorExpensesByVendorId = async (id: ID): Promise<Result | VendorExpenses> => {
  return axios
    .post(`${Vendor_Expenses_By_Vendor_URL}`, {
      vendorId: id,
    })
    .then((response: AxiosResponse<VendorExpenses>) => response.data)
    .catch((err: Result) => {
      return err
    })
}
const createVendor = async (vendor: Vendor): Promise<Result> => {
  return await axios
    .post(Vendor_ADD_URL, {
      firstName: vendor.firstName?.trim(),
      lastname: vendor.lastName?.trim(),
      companyName: vendor.companyName?.trim(),
      printName: vendor.printName?.trim(),
      alias: vendor.aadharNumber?.trim(),
      // contactNumber: vendor.contactNumber?.trim(),
      faxNumber: vendor.faxNumber?.trim(),
      emailAddress: vendor.emailAddress?.trim(),
      website: vendor.website?.trim(),
      contactPerson: vendor.contactPerson?.trim(),
      mobileNumber: vendor.mobileNumber?.trim(),
      whatsappNumber: vendor.whatsappNumber?.trim(),
      lstNumber: vendor.lstNumber?.trim(),
      cstNumber: vendor.cstNumber?.trim(),
      gstNumber: vendor.gstNumber?.trim(),
      panNumber: vendor.panNumber?.trim(),
      aadharNumber: vendor.aadharNumber?.trim(),
      gstRegistrationTypeId: vendor.gstRegistrationTypeId,
      creditPeriodInDays: vendor.creditPeriodInDays,
      creditLimitInAmount: vendor.creditLimitInAmount,
      discountPercent: vendor.discountPercent,
      applyGST: vendor.applyGST,
      rateId: vendor.rateId,
      isInternal: vendor.isInternal,
      tenantTypeId: vendor.tenantTypeId,
      linkedTenantId: vendor.linkedTenantId,
      billingAddress: vendor.billingAddress,
      dateOfBirth: vendor.dateOfBirth,
      dateOfAnniversary: vendor.dateOfAnniversary,
      shippingAddress: vendor.shippingAddress,
      placeOfSupplyId: vendor.placeOfSupplyId,
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const updateVendor = (vendor: Vendor): Promise<Result> => {
  return axios
    .put(`${Vendor_BY_ID_URL}/${vendor.id}`, {
      id: vendor.id,
      firstName: vendor.firstName?.trim(),
      lastname: vendor.lastName?.trim(),
      companyName: vendor.companyName?.trim(),
      printName: vendor.printName?.trim(),
      alias: vendor.aadharNumber?.trim(),
      // contactNumber: vendor.contactNumber?.trim(),
      faxNumber: vendor.faxNumber?.trim(),
      emailAddress: vendor.emailAddress?.trim(),
      website: vendor.website?.trim(),
      contactPerson: vendor.contactPerson?.trim(),
      mobileNumber: vendor.mobileNumber?.trim(),
      whatsappNumber: vendor.whatsappNumber?.trim(),
      lstNumber: vendor.lstNumber?.trim(),
      cstNumber: vendor.cstNumber?.trim(),
      gstNumber: vendor.gstNumber?.trim(),
      panNumber: vendor.panNumber?.trim(),
      aadharNumber: vendor.aadharNumber?.trim(),
      gstRegistrationTypeId: vendor.gstRegistrationTypeId,
      creditPeriodInDays: vendor.creditPeriodInDays,
      creditLimitInAmount: vendor.creditLimitInAmount,
      discountPercent: vendor.discountPercent,
      applyGST: vendor.applyGST,
      rateId: vendor.rateId,
      isInternal: vendor.isInternal,
      tenantTypeId: vendor.tenantTypeId,
      linkedTenantId: vendor.linkedTenantId,
      billingAddress: vendor.billingAddress,
      dateOfBirth: vendor.dateOfBirth,
      dateOfAnniversary: vendor.dateOfAnniversary,
      shippingAddress: vendor.shippingAddress,
      isActive: vendor.isActive,
      placeOfSupplyId: vendor.placeOfSupplyId,
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}

const deleteVendor = async (id: ID): Promise<Result> => {
  return axios
    .delete(`${Vendor_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const getRecordById = async (id: ID): Promise<Result | Vendor> => {
  return axios
    .get(`${Vendor_Record_URL}/${id}`)
    .then((response: AxiosResponse<Vendor>) => response.data)
    .then((response: Vendor) => response)
    .catch((err: Result) => {
      return err
    })
}

const transactionHistory = async (id: ID): Promise<Result | TransactionHistory> => {
  return axios
    .get(`${Transaction_History_URL}/${id}`)
    .then((response: AxiosResponse<TransactionHistory>) => response.data)
    .then((response: TransactionHistory) => response)
    .catch((err: Result) => {
      return err
    })
}

const getVendorStatementsReport = async (
  id: ID,
  fromDate: string,
  toDate: string
): Promise<Result | VendorStatementsReport> => {
  return axios
    .post(`${Vendor_Statements_Report_URL}`, {
      vendorId: Number(id),
      startDate: fromDate,
      endDate: toDate,
    })
    .then((response: AxiosResponse<VendorStatementsReport>) => response.data)
    .catch((err: Result) => {
      return err
    })
}

const exportVendorStatementPDF = async (
  id: ID,
  fromDate: string,
  toDate: string
): Promise<Result> => {
  return axios
    .post(
      `${Vendor_Statements_Report_Export_URL}`,
      {
        vendorId: Number(id),
        startDate: fromDate,
        endDate: toDate,
      },
      {
        responseType: 'blob',
      }
    )
    .then(async (response: any) => {
      if (response.type == 'application/json') {
        const jsonData = await response.text()
        const errorData = JSON.parse(jsonData)
        return errorData
      } else {
        let filename = response.headers['content-disposition']
          .split(';')
          .find((n: any) => n.includes('filename='))
          .replace('filename=', '')
          .trim()

        var result: FileResult = {
          data: response.data,
          name: filename,
        }
        return result
      }
    })
    .catch((err: Result) => {
      return err
    })
}

const exportVendorStatementExcel = async (
  id: ID,
  fromDate: string,
  toDate: string
): Promise<Result> => {
  return axios
    .post(
      `${Vendor_Statements_Report_Export_Excel_URL}`,
      {
        vendorId: Number(id),
        startDate: fromDate,
        endDate: toDate,
      },
      {
        responseType: 'blob',
      }
    )
    .then(async (response: any) => {
      if (response.type == 'application/json') {
        const jsonData = await response.text()
        const errorData = JSON.parse(jsonData)
        return errorData
      } else {
        let filename = response.headers['content-disposition']
          .split(';')
          .find((n: any) => n.includes('filename='))
          .replace('filename=', '')
          .trim()

        var result: FileResult = {
          data: response.data,
          name: filename,
        }
        return result
      }
    })
    .catch((err: Result) => {
      return err
    })
}

export {
  getVendorList,
  getVendorById,
  createVendor,
  updateVendor,
  deleteVendor,
  getRecordById,
  transactionHistory,
  getVendorPurchaseOrders,
  getVendorPurchaseReceiveById,
  getVendorBillsByVendorId,
  getPaymentMadeRecordsByVendorId,
  getVendorExpensesByVendorId,
  getVendorStatementsReport,
  exportVendorStatementPDF,
  exportVendorStatementExcel,
}
