import axios, { AxiosResponse } from 'axios'
import { ID, PaginationFilter } from '../../../../../_metronic/helpers/crud-helper/models'
import { BankAccount, BankAccountQueryResponse, BankAccountStatementsReport, CreateBankAccountPayload } from './_models'
import { Result } from '../../../../core/models/Result'

const API_URL = process.env.REACT_APP_STORE_API_URL
const ACCOUNT_BY_ID_URL = `${API_URL}/bank`
const ACCOUNT_LIST_URL = `${API_URL}/bank/search`
const ACCOUNT_ADD_URL = `${API_URL}/bank`
const Bank_Statements_Report_URL = `${API_URL}/bank/bank-transaction`

const getBankAccountList = async (filter: PaginationFilter): Promise<BankAccountQueryResponse> => {
  return await axios
    .post(`${ACCOUNT_LIST_URL}`, filter)
    .then((d: AxiosResponse<BankAccountQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}
const getBankAccountById = async (id: ID): Promise<Result | BankAccount> => {
  return await axios
    .get(`${ACCOUNT_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<BankAccount>) => response.data)
    .then((response: BankAccount) => response)
    .catch((err: Result) => {
      return err
    })
}

const getBankAccountStatementsReport = async (
  id: ID,
  fromDate: string,
  toDate: string
): Promise<Result | BankAccountStatementsReport> => {
  return axios
    .post(`${Bank_Statements_Report_URL}`, {
      vendorId: Number(id),
      startDate: fromDate,
      endDate: toDate,
    })
    .then((response: AxiosResponse<BankAccountStatementsReport>) => response.data)
    .catch((err: Result) => {
      return err
    })
}

const createNewBankAccount = async (bankaccount: CreateBankAccountPayload): Promise<Result> => {
  return await axios.post(ACCOUNT_ADD_URL, bankaccount)
  .then((response: AxiosResponse<Result>) => response.data)
  .then((response: Result   ) => response)
  .catch((err: Result) => {
      return err
    })
}


const updateExistingBankAccount = async (bankaccount: CreateBankAccountPayload): Promise<Result> => {
  return await axios.put(`${ACCOUNT_BY_ID_URL}/${bankaccount.id}`, bankaccount)
  .then((response: AxiosResponse<Result>) => response.data)
  .then((response: Result   ) => response)
  .catch((err: Result) => {
      return err
    })
}


export {
  getBankAccountList, getBankAccountById, createNewBankAccount, updateExistingBankAccount, getBankAccountStatementsReport
}


