import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { EmailTemplateWrapper } from './pages/EmailTemplateList'

const Breadcrumbs: Array<PageLink> = [
    {
        title: 'Home',
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const EmailTemplatePage = () => {
    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='list'
                    index
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Email Template </PageTitle>
                            <EmailTemplateWrapper />
                        </>
                    }
                />

                {/* <Route
                    path='/add'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Account List</PageTitle>
                            <AccountModalWrapper />

                        </>
                    }
                /> */}

            </Route>
        </Routes>
    )
}

export default EmailTemplatePage
