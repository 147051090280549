import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FileUploadRequest } from '../../../../core/models/FileUploadRequest'
import * as Yup from "yup";
import { Field, FormikProvider, useFormik } from 'formik';
import { KTSVG, PaginationFilter, isNotEmpty } from '../../../../../_metronic/helpers';
import { Result } from '../../../../core/models/Result';
import { toast } from 'react-toastify';
import { camelize } from '../../../../core/utils/StringHelpers';
import UploadImage from '../../../../core/shared/components/UploadImage';
import clsx from 'clsx';
import CustomSelect from '../../../../core/shared/components/CustomSelect';
import { SelectOptionProps } from '../../../../core/models/SelectOptionProps';
import { Testimonial, initialTestimonial } from '../core/_models';
import { createTestimonial } from '../core/_requests';

const paginationFilter: PaginationFilter = {
  pageNumber: 1,
  pageSize: 500,


};

const TestimonialAddPage = () => {

  const API_URL = process.env.REACT_APP_IMAGE_API_URL
  const [selectedImage, setSelectedImage] = useState<FileUploadRequest>({})
  const [pageList, setPageList] = useState<SelectOptionProps[]>([])
  const [sectionList, setSectionList] = useState<SelectOptionProps[]>([])


  const navigate = useNavigate()

  const testimonialSchema = Yup.object().shape({
    testimonialImagePath: Yup.string().required('Please upload Home Page Banner image'),
    name: Yup.string()
      .matches(/^[A-Za-z0-9\-_', .]*$/, "Only alphanumeric, _, -, ' and space are allowed for this field")
      .required("Please enter First name"),
    description: Yup.string().required('Please provide proper description'),
    designation: Yup.string().required('Please provide proper designation'),

  })

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: initialTestimonial,
    validationSchema: testimonialSchema,

    onSubmit: async (formValues, { setSubmitting, setFieldError }) => {
       
      setSubmitting(true)
      let testimonialData: Testimonial = {
        id: formValues.id,
        name: formValues.name,
        description: formValues.description,
        designation: formValues.designation


      }
      if (isNotEmpty(selectedImage.data)) {

        testimonialData.uploadImage = selectedImage;

      }
      try {
        let result: Result;

        result = await createTestimonial(testimonialData)
        if (result.hasOwnProperty('succeeded') && result?.succeeded) {
          setSubmitting(true)
          toast.success("Testimonial added successfully.")
          navigate('/cms/testimonial/list')

        }
        else {
          if (result.statusCode === 400) {
            result.propertyResults.map((error) =>
              setFieldError(camelize(error.propertyName), error.errorMessage),
              toast.error(result.exception)

            )
          }
        }

      } catch (ex) {
        console.error(ex)
        toast.error("Error caught during adding testimonial.")
      }
    },
  })



  return (
    <FormikProvider value={formik}>
      <form
        id='banner_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate

      >
        <div className="card mb-5 mb-md-5">
          <div className="card-header">

            <div className="card-title">
              <h3 className="fw-bold">Testimonial</h3>
            </div>

          </div>

          
            <div className="card-body">
              <div className="row mb-5">
                <label className="col-xxl-2 col-xl-3 col-lg-3 col-md-3 col-form-label fw-semibold fs-6">
                  Author Image
                </label>

                <div className="col-xxl-6 col-xl-6 col-lg-9 col-md-9">
                  <input type="hidden" {...formik.getFieldProps('testimonialImagePath')}></input>

                  <UploadImage
                    // ImagePath={`${bannerData.imagePath}`}
                    HandleUpload={(img: FileUploadRequest) => {
                      setSelectedImage(img)
                      formik.setFieldValue('testimonialImagePath', img.name)

                    }}
                    HandleRemove={() => { setSelectedImage({}) }} />
                    <div className='form-text'>Allowed file types: png, jpg, jpeg.</div>
                  {formik.touched.testimonialImagePath && formik.errors.testimonialImagePath && (
                    <div className='fv-help-block text-center'>
                      <span role='alert ' className="text-danger">{formik.errors.testimonialImagePath}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mb-md-5">
                <label className="col-xxl-2 col-xl-3 col-lg-3 col-md-3 col-form-label fw-semibold fs-6">
                  Name
                </label>

                <div className="col-xxl-6 col-xl-6 col-lg-9 col-md-9">                

                      <Field
                        type='text'
                        className={clsx(
                          'form-control form-control-solid mb-lg-0',
                          { 'is-invalid': formik.touched.name && formik.errors.name },
                          {
                            'is-valid': formik.touched.name && !formik.errors.name,
                          }
                        )}
                        placeholder='Name'
                        {...formik.getFieldProps('name')}
                        name='name'
                      />
                      {formik.touched.name && formik.errors.name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.name}</span>
                          </div>
                        </div>
                      )}
                      <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />                   
                  
                </div>

              </div>

              <div className="row mb-md-5">

                <label className="col-xxl-2 col-xl-3 col-lg-3 col-md-3 col-form-label fw-semibold fs-6">
                  Designation
                </label>

                <div className="col-xxl-6 col-xl-6 col-lg-9 col-md-9">                

                      <Field
                        type='text'
                        className={clsx(
                          'form-control form-control-solid mb-lg-0',
                          { 'is-invalid': formik.touched.designation && formik.errors.designation },
                          {
                            'is-valid': formik.touched.designation && !formik.errors.designation,
                          }
                        )}
                        placeholder='Designation'
                        {...formik.getFieldProps('designation')}
                        name='designation'
                      />
                      {formik.touched.designation && formik.errors.designation && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.designation}</span>
                          </div>
                        </div>
                      )}
                      <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />                  
                  
                </div>

              </div>

              <div className="row mb-md-5">

                <label className="col-xxl-2 col-xl-3 col-lg-3 col-md-3 col-form-label fw-semibold fs-6">
                  Description
                </label>

                <div className="col-xxl-6 col-xl-6 col-lg-9 col-md-9">  
                    <textarea
                      className='form-control form-control-solid mb-lg-0'
                      rows={4}
                      id="description"
                      {...formik.getFieldProps('description')}
                      name="description"
                    />

                    {formik.touched.description && formik.errors.description && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.description}</span>
                        </div>
                      </div>
                    )}
                    <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />                  
                </div>
              </div>              
            </div>
            <div className="card-footer text-end">
            <button
                  type='reset'
                  className='btn btn-light me-3'
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_1"
                  disabled={formik.isSubmitting}
                >
                  Discard
                </button>
                <div className="modal fade" tabIndex={-1} id="kt_modal_1">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h2>Confirmation</h2>
                        <div
                          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          <KTSVG
                            path="/media/icons/duotune/arrows/arr061.svg"
                            className="svg-icon svg-icon-2x"
                          />
                        </div>
                      </div>
                      <div className="modal-body">
                        <div className="w-100">

                          {/* <div className="fv-row mb-10 d-flex justify-content-center exclamation fs-14">
												<i className="las la-exclamation-circle"></i>
											</div> */}

                          <div className="fv-row text-center">
                            <h5>Are you sure you want to discard ?</h5>
                          </div>

                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-light"
                          data-bs-dismiss="modal"
                          onClick={() => navigate('/cms/testimonial/list')}
                        >
                          Yes
                        </button>
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal">
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-color-modal-action='submit'
                  disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                >
                  <span className='indicator-label'>Submit</span>
                  {(formik.isSubmitting) && (
                    <span className='indicator-progress'>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
            </div>
          
        </div>
      </form>
    </FormikProvider>
  )

}

export default TestimonialAddPage