import { ID, Response } from '../../../../../_metronic/helpers/crud-helper/models';
export interface Collection {
    id?: ID
    name?:''    
      pageId?: number,
      pageName?: string,
      sectionId?: number,
      sectionName?: string,
      isActive?: boolean,
      statusId?:number
      productGroupIds?:number[]
      productGroupName?:string
      productGroupCollections?:productGroupCollections
   
}
export type CollectionQueryResponse = Response<Array<Collection>>

export type productGroupCollections = [{
    
        id?: number,
        // collectionId?: number,
        // collectionName?: string,
        productGroupId?: number,
        productGroupName?: string,
        imagePath?: string,
        spSemiWholeSeller?: number,
        spRetail?: number,
        spWholeSeller?: number

}
]

export const initialCollection: Collection = {
    name:'',
    pageId: undefined,
      pageName: '',
      sectionId: undefined,
      sectionName: '',
      isActive: false,
      statusId:0,
      productGroupIds:undefined
   
}


