import { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import { FileUploadRequest } from './FileUploadRequest';

interface ImageContextProps {
  imageSelected: FileUploadRequest
  setImage: (imageData: FileUploadRequest) => void;
 
}

const ImageContext = createContext<ImageContextProps | undefined>(undefined);

export const ImageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

  const [imageSelected, setImageSelected] = useState<FileUploadRequest>({
    name: undefined,
    extension: undefined,
    data: undefined,
  });

  const setImage = (imageData: FileUploadRequest) => {
    setImageSelected(imageData);
   

  };



  return (
    <ImageContext.Provider value={{ imageSelected, setImage }}>
      {children}
    </ImageContext.Provider>
  );
};

export const useImage = (): ImageContextProps => {
  const context = useContext(ImageContext);
  if (!context) {
    throw new Error('useImage must be used within an ImageProvider');
  }
  return context;
};
