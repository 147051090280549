import axios, {AxiosResponse} from 'axios'
import {
  AvailabilityTypes,
  ProductGroup,
  ProductGroupQueryResponse,
  updateProductGroupPricesRequests,
} from './_models'

import {ID, PaginationFilter} from '../../../../../../_metronic/helpers/crud-helper/models'
import {Result} from '../../../../../core/models/Result'
const API_URL = process.env.REACT_APP_STORE_API_URL
const Product_BY_ID_URL = `${API_URL}/productgroup`
const Product_LIST_URL = `${API_URL}/productgroup/search`
const Product_ADD_URL = `${API_URL}/productgroup`
const Price_LIST_URL = `${API_URL}/productgroup/updatepricelist`
const Product_GROUP_AVAILABILITY_TYPES = `${API_URL}/referencedata/get-availability-type`

const getProductGroupList = async (
  filter: PaginationFilter
): Promise<ProductGroupQueryResponse> => {
  return await axios
    .post(`${Product_LIST_URL}`, filter)
    .then((d: AxiosResponse<ProductGroupQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}
const getProductGroupById = async (id: ID): Promise<Result | ProductGroup> => {
  return axios
    .get(`${Product_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<ProductGroup>) => response.data)
    .then((response: ProductGroup) => response)
    .catch((err: Result) => {
      return err
    })
}

const getAvailabilityTypes = async (): Promise<AvailabilityTypes[]> => {
  return axios
    .get(`${Product_GROUP_AVAILABILITY_TYPES}`)
    .then((response: AxiosResponse<AvailabilityTypes[]>) => response.data)
    .catch((err) => {
      return err
    })
}
const createProductGroup = async (productgroup: ProductGroup): Promise<Result> => {
  return await axios
    .post(Product_ADD_URL, {
      name: productgroup.name?.trim(),
      description: productgroup.description?.trim(),
      categoryId: productgroup.categoryId,
      productGroupImage: productgroup.productGroupImage,
      spSemiWholeSeller: productgroup.spSemiWholeSeller,
      spRetail: productgroup.spRetail,
      spWholeSeller: productgroup.spWholeSeller,
      retailMoQ: productgroup.retailMoQ,
      wholeSellerMoQ: productgroup.wholeSellerMoQ,
      semiWholeSellerMoQ: productgroup.semiWholeSellerMoQ,
      brandId: productgroup.brandId,
      manufacturerId: productgroup.manufacturerId,
      secondaryCategory: productgroup.secondaryCategory,
      designNumberId: productgroup.designNumberId,
      purchasePrice: productgroup.purchasePrice,
      vendorId: productgroup.vendorId,
      availabilityTypeId: productgroup?.availabilityTypeId,
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .then((response: Result) => response)
    .catch((err: Result) => {
      return err
    })
}

const updateProductGroup = (productgroup: ProductGroup): Promise<Result> => {
  return axios
    .put(`${Product_BY_ID_URL}/${productgroup.id}`, {
      id: productgroup.id,
      name: productgroup.name?.trim(),
      description: productgroup.description?.trim(),
      categoryId: productgroup.categoryId,
      productGroupImage: productgroup.productGroupImage,
      imagePath: productgroup.imagePath,
      isActive: productgroup.isActive,
      spSemiWholeSeller: productgroup.spSemiWholeSeller,
      spRetail: productgroup.spRetail,
      spWholeSeller: productgroup.spWholeSeller,
      retailMoQ: productgroup.retailMoQ,
      wholeSellerMoQ: productgroup.wholeSellerMoQ,
      semiWholeSellerMoQ: productgroup.semiWholeSellerMoQ,
      brandId: productgroup.brandId,
      manufacturerId: productgroup.manufacturerId,
      secondaryCategory: productgroup.secondaryCategory,
      designNumberId: productgroup.designNumberId,
      purchasePrice: productgroup.purchasePrice,
      vendorId: productgroup.vendorId,
      availabilityTypeId: productgroup?.availabilityTypeId,
    })
    .then((response: AxiosResponse<Result>) => response.data)
    .catch((err: Result) => {
      return err
    })
}
const deleteProductGroup = async (id: ID): Promise<Result | ProductGroup> => {
  return axios
    .delete(`${Product_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<ProductGroup>) => response.data)
    .then((response: ProductGroup) => response)
    .catch((err: Result) => {
      return err
    })
}

const updatePrice = (price: updateProductGroupPricesRequests[]): Promise<Result> => {
  return axios.put(`${Price_LIST_URL}`, {updateProductGroupPricesRequests: price})
}
export {
  getProductGroupById,
  getProductGroupList,
  createProductGroup,
  updateProductGroup,
  updatePrice,
  deleteProductGroup,
  getAvailabilityTypes,
}
