
import { useEffect, useState } from 'react'
import { KTCard, KTCardBody, KTIcon, PaginationFilter } from '../../../../../_metronic/helpers'
import { StaffListHeader } from '../../staff/staff-list/components/header/StaffListHeader'
import { useListView, ListViewProvider } from '../core/ListViewProvider'
import { QueryRequestProvider } from '../core/QueryRequestProvider'
import { QueryResponseProvider } from '../core/QueryResponseProvider'
import { EmailTemplate } from '../core/_models'
import { getEmailTemplateList } from '../core/_requests'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { EmailTemplateModal } from './EmailTemplateModal'
import { MustHavePermission, SWSection, SWResource, SWAction } from '../../../../core/shared/core/permissionUtils'


const paginationFilter: PaginationFilter = {
  pageNumber: 1,
  pageSize: 500,
}
const EmailTemplateList = () => {
  const { itemIdForUpdate } = useListView()
  const [emailList, setEmailList] = useState<EmailTemplate[]>([])
  useEffect(() => {
    getEmailTemplateList(paginationFilter).then((data) => {
      let result = data.data as EmailTemplate[]
      setEmailList(result)
    })
  }, [])
  const { setItemIdForUpdate } = useListView()
  const openAddAccountModal = () => {
    setItemIdForUpdate(null)
  }

  const openEditAccountModal = (id: any) => {
    setItemIdForUpdate(id)
  }


  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="actions">
        <a
          href='#'
          className='btn btn-light btn-active-light-primary btn-sm '
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          onClick={() => { openEditAccountModal(rowData.id) }}
        >
          <span className="ki-duotone ki-pencil" >
            <span className="path1"></span>
            <span className="path2"></span >
          </span>

        </a>
      </div>
    );
  };
  return (
    <>
      <KTCard>
        <KTCardBody>
          <>
            <div className="d-flex justify-content-end mt-4">
              <div className="m-0">
              </div>
              {MustHavePermission(SWSection.Store, SWResource.EmailTemplates, SWAction.Create) ?
                <button type='button' className='btn btn-primary' onClick={openAddAccountModal}>
                  <KTIcon iconName='plus' className='fs-2' />
                  Add
                </button>
                : null}
            </div>
            <div>
              <DataTable

                className='table align-middle table-row-dashed gy-5 dataTable no-footer'
                value={emailList}
                tableStyle={{ minWidth: '75rem' }}
                breakpoint='960px'

              >
                <Column

                  className='text-gray-600 fw-bold'
                  field='templateName'
                  header={
                    <div className='text-start text-muted fw-bolder fs-5 gs-0 min-w-125px'>
                      Email Name
                    </div>
                  }
                  sortable
                  style={{ minWidth: '6rem' }}
                />
                <Column
                  className='text-gray-600 fw-bold'

                  field='from'
                  header={
                    <div className='text-start text-muted fw-bolder fs-5 gs-0 min-w-125px'>
                      From
                    </div>
                  }
                  sortable
                />

                <Column
                  className='text-gray-600 fw-bold'
                  field='cc'
                  header={
                    <div className='text-start text-muted fw-bolder fs-5 gs-0 min-w-125px'>
                      cc
                    </div>
                  }
                  sortable
                />

                <Column
                  className='text-gray-600 fw-bold'
                  field='subject'
                  header={
                    <div className='text-start text-muted fw-bolder fs-5 gs-0 min-w-125px'>
                      Subject
                    </div>
                  }
                  sortable
                /> 
                {MustHavePermission(SWSection.Store, SWResource.EmailTemplates, SWAction.Update) ?
                  <Column field="" header="Actions" body={actionBodyTemplate} /> : null}
              </DataTable>
            </div>
          </>
        </KTCardBody>
      </KTCard>

      {itemIdForUpdate !== undefined && <EmailTemplateModal />}
    </>
  )
}

const EmailTemplateWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <EmailTemplateList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { EmailTemplateWrapper }
