import { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Field, FormikProvider, useFormik } from 'formik'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { Toast } from 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify'
import { EmailTemplate } from '../core/_models'
import { Result } from '../../../../core/models/Result'
import { PaginationFilter, isNotEmpty } from '../../../../../_metronic/helpers'
import { camelize } from '../../../../core/utils/StringHelpers'
import { createEmailTemplate, updateEmailTemplate } from '../core/_requests'
import { StatusOptions } from '../../../../core/models/StatusOptions'
import CustomSelect from '../../../../core/shared/components/CustomSelect'
import { Loading } from '../../../../core/shared/components/Loading'
import { SelectOptionProps } from '../../../../core/models/SelectOptionProps'
import { getAccountGroupList } from '../../account-group/core/_requests'
// import { AccountGroup } from '../../account-group/core/_models'
type Props = {
  isEmailTemplateLoading: boolean
  data: EmailTemplate | Result
}

const paginationFilter: PaginationFilter = {
  pageNumber: 1,
  pageSize: 500,

}

const editEmailSchema = Yup.object().shape({
  templateName: Yup.string().required('Please enter template Name.'),
  from: Yup.string().required('Please enter from value.'),
  cc: Yup.string().required('Please enter cc value.'),
  bcc: Yup.string().required('Please enter bcc value.'),
  subject: Yup.string().required('Please enter subject value.'),
  content: Yup.string().required('Please enter content value.'),
  setThisToDefault: Yup.string().required('Please select for defualt email .'),
  emailTypeId: Yup.string().required('Please enter email type.')

})

const EmailTemplateForm: FC<Props> = ({ data, isEmailTemplateLoading }) => {
  const { setItemIdForUpdate, itemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()
  let emailTemplate = data as EmailTemplate;
  const [initialValues] = useState<EmailTemplate>({
    ...emailTemplate,
    id: emailTemplate.id,
    templateName: emailTemplate.templateName,
    from: emailTemplate.from,
    cc: emailTemplate.cc,
    bcc: emailTemplate.bcc,
    subject: emailTemplate.subject,
    content: emailTemplate.content,
    setThisToDefault: emailTemplate.setThisToDefault,
    emailTypeId: emailTemplate.emailTypeId

  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: editEmailSchema,

    onSubmit: async (emailtemplate, { setSubmitting, setFieldError }) => {
      setSubmitting(true)
      try {
        let result: Result
        if (isNotEmpty(emailtemplate.id)) {

          result = await updateEmailTemplate(emailtemplate)
        } else {
          result = await createEmailTemplate(emailtemplate)
        }
        if (result.hasOwnProperty('succeeded') && result?.succeeded) {
          setSubmitting(true)
          toast.success('Email Template created successfully');
          cancel(true)
        }
        else {
          toast.error('Error Occurred while updating Email template.');
          if (result.statusCode === 400) {
            result.propertyResults.map((error) =>
              setFieldError(camelize(error.propertyName), error.errorMessage)
            )
          }
        }
      } catch (ex) {

        toast.error('Error Occurred .');
        console.error(ex)
      }
    },
  })



  return (

    <FormikProvider value={formik}>
      <form
        id='kt_modal_add_emailtemplate_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div
          className='d-flex flex-column'
          id='kt_modal_add_emailtemplate_scroll'
          data-kt-scroll='true'
        >
          <div className='row mb-7'>
            <div className="col-6 col-md-6 mb-4">
              <label className='required fw-bold fs-6 mb-2 ms-1'>Template Name</label>
              <input
                placeholder='template Name'
                {...formik.getFieldProps('templateName')}
                type='text'
                name='templateName'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0 ms-1',
                  { 'is-invalid': formik.touched.templateName && formik.errors.templateName },
                  {
                    'is-valid': formik.touched.templateName && !formik.errors.templateName,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isEmailTemplateLoading}
              />
              {formik.touched.templateName && formik.errors.templateName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.templateName}</span>
                  </div>
                </div>
              )}
            </div>
            <div className="col-6 col-md-6 mb-4">
              <label className='required fw-bold fs-6 mb-2 ms-1'>From</label>
              <input
                placeholder='From'
                {...formik.getFieldProps('from')}
                type='text'
                name='from'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0 ms-1',
                  { 'is-invalid': formik.touched.from && formik.errors.from },
                  {
                    'is-valid': formik.touched.from && !formik.errors.from,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isEmailTemplateLoading}
              />
              {formik.touched.from && formik.errors.from && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.from}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-7'>
            <div className='col-6 col-md-6 mb-4'>
              <label className="d-flex align-items-center fs-6 fw-bold mb-2 required ms-1">CC</label>
              <input
                placeholder='cc'
                {...formik.getFieldProps('cc')}
                type='text'
                name='cc'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0 ms-1',
                  { 'is-invalid': formik.touched.cc && formik.errors.cc },
                  {
                    'is-valid': formik.touched.cc && !formik.errors.cc,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isEmailTemplateLoading}
              />
              {formik.touched.cc && formik.errors.cc && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.cc}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='col-6 col-md-6 mb-4'>
              <label className='fw-bold fs-6 mb-2 ms-1 required'> BCC</label>
              <input
                placeholder='bcc'
                {...formik.getFieldProps('bcc')}
                type='text'
                name='bcc'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0 ms-1',
                  { 'is-invalid': formik.touched.bcc && formik.errors.bcc },
                  {
                    'is-valid': formik.touched.bcc && !formik.errors.bcc,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isEmailTemplateLoading}
              />

              {formik.touched.bcc && formik.errors.bcc && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.bcc}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-7'>
            <div className='col-6 col-md-6 mb-4'>
              <label className="d-flex align-items-center fs-6 fw-bold mb-2 required ms-1">Subject</label>
              <input
                placeholder='subject'
                {...formik.getFieldProps('subject')}
                type='text'
                name='subject'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0 ms-1',
                  { 'is-invalid': formik.touched.subject && formik.errors.subject },
                  {
                    'is-valid': formik.touched.subject && !formik.errors.subject,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isEmailTemplateLoading}
              />
              {formik.touched.subject && formik.errors.subject && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.subject}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='col-6 col-md-6 mb-4'>
              <label className='fw-bold fs-6 mb-2 ms-1 required'> Email Type</label>
              <input
                placeholder='emailTypeId'
                {...formik.getFieldProps('emailTypeId')}
                type='text'
                name='emailTypeId'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0 ms-1',
                  { 'is-invalid': formik.touched.emailTypeId && formik.errors.emailTypeId },
                  {
                    'is-valid': formik.touched.emailTypeId && !formik.errors.emailTypeId,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isEmailTemplateLoading}
              />

              {formik.touched.emailTypeId && formik.errors.emailTypeId && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.emailTypeId}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-7'>
            <div className='col-6 col-md-6 mb-4'>
              <label className='fw-bold fs-6 mb-2 ms-1'> Default Email</label>
              <input
                placeholder='setThisToDefault'
                {...formik.getFieldProps('setThisToDefault')}
                type='text'
                name='setThisToDefault'
                className={clsx(
                  'form-control form-control-solid mb-3 mb-lg-0 ms-1',
                  { 'is-invalid': formik.touched.setThisToDefault && formik.errors.setThisToDefault },
                  {
                    'is-valid': formik.touched.setThisToDefault && !formik.errors.setThisToDefault,
                  }
                )}
                autoComplete='off'
                disabled={formik.isSubmitting || isEmailTemplateLoading}
              />

            </div>
          </div>

          <div className='row mb-7'>
            <label className='fw-bold fs-6 mb-2 ms-1'> Content</label>
            <textarea
              rows={3}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.content && formik.errors.content },
                {
                  'is-valid': formik.touched.content && !formik.errors.content,
                }
              )}
              disabled={formik.isSubmitting || isEmailTemplateLoading}
              placeholder='content'
              {...formik.getFieldProps('content')}
              name='content'
            />
            {formik.touched.content && formik.errors.content && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.content}</span>
                </div>
              </div>
            )}
          </div>

          <div className='text-center pt-15'>
            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light me-3'
              data-kt-regions-modal-action='cancel'
              disabled={formik.isSubmitting || isEmailTemplateLoading}
            >
              Discard
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-regions-modal-action='submit'
              disabled={isEmailTemplateLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              <span className='indicator-label'>Submit</span>
              {(formik.isSubmitting || isEmailTemplateLoading) && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>

      </form>
      {(formik.isSubmitting || isEmailTemplateLoading) && <Loading />}
    </FormikProvider >
  )
}

export { EmailTemplateForm }
