import {FC} from 'react'

type Props = {
  isActive?: boolean
}

const StatusCell: FC<Props> = ({isActive}) => (<>

  
 {isActive? (<div className='badge fw-bolder badge-light-success text-center'>Active</div> ): <div className='badge fw-bolder badge-light-danger'>Inactive</div>}</>
)

export {StatusCell}