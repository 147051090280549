
import { useField } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const CustomDatePicker = ({ name = "" }) => {
  const [field, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;

  return (
    <DatePicker
      className="form-control w-100"
      {...field}
      selected={value}
      onChange={(date) => setValue(date)}
      dateFormat="dd/MM/yyyy"
      placeholderText="dd/mm/yyyy"
    
    />
  );
};
export default CustomDatePicker