import {lazy, FC, Suspense} from 'react'
import {Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../../_metronic/helpers'
import {StockRoutes} from './stockReports/StockRoutes'
import {LowStockRoutes} from './low-stock/LowStockRoutes'

const ReportRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route
          path='stocks/*'
          element={
            <SuspensedView>
              <StockRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='lowstock/*'
          element={
            <SuspensedView>
              <LowStockRoutes />
            </SuspensedView>
          }
        />       
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })

  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {ReportRoutes}
