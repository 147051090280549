import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { ProductGroupListWrapper } from './productgroup-list/ProductGroupList'
import { ProductGroupAddPage } from './productgroup-list/productgroup-page/ProductGroupAddPage'
import { ProductGroupEditWrapper } from './productgroup-list/productgroup-page/ProductGroupEditWrapper'
import { MustHavePermission, SWSection, SWResource, SWAction } from '../../../core/shared/core/permissionUtils'
import PriceList from '../priceList/PriceList'
import UnAuthorized from '../../errors/components/UnAuthorized'


const Breadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProductPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Product Group</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.ProductGroups, SWAction.Search) ?
              <ProductGroupListWrapper />:<UnAuthorized/>}
            </>
          }
        />

        {/* to navigate to edit page */}
        <Route
          path='edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Edit Product Group</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.ProductGroups, SWAction.Update) ?
              <ProductGroupEditWrapper />
              :<UnAuthorized/>}
            </>
          }
        />
        {/* to navigate to add page */}
        <Route
          path='/add'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Add Product Group</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.ProductGroups, SWAction.Create) ?
              <ProductGroupAddPage isProductLoading={false} />
              :<UnAuthorized/>}

            </>
          }
        />

        <Route
          path='/pricelist'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Price List</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.ProductGroups, SWAction.Search) ?
              <PriceList />:<UnAuthorized/>}

            </>
          }
        />

      </Route>
      <Route index element={<Navigate to='/catalog/productgroup/list' />} />
    </Routes>
  )
}

export default ProductPage
