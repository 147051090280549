import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {Field, FormikProvider, useFormik} from 'formik'
import clsx from 'clsx'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {KTCard, KTCardBody, PaginationFilter} from '../../../../../_metronic/helpers'
import {SelectOptionProps} from '../../../../core/models/SelectOptionProps'
import {Result} from '../../../../core/models/Result'
import {camelize} from '../../../../core/utils/StringHelpers'
import {getAccountList} from '../../../settings/account/core/_requests'
import {Account} from '../../../settings/account/core/_models'
import {getPaymentModeList} from '../../../settings/paymode/core/_requests'
import {PaymentMode} from '../../../settings/paymode/core/_models'
import CustomDatePicker from '../../../../core/shared/components/CustomDatePicker'
import CustomSelect from '../../../../core/shared/components/CustomSelect'
import {Loading} from '../../../../core/shared/components/Loading'
import {getVendorList} from '../../vendor/vendor-list/core/_requests'
import {Vendor} from '../../vendor/vendor-list/core/_models'
import {createPOPayment} from '../../purchaseOrder/purchaseOrder-list/core/_requests'
import {PurchaseOrderPayment} from '../../purchaseOrder/purchaseOrder-list/core/_models'

const paginationFilter: PaginationFilter = {
  pageNumber: 1,
  pageSize: 500,
}
const paymentSchema = Yup.object().shape({
  amountPaid: Yup.string().required('Please enter amount.'),
  paymentDate: Yup.string().required('Please enter Payment date'),
  paymentModeId: Yup.string().required('Please select payment mode.'),
  referenceNumber: Yup.string().required('Please enter the reference Number'),
  notes: Yup.string(),
  bankName: Yup.string(),
  chequeNumber: Yup.number(),
  chequeDate: Yup.date(),
  vendorId: Yup.number().required('Please select the vendor'),
})

const PaymentForm: FC = ({}) => {
  const [PaymentModeOptions, setPaymentModeOptions] = useState<SelectOptionProps[]>([])
  const [vendor, setVendor] = useState<SelectOptionProps[]>([])

  const initialValues = {
    vendorId: undefined,
    paymentDate: new Date(),
    amountPaid: 0,
    paymentModeId: undefined,
    bankName: '',
    chequeNumber: 0,
    chequeDate: new Date(),
    referenceNumber: '',
    notes: '',
    accountId: undefined,
    isAdvance: true,
  }

  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: paymentSchema,
    onSubmit: async (formValues, {setSubmitting, setFieldError}) => {
      setSubmitting(true)
      try {
        let result: Result
        let payment: PurchaseOrderPayment = {
          amountPaid: formValues.amountPaid,
          paymentDate: formValues.paymentDate,
          paymentModeId: formValues.paymentModeId,
          referenceNumber: formValues.referenceNumber,
          notes: formValues.notes,
          vendorId: formValues.vendorId,
          accountId: formValues.accountId,
          isAdvance: true,
        }

        result = (await createPOPayment(payment)) as Result
        if (result.succeeded === true) {
          setSubmitting(true)
          toast.success('Payment added successfully')
          navigate('/purchase/payment/list')
        } else if (result.succeeded === false) {
          toast.error(result.messages[0])
        } else if (result.statusCode === 400) {
          result.propertyResults.forEach((error) =>
            setFieldError(camelize(error.propertyName), error.errorMessage)
          )
        }
      } catch (ex) {
        toast.error('error')
      }
    },
  })

  useEffect(() => {
    getPaymentModeList(paginationFilter).then((data) => {
      let result = data.data as PaymentMode[]
      let paymentModeArray: any[] = []
      result.map((item: any) => {
        return paymentModeArray.push({value: item.id, label: item.name})
      })
      setPaymentModeOptions(paymentModeArray)
    })

    getVendorList(paginationFilter).then((data) => {
      let result = data.data as Vendor[]
      let vendorArray: any[] = []
      result.map((item: any) => {
        return vendorArray.push({value: item.id, label: item.printName})
      })
      setVendor(vendorArray)
    })
  }, [])
  return (
    <FormikProvider value={formik}>
      <KTCard>
        <KTCardBody>
          <form
            id='kt_modal_add_status_form'
            className='form'
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div className='d-flex flex-column' id='kt_modal_add_status_scroll'>
              <div className='row'>
                <div className='col-xl-2 col-lg-3 col-md-4'>
                  <label className='form-label required'>Vendor</label>
                </div>
                <div className='col-xl-6 col-lg-9 col-md-8'>
                  <Field
                    className='form-select-solid'
                    options={vendor}
                    component={CustomSelect}
                    name={'vendorId'}
                    placeholder={'select vendor'}
                  ></Field>
                  {formik.touched.vendorId && formik.errors.vendorId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.vendorId}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* <div className='row mb-7'>
                <div className="col-6 col-md-6 mb-3">
                  <label className="form-label required">Vendor</label>
                  <Field
                    className='form-select-solid'
                    options={vendor}
                    component={CustomSelect}
                    name={'vendorId'}
                    placeholder={'select vendor'}
                  >
                  </Field>
                  {formik.touched.vendorId && formik.errors.vendorId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.vendorId}</span>
                      </div>
                    </div>
                  )}

                </div>
              </div> */}
              <div className='row mt-7'>
                <div className='col-xl-2 col-lg-3 col-md-4'>
                  <label className='form-label required'>Amount</label>
                </div>
                <div className='col-xl-3 col-lg-9 col-md-8'>
                  <Field
                    type='number'
                    className={clsx('form-control')}
                    {...formik.getFieldProps('amountPaid')}
                    name='amountPaid'
                    onChange={(e: any) => {
                      //formik.handleChange(e)
                      formik.setFieldValue('amountPaid', e.target.value)
                    }}
                  />
                  {formik.touched.amountPaid && formik.errors.amountPaid && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.amountPaid}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-xl-1 col-lg-3 col-md-4'>
                  <label className='form-label required'>Payment Date</label>
                </div>
                <div className='col-xl-2 col-lg-9 col-md-8 mb-5'>
                  <div>
                    <CustomDatePicker name='paymentDate' />
                  </div>
                  {formik.touched.paymentDate && formik.errors.paymentDate && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.paymentDate as string}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-xl-1 col-lg-3 col-md-4'>
                  <label className='form-label required'>Reference Number</label>
                </div>

                <div className='col-xl-2 col-lg-9 col-md-8 mb-5'>
                  <Field
                    type='text'
                    className={clsx('form-control')}
                    {...formik.getFieldProps('referenceNumber')}
                    name='referenceNumber'
                    onChange={(e: any) => {
                      formik.handleChange(e)
                      // onQuantityChange( e.target.value)
                    }}
                  />
                  {formik.touched.referenceNumber && formik.errors.referenceNumber && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.referenceNumber}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* 
              <div className='row mb-7'>
                <div className="col-6 col-md-6 mb-3">
                  <label className="form-label required">Amount</label>
                  <Field
                    type='number'
                    className={clsx('form-control')}
                    {...formik.getFieldProps('amountPaid')}
                    name='amountPaid'
                    onChange={(e: any) => {
                      //formik.handleChange(e)
                      formik.setFieldValue('amountPaid', e.target.value)
                    }
                    }
                  />
                  {formik.touched.amountPaid && formik.errors.amountPaid && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.amountPaid}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-6 col-md-6 mb-3">
                  <label className="form-label required">Payment Date</label>
                  <div>
                    <CustomDatePicker
                      name='paymentDate'
                    />
                  </div>
                </div>
              </div> */}

              {/* <div className='row mb-7'>
                <div className="col-6 col-md-6 mb-3">
                  <label className="form-label required">Reference Number</label>
                  <Field
                    type='text'
                    className={clsx('form-control')}
                    {...formik.getFieldProps('referenceNumber')}
                    name='referenceNumber'
                    onChange={(e: any) => {
                      formik.handleChange(e)
                      // onQuantityChange( e.target.value)
                    }
                    }
                  />
                  {formik.touched.referenceNumber && formik.errors.referenceNumber && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.referenceNumber}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div> */}

              <div className='fv-row mb-7'>
                <label className='fw-bold fs-6 mb-2 ms-1'>Notes</label>

                <textarea
                  rows={3}
                  placeholder='Notes'
                  {...formik.getFieldProps('notes')}
                  name='notes'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0 ps-4 ms-1',
                    {'is-invalid': formik.touched.notes && formik.errors.notes},
                    {
                      'is-valid': formik.touched.notes && !formik.errors.notes,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                />
                {formik.touched.notes && formik.errors.notes && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.notes}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='row mb-7'>
                <label className='form-label required'>Payment Mode</label>
                <div className='d-flex'>
                  {PaymentModeOptions.map((option) => (
                    <div key={option.value} className='form-check form-check-inline'>
                      <Field
                        type='radio'
                        className='form-check-input'
                        name='paymentModeId'
                        value={option.value}
                        checked={formik.values.paymentModeId === option.value}
                        onChange={() => formik.setFieldValue('paymentModeId', option.value)}
                      />
                      <label className='form-check-label'>{option.label}</label>
                    </div>
                  ))}
                </div>
              </div>
              {formik.values.paymentModeId === 2 && (
                <div className='row mb-7'>
                  <div className='col-4 mb-3'>
                    <label className='form-label required'>Bank Name</label>
                    <Field
                      type='text'
                      className={clsx('form-control')}
                      {...formik.getFieldProps('bankName')}
                      name='bankName'
                      onChange={(e: any) => {
                        formik.handleChange(e)
                        // onQuantityChange( e.target.value)
                      }}
                    />
                  </div>

                  <div className='col-4 mb-3'>
                    <label className='form-label required'>Cheque Number</label>
                    <Field
                      type='text'
                      className={clsx('form-control')}
                      {...formik.getFieldProps('chequeNumber')}
                      name='chequeNumber'
                      onChange={(e: any) => {
                        formik.handleChange(e)
                        // onQuantityChange( e.target.value)
                      }}
                    />
                  </div>
                  <div className='col-4 mb-3'>
                    <label className='form-label required'>Cheque Date</label>
                    <div>
                      <CustomDatePicker name='chequeDate' />
                    </div>
                  </div>
                </div>
              )}
              <div className='text-end pt-5'>
                <button
                  type='reset'
                  onClick={() => {
                    navigate('/sales/payment/list')
                  }}
                  className='btn btn-light me-3'
                  data-kt-status-modal-action='cancel'
                  disabled={formik.isSubmitting}
                >
                  Discard
                </button>

                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-status-modal-action='submit'
                  disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                >
                  <span className='indicator-label'>Submit</span>
                  {formik.isSubmitting && (
                    <span className='indicator-progress'>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
          {formik.isSubmitting && <Loading />}
        </KTCardBody>
      </KTCard>
    </FormikProvider>
  )
}

export {PaymentForm}
