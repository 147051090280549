
import { useEffect, useState } from 'react'
import { Filter, KTCard, KTCardBody, KTIcon, PaginationFilter } from '../../../../../_metronic/helpers'
import { StaffListHeader } from '../../staff/staff-list/components/header/StaffListHeader'
import { useListView, ListViewProvider } from '../core/ListViewProvider'
import { QueryRequestProvider } from '../core/QueryRequestProvider'
import { QueryResponseProvider } from '../core/QueryResponseProvider'
import { Account } from '../core/_models'
import { getAccountList } from '../core/_requests'
import { DataTable, DataTableStateEvent } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { AccountModal } from './AccountModal'
import { Button } from 'primereact/button'
import { useNavigate } from 'react-router-dom'
import { MustHavePermission, SWSection, SWResource, SWAction } from '../../../../core/shared/core/permissionUtils'
import { useImmer } from 'use-immer'
const paginationFilter: PaginationFilter = {
  pageNumber: 1,
  pageSize: 500,
}

const AccountList = () => {
  const { itemIdForUpdate } = useListView()
  const [account, setAccount] = useState<Account[]>([])
  const [globalFilterValue, setGlobalFilterValue] = useState<string>('');

  useEffect(() => {
    getAccountList(paginationFilter).then((data) => {
      let result = data.data as Account[]
      setAccount(result)
    })
  }, [itemIdForUpdate])

  const filteredData = account?.filter((item: Account) =>
    Object.values(item).some((value: string | boolean) =>
      typeof value === 'string' && value.toLowerCase().includes(globalFilterValue.toLowerCase())
    )
  );

  const { setItemIdForUpdate } = useListView()
  const openAddAccountModal = () => {
    setItemIdForUpdate(null)
  }

  const openEditAccountModal = (id: any) => {
    setItemIdForUpdate(id)
  }


  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="actions">
        <a
          href='#'
          className='btn btn-light btn-active-light-primary btn-sm '
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          onClick={() => { openEditAccountModal(rowData.id) }}
        >
          <span className="ki-duotone ki-pencil" >
            <span className="path1"></span>
            <span className="path2"></span >
          </span>

        </a>
      </div>
    );
  };

  const renderHeader = () => (

    <><div className='d-flex align-items-center position-relative justify-content-between my-1'>
      {/* <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' /> */}
      <input
        type='text'
        data-kt-customer-table-filter='search'
        className='form-control form-control-solid w-250px ps-14'
        placeholder='Search Account'
        value={globalFilterValue} onChange={(e) => setGlobalFilterValue(e.target.value)} />
        {MustHavePermission(SWSection.Store, SWResource.Accounts, SWAction.Create) ?
      <button type='button' className='btn btn-primary' onClick={openAddAccountModal}>
        <KTIcon iconName='plus' className='fs-2' />
        Add
      </button>
      :null}
    </div>
    </>
  );

  const header = renderHeader();


  return (

    <>

      <KTCard>
        <KTCardBody>
          <>
            <div className="d-flex justify-content-end mt-4">
              <div className="m-0">
              </div>
              {/* {MustHavePermission(SWSection.Store, SWResource.Accounts, SWAction.Create) ?
              <button type='button' className='btn btn-primary' onClick={openAddAccountModal}>
                <KTIcon iconName='plus' className='fs-2' />
                Add
              </button>
              :null} */}
            </div>
            <div>
              <DataTable
                paginator rows={10} rowsPerPageOptions={[20, 30, 50]}
                className='table align-middle table-row-dashed gy-5 dataTable no-footer'
                value={filteredData}
                tableStyle={{ minWidth: '75rem' }}
                breakpoint='960px'
                header={header}

              >
                <Column

                  className='text-gray-600 fw-bold'
                  field='name'
                  header={
                    <div className='text-start text-muted fw-bolder  gs-0 min-w-125px'>
                      Account Name
                    </div>
                  }
                  sortable
                  style={{ minWidth: '6rem' }}
                />
                <Column
                  className='text-gray-600 fw-bold'

                  field='accountCode'
                  header={
                    <div className='text-start text-muted fw-bolder  gs-0 min-w-125px'>
                      Account Code
                    </div>
                  }
                  sortable
                />
                <Column
                  className='text-gray-600 fw-bold'

                  field='accountTypeName'
                  header={
                    <div className='text-start text-muted fw-bolder  gs-0 min-w-125px'>
                      Account Type Name
                    </div>
                  }
                  sortable
                />
                <Column
                  className='text-gray-600 fw-bold'
                  field='parentAccountName'
                  header={
                    <div className='text-start text-muted fw-bolder  gs-0 min-w-125px'>
                      Parent Account
                    </div>
                  }
                  sortable
                />
                {MustHavePermission(SWSection.Store, SWResource.Accounts, SWAction.Update) ?
                  <Column
                    field=""
                    // header="Actions"
                    header={
                      <div className='text-start text-muted fw-bolder  gs-0 min-w-125px'>
                        Actions
                      </div>
                    }
                    body={actionBodyTemplate} /> : null}
              </DataTable>
            </div>
          </>
        </KTCardBody>
      </KTCard>

      {itemIdForUpdate !== undefined && <AccountModal />}
    </>
  )
}

const AccountWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <AccountList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { AccountWrapper }
