import {useQuery} from 'react-query'
import React from 'react'
import {QUERIES} from '../../../../../../_metronic/helpers'
import {getOrganizatinProfile} from '../../../core/_requests'
import {OrganizationProfileForm} from './OrganizationProfileForm'

export const OrganizationProfilePage = () => {
  const {
    isLoading,
    data: result,
    error: error,
  } = useQuery([`${QUERIES.ORGANIZATION_PROFILE}`], async () => {
    return await getOrganizatinProfile()
  })

  if (!isLoading && !error && result) {
    return <OrganizationProfileForm data={result} />
  }
  return null
}
