import React, {useState} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {
  deleteOrgfAddress,
  getAddressById,
  getOrganizatinProfile,
  getOrganizatinProfileAddress,
} from '../../../core/_requests'
import {OrganizationAddress} from '../../../core/_models'
import AddAddressModal from './AddAddressModal'
import ConfirmationModal from './ConfirmationModal'
import {toast} from 'react-toastify'
import {Result} from '../../../../../core/models/Result'

const AddressesPage = () => {
  const [editAddress, setEditAddress] = useState<OrganizationAddress>()
  const [isModalOpen, setModalOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [addressToDelete, setAddressToDelete] = useState<number>()
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const {data: organizationProfileData} = useQuery({
    queryKey: ['getOrganizationData'],
    queryFn: () => getOrganizatinProfile(),
  })
  const {
    // isLoading: isOrgLoading,
    data: organizationData,
    // error: orgError,
    refetch: refetchOrgAddress,
  } = useQuery(
    ['getAddress'],
    async () => {
      return await getOrganizatinProfileAddress()
    },
    {
      refetchOnWindowFocus: false,
    }
  )

  const organizationProfile = organizationProfileData as unknown as any

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const openNewModal = () => {
    setEditAddress({
      address: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        stateId: undefined,
        countryId: 1,
        zipCode: '',
        phoneNumber: '',
      },
      isPrimary: false,
      organisationId: organizationProfile?.id,
      id: undefined,
    })

    setIsEditMode(false)
    setModalOpen(true)
  }
  const onCustomerAddressChange = (e: number) => {
    getAddressById(e).then((v) => {
      const result = v

      if (result) {
        setEditAddress(result as OrganizationAddress)
        setIsEditMode(true)
        openModal()
      } else {
        console.error('Invalid address data:', result)
      }
    })
    setIsEditMode(true)
    openModal()
  }

  const handleDeleteButtonClick = (e: number) => {
    setAddressToDelete(e)
    setShowConfirmationModal(true)
  }

  const onDelete = async (addressId: number) => {
    try {
      let result: Result
      result = (await deleteOrgfAddress(addressId)) as Result
      if (result.succeeded) {
        toast.success('Address deleted successfully.')
        refetchOrgAddress()
      } else {
        if (result.statusCode === 400) {
          toast.error(result.exception)
        } else {
          toast.error('A Default address cannot be deleted.')
        }
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  return (
    <div className='card'>
      <div className='card-header'>
        <div className='card-title'>
          <h3>Addresses</h3>
        </div>
      </div>
      <div className='card-body'>
        <div className='row'>
          {organizationData &&
            Array.isArray(organizationData) &&
            organizationData.map((item: any) => (
              <div
                className='col-md-6 col-lg-6 col-xl-12 col-xxl-6 mb-4'
                data-kt-address-element='address'
              >
                <div className='d-flex flex-column py-2 border px-5 py-5'>
                  <div className='fw-semibold text-gray-600 prewrap'>
                    {item?.displayAddress ? (
                      item?.displayAddress
                    ) : (
                      <span className='text-danger'>No address available</span>
                    )}
                  </div>
                </div>
                <div className='d-flex align-items-center py-2'>
                  <button
                    className='btn btn-sm btn-light btn-active-light-primary me-3'
                    data-kt-address-action='address-delete'
                    onClick={() => {
                      handleDeleteButtonClick(item.id as number)
                    }}
                  >
                    <span className='indicator-label'>Delete</span>
                    <span className='indicator-progress'>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2' />
                    </span>
                  </button>

                  <button
                    className='btn btn-sm btn-light btn-active-light-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_new_address'
                    onClick={() => {
                      onCustomerAddressChange(item.id as number)
                    }}
                  >
                    Edit
                  </button>
                </div>
              </div>
            ))}
        </div>
        {showConfirmationModal && (
          <ConfirmationModal
            addressToDelete={addressToDelete}
            onConfirm={(addressToDelete) => onDelete(addressToDelete)}
          />
        )}

        <AddAddressModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          initialValues={editAddress as OrganizationAddress}
          isEditMode={isEditMode}
        />
      </div>
      <div className='card-footer text-end'>
        <div className='text-end'>
          <a
            href='#'
            className='btn btn-primary px-6 align-self-center text-nowrap'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_new_address'
            onClick={openNewModal}
          >
            New Address
          </a>
        </div>
      </div>
    </div>
  )
}

export default AddressesPage
