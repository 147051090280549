import axios, { AxiosResponse } from 'axios'
import { ID, PaginationFilter } from '../../../../_metronic/helpers/crud-helper/models'
import { Result } from '../../models/Result'
import { BarcodeQueryResponse, ColorQueryResponse, CountryQueryResponse, DesignNumberQueryResponse, ItemGroupQueryResponse, OtherChargeQueryResponse, PlaceOfSupplyQueryResponse, PolishingTypeQueryResponse, SizeQueryResponse, StateQueryResponse, StatusModel, TaxRateQueryResponse, TenantQueryResponse, Term, TermQueryResponse, UnitMeasurementsQueryResponse } from './_model'

const API_URL = process.env.REACT_APP_ADMIN_API_URL
const STORE_API_URL=process.env.REACT_APP_STORE_API_URL
const ItemGroup_LIST_URL = `${API_URL}/itemgroups/search`
const Color_LIST_URL = `${API_URL}/colors/search`
const UnitMeasurements_LIST_URL = `${API_URL}/unitmeasurements/search`
const PolishingType_LIST_URL = `${API_URL}/polishingtypes/search`
const Tenants_LIST_URL = `${API_URL}/tenants/search`
const Size_LIST_URL = `${API_URL}/sizes/search`
const State_LIST_URL = `${API_URL}/states/search`
const TaxRate_LIST_URL = `${API_URL}/taxrate/search`
const DesignNumber_LIST_URL = `${API_URL}/designnumbers/search`
const barcodeSymbology_LISt_URL = `${API_URL}/barcodesymbology/search`
const Term_LIST_URL = `${API_URL}/term/search`
const Term_BY_ID_URL = `${API_URL}/term`
const OTHERCHARGE_LIST_URL = `${API_URL}/charge/search`
const PLACEOFSUPPLY_LIST_URL = `${API_URL}/placeofsupply/search`
const PACKAGE_STATUS_URL = `${STORE_API_URL}/status/get-package-status`
const PAYMENT_STATUS_URL=`${STORE_API_URL}/status/get-payment-status`
const PURCHASE_ORDER_STATUS_URL=`${STORE_API_URL}/status/get-purchase-order-status`
const SALESORDER_STATUS_URL=`${STORE_API_URL}/status/get-sale-order-status`
const SALESORDER_TYPE_STATUS_URL=`${STORE_API_URL}/status/get-sale-order-type`
const SHIPMENT_STATUS_URL=`${STORE_API_URL}/status/get-shipment-status`
const CONVERT_TO_TAX_SO_URL=`${STORE_API_URL}/saleorders/convert-to-tax`
const COUNTRY_LIST_URL=`${API_URL}/countries/search`
const GET_STATE_BY_COUNTRY_ID_URL=`${API_URL}/states/state-list`
const getPlaceOfSupplyList = async (filter: PaginationFilter): Promise<PlaceOfSupplyQueryResponse> => {
  return await axios
    .post(`${PLACEOFSUPPLY_LIST_URL}`, filter)
    .then((d: AxiosResponse<PlaceOfSupplyQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const convertToTaxSO = async (soId: ID, placeOfSupplyId: ID): Promise<Result> => {
  return await axios
    .put(`${CONVERT_TO_TAX_SO_URL}`, {
      id: soId,
      placeOfSupplyId: placeOfSupplyId,
    })
    .then((d: AxiosResponse<Result>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const getOtherChargeList = async (filter: PaginationFilter): Promise<OtherChargeQueryResponse> => {
  return await axios
    .post(`${OTHERCHARGE_LIST_URL}`, filter)
    .then((d: AxiosResponse<OtherChargeQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const getBarcodeList = async (filter: PaginationFilter): Promise<BarcodeQueryResponse> => {
  return await axios
    .post(`${barcodeSymbology_LISt_URL}`, filter)
    .then((d: AxiosResponse<BarcodeQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const getTaxRateList = async (filter: PaginationFilter): Promise<TaxRateQueryResponse> => {
  return await axios
    .post(`${TaxRate_LIST_URL}`,
      filter,
    )
    .then((d: AxiosResponse<TaxRateQueryResponse>) => d.data)
    .catch((err: Result) => {
      return err
    })
}

const getStateList = (filter: PaginationFilter): Promise<StateQueryResponse> => {
  return axios
    .post(`${State_LIST_URL}`, filter)
    .then((d: AxiosResponse<StateQueryResponse>) => {
      return d.data
    })
}

const getStateByCountryId = async (countryId: ID): Promise<StateQueryResponse> => {
  return await axios
    .post(`${GET_STATE_BY_COUNTRY_ID_URL}`,{
      headers:{tenantId:"ho"},
      countryId:countryId
    })
    .then((d: AxiosResponse<StateQueryResponse>) => {
      return d.data
    })
}


const getSizeList = async (filter: PaginationFilter): Promise<SizeQueryResponse> => {
  return await axios
    .post(`${Size_LIST_URL}`,
      filter,
    )
    .then((d: AxiosResponse<SizeQueryResponse>) => d.data)
    .catch((err: Result) => {
      return err
    })
}

const getTenantList = async (filter: PaginationFilter): Promise<TenantQueryResponse> => {

  return await axios
    .post(`${Tenants_LIST_URL}`, filter)
    .then((d: AxiosResponse<TenantQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}
const getTermList = async (filter: PaginationFilter): Promise<TermQueryResponse> => {
  return await axios
    .post(`${Term_LIST_URL}`, filter)
    .then((d: AxiosResponse<TermQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const getTermById = async (id: ID): Promise<Result | Term> => {
  return await axios
    .get(`${Term_BY_ID_URL}/${id}`)
    .then((response: AxiosResponse<Term>) => response.data)
    .then((response: Term) => response)
    .catch((err: Result) => {
      return err
    })
}
const getItemGroupList = async (filter: PaginationFilter): Promise<ItemGroupQueryResponse> => {
  return await axios
    .post(`${ItemGroup_LIST_URL}`, filter)
    .then((d: AxiosResponse<ItemGroupQueryResponse>) => {

      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const getColorList = async (filter: PaginationFilter): Promise<ColorQueryResponse> => {
  return await axios
    .post(`${Color_LIST_URL}`, filter)
    .then((d: AxiosResponse<ColorQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const getPolishingTypeList = async (filter: PaginationFilter): Promise<PolishingTypeQueryResponse> => {
  return await axios
    .post(`${PolishingType_LIST_URL}`, filter)
    .then((d: AxiosResponse<PolishingTypeQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

// const getCategoryList = (filter: PaginationFilter): Promise<CategoryQueryResponse> => {
//   return axios
//     .post(`${Category_LIST_URL}`, filter)
//     .then((d: AxiosResponse<CategoryQueryResponse>) => {
//       return d.data
//     })
//     .catch((err: Result) => {
//       return err
//     })
// }


const getUnitMeasurementsList = async (filter: PaginationFilter): Promise<UnitMeasurementsQueryResponse> => {
  return await axios
    .post(`${UnitMeasurements_LIST_URL}`, filter)
    .then((d: AxiosResponse<UnitMeasurementsQueryResponse>) => {
      return d.data
    })
}

const getDesignNumberList = async (filter: PaginationFilter): Promise<DesignNumberQueryResponse> => {
  return await axios
    .post(`${DesignNumber_LIST_URL}`, filter)
    .then((d: AxiosResponse<DesignNumberQueryResponse>) => {
      return d.data
    })
    .catch((err: Result) => {
      return err
    })
}

const getPackageStatus = async (): Promise<Array<StatusModel>> => {
  return await axios
    .get(`${PACKAGE_STATUS_URL}`)
    .then((d: AxiosResponse<Array<StatusModel>>) => {
      return d.data
    })
}

const getPaymentStatus = async (): Promise<Array<StatusModel>> => {
  return await axios
    .get(`${PAYMENT_STATUS_URL}`)
    .then((d: AxiosResponse<Array<StatusModel>>) => {
      return d.data
    })
}

const getPurchaseOrderStatus = async (): Promise<Array<StatusModel>> => {
  return await axios
    .get(`${PURCHASE_ORDER_STATUS_URL}`)
    .then((d: AxiosResponse<Array<StatusModel>>) => {
      return d.data
    })
}

const getSaleOrderStatus = async (): Promise<Array<StatusModel>> => {
  return await axios
    .get(`${SALESORDER_STATUS_URL}`)
    .then((d: AxiosResponse<Array<StatusModel>>) => {
      return d.data
    })
}

const getSaleOrderTypeStatus = async (): Promise<Array<StatusModel>> => {
  return await axios
    .get(`${SALESORDER_TYPE_STATUS_URL}`)
    .then((d: AxiosResponse<Array<StatusModel>>) => {
      return d.data
    })
}

const getShipmentStatus = async (): Promise<Array<StatusModel>> => {
  return await axios
    .get(`${SHIPMENT_STATUS_URL}`)
    .then((d: AxiosResponse<Array<StatusModel>>) => {
      return d.data
    })
}

const getCountryList = async (filter: PaginationFilter): Promise<CountryQueryResponse> => {
  return await axios
    .post(`${COUNTRY_LIST_URL}`, filter)
    .then((d: AxiosResponse<CountryQueryResponse>) => {
      return d.data
    })
}



export {
  getItemGroupList,
  getColorList,
  getPolishingTypeList,
  getUnitMeasurementsList,
  getTenantList,
  getSizeList,
  convertToTaxSO,
  getDesignNumberList,
  getStateList,
  getTaxRateList,
  getBarcodeList,
  getTermList,
  getTermById,
  getOtherChargeList,
  getPlaceOfSupplyList,
  getPackageStatus,
  getPaymentStatus,
  getPurchaseOrderStatus,
  getSaleOrderStatus,
  getSaleOrderTypeStatus,
  getShipmentStatus,
  getCountryList,
  getStateByCountryId
}
