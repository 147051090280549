import { Field, FormikProvider, useFormik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { KTCard, KTSVG } from '../../../../../_metronic/helpers'
import { CustomSelect } from '../../../../core/shared/components/CustomSelect'
import { createNewBankAccount } from '../core/_requests'
import { toast } from 'react-toastify'

const accountTypes = [
  { value: 1, label: 'Savings' },
  { value: 2, label: 'Current' },
  { value: 3, label: 'Salary' },
]

const BankAccountAddPage = () => {
  const navigate = useNavigate()
  const addBankAccountSchema = yup.object().shape({
    name: yup.string().required('Bank Name is required'),
    accountHolderName: yup
      .string()
      .required('Account Holder Name is required')
      .matches(/^[A-Za-z \-()\/]*$/, 'Account Holder Name is invalid'),
    branchNameAddress: yup
      .string()
      .required('Branch Name Address is required')
      .matches(/^[A-Za-z \-()\/]*$/, 'Branch Name Address is invalid'),
    accountNumber: yup
      .string()
      .required('Account Number is required')
      .matches(/^[0-9]{10}$/, 'Account Number is invalid'),
    ifscCode: yup
      .string()
      .required('IFSC Code is required')
      .matches(/^[A-Za-z]*[A-Za-z][A-Za-z0-9]{10}$/, 'IFSC Code is invalid'),
    accountTypeId: yup.number().required('Account Type is required'),
    confirmAccountNumber: yup
      .string()
      .required('Confirm Account Number is required')
      .oneOf([yup.ref('accountNumber')], 'Account numbers must match')
      .matches(/^[0-9]{10}$/, 'Confirm Account Number is invalid'),
    isDefault: yup.boolean().required('Is Default is required'),
    upiId: yup.string(),
    switfbicCode: yup.string(),
    initialBalance: yup.number().required('Initial Balance is required'),
  })

  const initialValues = {
    name: '',
    accountHolderName: '',
    branchNameAddress: '',
    accountNumber: '',
    ifscCode: '',
    accountTypeId: undefined,
    isDefault: false as boolean,
    confirmAccountNumber: '',
    upiId: '',
    switfbicCode: '',
    initialBalance: undefined,
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: addBankAccountSchema,
    onSubmit: async (formValues, { setFieldError, setSubmitting }) => {
      setSubmitting(true)
      const resultPayload = {
        name: formValues.name,
        accountHolderName: formValues.accountHolderName,
        branchNameAddress: formValues.branchNameAddress,
        accountNumber: formValues.accountNumber,
        ifscCode: formValues.ifscCode,
        accountTypeId: formValues.accountTypeId,
        switfbicCode: formValues.switfbicCode,
        upiId: formValues.upiId,
        isDefault: formValues.isDefault,
        initialBalance: Number(formValues.initialBalance),
      }
      const result = await createNewBankAccount(resultPayload)
      if (result.hasOwnProperty('succeeded') && result?.succeeded) {
        setSubmitting(true)
        toast.success('Bank Account  saved successfully!')
        navigate(`/settings/bank-account/list`)
      } else {
        if (result.statusCode === 400) {
          result.propertyResults.map((error) => toast.error(error.errorMessage))
        }
        toast.error('An error occurred while saving the purchase receive.')
      }
    },
  })

  return (
    <FormikProvider value={formik}>
      <KTCard>
        <div className='card-header justify-content-bewteen align-items-center'>
          <div className='card-title'>
            <h3>Add Bank Account</h3>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit} className='form' noValidate>
          <div className='card-body d-flex flex-column gap-5'>
            <div className='d-flex flex-row align-items-center'>
              <div className='col-xxl-2 col-xl-3 col-lg-3 col-md-4'>
                <label className='form-label required'>Account Type</label>
              </div>
              <div className='col-xxl-6 col-xl-6 col-lg-9 col-md-8'>
                <Field
                  className='form-select-solid'
                  options={accountTypes}
                  component={CustomSelect}
                  {...formik.getFieldProps('accountTypeId')}
                  value={formik.values.accountTypeId}
                  name={'accountTypeId'}
                  placeholder={'Select Account Type'}
                ></Field>
                {formik.touched.accountTypeId && formik.errors.accountTypeId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.accountTypeId}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='d-flex flex-row align-items-center'>
              <div className='col-xxl-2 col-xl-3 col-lg-3 col-md-4'>
                <label className='form-label required'>Bank name</label>
              </div>
              <div className='col-xxl-6 col-xl-6 col-lg-9 col-md-8'>
                <Field
                  className='form-control'
                  type='text'
                  {...formik.getFieldProps('name')}
                  value={formik.values.name}
                  name={'name'}
                  placeholder={'Enter Bank Name'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue('name', e.target.value)
                  }}
                ></Field>
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.name}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='d-flex flex-row align-items-center'>
              <div className='col-xxl-2 col-xl-3 col-lg-3 col-md-4'>
                <label className='form-label required'>Account Holder Name</label>
              </div>
              <div className='col-xxl-6 col-xl-6 col-lg-9 col-md-8'>
                <Field
                  className='form-control'
                  type='text'
                  {...formik.getFieldProps('accountHolderName')}
                  value={formik.values.accountHolderName}
                  name={'accountHolderName'}
                  placeholder={'Enter Account Holder Name'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue('accountHolderName', e.target.value)
                  }}
                ></Field>
                {formik.touched.accountHolderName && formik.errors.accountHolderName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.accountHolderName}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='d-flex flex-row align-items-center'>
              <div className='col-xxl-2 col-xl-3 col-lg-3 col-md-4'>
                <label className='form-label required'>Branch Name Address</label>
              </div>
              <div className='col-xxl-6 col-xl-6 col-lg-9 col-md-8'>
                <textarea
                  className='form-control'
                  rows={3}
                  {...formik.getFieldProps('branchNameAddress')}
                  value={formik.values.branchNameAddress}
                  name={'branchNameAddress'}
                  placeholder={'Enter Branch Name Address'}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    formik.setFieldValue('branchNameAddress', e.target.value)
                  }}
                ></textarea>
                {formik.touched.branchNameAddress && formik.errors.branchNameAddress && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.branchNameAddress}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='d-flex flex-row align-items-center'>
              <div className='col-xxl-2 col-xl-3 col-lg-3 col-md-4'>
                <label className='form-label required'>Account Number</label>
              </div>
              <div className='col-xxl-6 col-xl-6 col-lg-9 col-md-8'>
                <Field
                  className='form-control'
                  type='text'
                  {...formik.getFieldProps('accountNumber')}
                  value={formik.values.accountNumber}
                  name={'accountNumber'}
                  placeholder={'Enter Account Number'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue('accountNumber', e.target.value)
                  }}
                ></Field>
                {formik.touched.accountNumber && formik.errors.accountNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.accountNumber}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='d-flex flex-row align-items-center'>
              <div className='col-xxl-2 col-xl-3 col-lg-3 col-md-4'>
                <label className='form-label required'>Confirm Account Number</label>
              </div>
              <div className='col-xxl-6 col-xl-6 col-lg-9 col-md-8'>
                <Field
                  className='form-control'
                  type='text'
                  {...formik.getFieldProps('confirmAccountNumber')}
                  value={formik.values.confirmAccountNumber}
                  name={'confirmAccountNumber'}
                  placeholder={'Enter Confirm Account Number'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue('confirmAccountNumber', e.target.value)
                  }}
                ></Field>
                {formik.touched.confirmAccountNumber && formik.errors.confirmAccountNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.confirmAccountNumber}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='d-flex flex-row align-items-center'>
              <div className='col-xxl-2 col-xl-3 col-lg-3 col-md-4'>
                <label className='form-label required'>IFSC Code</label>
              </div>
              <div className='col-xxl-6 col-xl-6 col-lg-9 col-md-8'>
                <Field
                  className='form-control'
                  type='text'
                  {...formik.getFieldProps('ifscCode')}
                  value={formik.values.ifscCode}
                  name={'ifscCode'}
                  placeholder={'Enter IFSC Code'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue('ifscCode', e.target.value)
                  }}
                ></Field>
                {formik.touched.ifscCode && formik.errors.ifscCode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.ifscCode}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='d-flex flex-row align-items-center'>
              <div className='col-xxl-2 col-xl-3 col-lg-3 col-md-4'>
                <label className='form-label'>UPI ID</label>
              </div>
              <div className='col-xxl-6 col-xl-6 col-lg-9 col-md-8'>
                <Field
                  className='form-control'
                  type='text'
                  {...formik.getFieldProps('upiId')}
                  value={formik.values.upiId}
                  name={'upiId'}
                  placeholder={'Enter UPI ID'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue('upiId', e.target.value)
                  }}
                ></Field>
                {formik.touched.upiId && formik.errors.upiId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.upiId}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='d-flex flex-row align-items-center'>
              <div className='col-xxl-2 col-xl-3 col-lg-3 col-md-4'>
                <label className='form-label'>Switf/BIC Code</label>
              </div>
              <div className='col-xxl-6 col-xl-6 col-lg-9 col-md-8'>
                <Field
                  className='form-control'
                  type='text'
                  {...formik.getFieldProps('switfbicCode')}
                  value={formik.values.switfbicCode}
                  name={'switfbicCode'}
                  placeholder={'Enter Swift/BIC Code'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue('switfbicCode', e.target.value)
                  }}
                ></Field>
                {formik.touched.switfbicCode && formik.errors.switfbicCode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.switfbicCode}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='d-flex flex-row align-items-center'>
              <div className='col-xxl-2 col-xl-3 col-lg-3 col-md-4'>
                <label className='form-label required'>Initial Balance</label>
              </div>
              <div className='col-xxl-6 col-xl-6 col-lg-9 col-md-8'>
                <Field
                  className='form-control'
                  type='text'
                  {...formik.getFieldProps('initialBalance')}
                  value={formik.values.initialBalance}
                  name={'initialBalance'}
                  placeholder={'Enter Initial Balance'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue('initialBalance', e.target.value)
                  }}
                ></Field>
                {formik.touched.initialBalance && formik.errors.initialBalance && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.initialBalance}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className='d-flex flex-row align-items-center'>
              <div className='col-xxl-2 col-xl-3 col-lg-3 col-md-4'>
                <label className='form-label required'>Is Default</label>
              </div>
              <div className='col-xxl-6 col-xl-6 col-lg-9 col-md-8'>
                <div className='form-check form-check-custom form-check-solid'>
                  <Field
                    className='form-check-input'
                    type='checkbox'
                    checked={formik.values.isDefault}
                    {...formik.getFieldProps('isDefault')}
                    value={formik.values.isDefault}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      formik.setFieldValue('isDefault', e.target.checked)
                    }}
                    name={'isDefault'}
                  ></Field>
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer text-end'>
            <button
              type='reset'
              className='btn btn-light me-3'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_1'
              disabled={formik.isSubmitting}
            >
              Discard
            </button>
            <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
              <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h2>Confirmation</h2>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>
                  <div className='modal-body'>
                    <div className='w-100'></div>
                    <div className='fv-row text-center'>
                      <div className='fv-row text-center'>
                        <h5>Are you sure you want to discard ?</h5>
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer'>
                    <button
                      type='button'
                      className='btn btn-light'
                      data-bs-dismiss='modal'
                      onClick={() => navigate('/settings/bank-account/list')}
                    >
                      Yes
                    </button>
                    <button type='button' className='btn btn-primary' data-bs-dismiss='modal'>
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-color-modal-action='submit'
              disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              <span className='indicator-label'>Submit</span>
              {formik.isSubmitting && (
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </KTCard>
    </FormikProvider>
  )
}

export default BankAccountAddPage
